import React, { useRef } from "react";
import { useSelector } from "react-redux";
import { shuffleArray } from "../../../Constants";
import QuestionOption from "./QuestionOption";
import listeningQues from "../../../Assets/img/listening.png";
import audioPlay from "../../../Assets/img/play.png";
import { toast } from "react-toastify";
import HTMLParser from "../../Components/HTMLParser";
const DashboardResultQuestions = ({ selectedQuestion, index }) => {
  let solvedQuestions = useSelector((state) => state.localset.solvedQuestion);
  const audioRef = useRef(null);
  const optionsAudio = useRef([]);
  const toggleAudio = () => {
    if (audioRef.current === null) {
      toast.error("Audio component is not loaded yet.");
    } else {
      if (audioRef.current.paused) {
        audioRef.current.parentElement.classList.add("played");
        audioRef.current.play();
        for (let i = 0; i < optionsAudio.current.length; i++) {
          optionsAudio.current[i].pause();}
      }
    }
  };
  const toggleOptionsAudio = (e) => {
    audioRef.current.pause();
    if (optionsAudio.current[e.target.id] != null) {
      for (let i = 0; i < optionsAudio.current.length; i++) {
        optionsAudio.current[i].pause();
        if (optionsAudio.current[i] === optionsAudio.current[e.target.id]) {
          optionsAudio.current[i].play();
        }
      }
    } else {
      alert("No Audio File!!!!");
    }
  };
  const ifSolved = (item) => {
    for (let i = 0; i < solvedQuestions.length; i++) {
      if (solvedQuestions[i].id === item.id) {
        return solvedQuestions[i];
      }
    }
  };
  const checkIfCorrect = (ques) => {
    let options = ifSolved(ques)
      ? ifSolved(ques).answers.options
      : ques.answers.options;
    let result;
    for (let i = 0; i < options.length; i++) {
      if (options[i].hasOwnProperty("userSelected")) {
        if (options[i].userSelected === true) {
          if (options[i].is_correct === 1) {
            result = "correct";
            break;
          } else {
            result = "notcorrect";
            break;
          }
        }
      } else {
        result = "not_attempted";
        continue;
      }
    }
    return result;
  };
  // let getCorrectRadio = () => {
  //   let result;
  //   selectedQuestion.answers.options.forEach((item) => {
  //     if (item.is_correct === 1) {
  //       result = item.id;
  //     }
  //   });
  //   return result;
  // };
  const getALlOptions = (ques) => {
    let options = ifSolved(ques)
      ? ifSolved(ques).answers.options
      : shuffleArray(ques.answers.options);
    return options;
  };
  // selectedQuestion.answers && selectedQuestion.answers.options;
  // checkIfCorrect(selectedQuestion) ? "correct" : "notcorrect"
  return (
    <div
      id={selectedQuestion.id}
      className={`questions ${
        checkIfCorrect(selectedQuestion) === "not_attempted"
          ? "not_attempted"
          : checkIfCorrect(selectedQuestion) === "correct"
          ? "correct"
          : "notcorrect"
      } `}
    >
      <h4>
        {/* {selectedQuestion.id} */}
        {selectedQuestion.groupTitle}
      </h4>
      <div className="d-flex questionGroups">
        <span>{index + 1}</span>
        <div>
          {selectedQuestion.mainTitle && (
            <div className="d-flex">
              <h5>
                {/* {question.mainTitle} */}
                <HTMLParser>{selectedQuestion.mainTitle}</HTMLParser>
              </h5>
            </div>
          )}
          {selectedQuestion.subTitle && (
            <div className="d-flex">
              <h5>
                <HTMLParser>{selectedQuestion.subTitle}</HTMLParser>
              </h5>
            </div>
          )}
          {selectedQuestion.type === "image" && selectedQuestion.file && (
            <div className="image-container">
              <img
                loading="lazy"
                src={selectedQuestion.file}
                alt={selectedQuestion.file}
              />
            </div>
          )}
          {selectedQuestion.type === "audio" && selectedQuestion.file && (
            <div className="image-container">
              <img
                loading="lazy"
                src={audioPlay}
                alt=""
                onClick={toggleAudio}
                className="audioPlayClass"
              />
              <audio ref={audioRef} src={selectedQuestion.file}></audio>
            </div>
          )}
          <div className="mcqAnswers">
            {getALlOptions(selectedQuestion).map((item, id) => (
              <div className="mb-3" key={id}>
                <QuestionOption
                  label={
                    <>
                      {selectedQuestion.answers.type === "audio" && (
                        <>
                          <img
                            loading="lazy"
                            id={id}
                            src={listeningQues}
                            alt=""
                            className="audioPlayClass"
                            onClick={toggleOptionsAudio}
                          />
                          <audio
                            ref={(el) => (optionsAudio.current[id] = el)}
                            src={item.option}
                          />
                        </>
                      )}
                      {selectedQuestion.answers.type === "text" && item.option}
                      {selectedQuestion.answers.type === "image" && (
                        <img loading="lazy" src={item.option} />
                      )}
                      {/* {item.userSelected ? getCorrectRadio() === item.id ? <i className="bi bi-check2"></i> : <i className="bi bi-x-lg"></i> : ""}
                      {!item.userSelected && getCorrectRadio() === item.id && <i className="bi bi-check2"></i>} */}
                    </>
                  }
                  wasSelected={item.userSelected}
                  correctValue={item.is_correct}
                  optionId={id}
                  type="resultItem"
                  value={item.id}
                  ifCorrect={
                    checkIfCorrect(selectedQuestion) === "not_attempted"
                      ? "not_attempted"
                      : checkIfCorrect(selectedQuestion) === "correct"
                      ? "correct"
                      : "notcorrect"
                  }
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardResultQuestions;
