import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  all_dictionary,
  single_dictionary,
  dictionary_collection,
} from "./DictionaryApi";
const initialState = {
  isLoading: false,
  isLoadingSingle: false,
  dicCollection: [],
  allDictionary: [],
  singleDictionary: {},
  isSingleSelected: false,
  errorHandle: "",
};
export const dictionaryCollection = createAsyncThunk(
  "dictionary/dictionaryCollection",
  async (data) => {
    try {
      return await dictionary_collection(data);
    } catch (error) {
      return error;
    }
  }
);
export const allDictionary = createAsyncThunk(
  "dictionary/allDictionary",
  async (data) => {
    try {
      return await all_dictionary(data);
    } catch (error) {
      return error;
    }
  }
);

export const singleDictionary = createAsyncThunk(
  "dictionary/singleDictionary",
  async (data) => {
    try {
      return await single_dictionary(data);
    } catch (error) {
      return error;
    }
  }
);

const DictionarySlice = createSlice({
  name: "dictionary",
  initialState,
  reducers: {
    selectDictionary: (state) => {
      state.isSingleSelected = true;
    },
    deselectDictionary: (state) => {
      state.isSingleSelected = false;
      state.singleDictionary = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(dictionaryCollection.fulfilled, (state, action) => {
        state.dicCollection = action.payload.data.data;
      })
      .addCase(allDictionary.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(allDictionary.fulfilled, (state, action) => {
        state.isLoading = false;
        if (action.payload.data.data.length > 0) {
          let allData = new Set([
            ...state.allDictionary,
            ...action.payload.data.data,
          ]);
          state.allDictionary = [...allData];
        } else {
          state.errorHandle = "No More Data To Load";
        }
      })
      .addCase(allDictionary.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(singleDictionary.pending, (state, action) => {
        state.isLoadingSingle = true;
        state.singleDictionary = {};
      })
      .addCase(singleDictionary.fulfilled, (state, action) => {
        state.isLoadingSingle = false;
        state.singleDictionary = action.payload.data.data;
      })
      .addCase(singleDictionary.rejected, (state, action) => {
        state.isLoadingSingle = false;
      });
  },
});
export const { deselectDictionary, selectDictionary } = DictionarySlice.actions;
export default DictionarySlice.reducer;
