import React, { useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deselectDictionary } from "../../../../Features/DictionaryApi/DictionarySlice";
import HTMLParser from "../../../Components/HTMLParser";
import {
  setFavorites,
  removeFavorites,
} from "../../../../Features/FavoriteApi/FavoriteSlice";
import { textToSpeech } from "../../../../Constants";
import ShareModal from "../../../Components/Share/ShareModal";
import MetaHelmet from "../../../Components/Helmet/MetaHelmet";
import nepalFlag from "../../../../Assets/img/nepal-flag.png";
import koreaFlag from "../../../../Assets/img/korea-flag.png";
import ukFlag from "../../../../Assets/img/uk-usa-flag.png";
import { openLoginForm, openModal } from "../../../../Features/ViewSlice";
const DictionaryDetails = () => {
  const dispatch = useDispatch();
  let singleDictionary = useSelector(
    (state) => state.dictionary.singleDictionary
  );
  let token = useSelector((state) => state.auth?.currentUser?.token);
  const [isFavorite, setFavorite] = useState(singleDictionary.isFavorite);
  console.log(singleDictionary.isFavorite);
  const makeFavorite = (e) => {
    e.preventDefault();
    const formdata = new FormData();
    formdata.append("favoritable_id", singleDictionary.id);
    formdata.append("favoritable_type", "App\\Models\\Dictionary");
    if (token) {
      dispatch(setFavorites({ formdata, token })).then((res) => {
        if (res.payload.status === 200) {
          setFavorite(true);
        }
      });
    } else {
      dispatch(openModal("loginView"));
      dispatch(openLoginForm());
    }
  };
  const removeFavorite = (e) => {
    e.preventDefault();
    const formdata = new FormData();
    formdata.append("favoritable_id", singleDictionary.id);
    formdata.append("favoritable_type", "App\\Models\\Dictionary");
    dispatch(removeFavorites({ formdata, token })).then((res) => {
      if (res.payload.status === 200) {
        setFavorite(false);
      }
    });
  };
  const textForSpeech = useRef(null);
  const textSpeechHandler = (e) => {
    e.preventDefault();
    if (textForSpeech != null) {
      textToSpeech(textForSpeech.current.textContent);
    }
  };

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const goBack = () => {
    dispatch(deselectDictionary());
  };
  return (
    <>
      {singleDictionary && singleDictionary.meaning && (
        <div className="grammar--details singleselected">
          <MetaHelmet
            data={singleDictionary}
            shareTo={`/dictionarydetail/${singleDictionary.id}`}
          />
          <div className="header-wrap">
            <div className="d-flex align-items-center justify-content-between">
              <h2 className="dashboard-page-title my-0" ref={textForSpeech}>
                {singleDictionary.word}
                <a aria-label="volume up" href="/#" onClick={textSpeechHandler}>
                  <i className="bi bi-volume-up"></i>
                </a>
              </h2>
              <div className="grammar--share d-flex align-items-center">
                <i className="bi bi-share" onClick={handleShow}></i>
                <ShareModal
                  shareTo={`/dictionarydetail/${singleDictionary.id}`}
                  show={show}
                  handleClose={handleClose}
                />
                {isFavorite ? (
                  <a aria-label="heart fill" href="/#" onClick={removeFavorite}>
                    <i className="bi bi-heart-fill"></i>
                  </a>
                ) : (
                  <a aria-label="heart shape" href="/#" onClick={makeFavorite}>
                    <i className="bi bi-heart"></i>
                  </a>
                )}
              </div>
            </div>
          </div>
          <div className="grammar__section">
            <div className="grammarDetails">
              <div>
                {singleDictionary.meaning.kr_meaning && (
                  <div className="d-flex">
                    <h4>
                      <img src={koreaFlag} alt="" /> <span>한국어:</span>
                    </h4>
                    <div>
                      <HTMLParser>
                        {singleDictionary.meaning.kr_meaning}
                      </HTMLParser>
                    </div>
                  </div>
                )}
                {singleDictionary.meaning.np_meaning && (
                  <div className="d-flex">
                    <h4>
                      <img src={nepalFlag} alt="" /> <span>네팔어:</span>
                    </h4>
                    <div>
                      <HTMLParser>
                        {singleDictionary.meaning.np_meaning}
                      </HTMLParser>
                    </div>
                  </div>
                )}
                {singleDictionary.meaning.en_meaning && (
                  <div className="d-flex">
                    <h4>
                      <img src={ukFlag} alt="" /> <span>영어:</span>
                    </h4>
                    <div>
                      <HTMLParser>
                        {singleDictionary.meaning.en_meaning}
                      </HTMLParser>
                    </div>
                  </div>
                )}
                {singleDictionary.examples && (
                  <div className="d-flex">
                    <h4>Examples:</h4>
                    <div>
                      <HTMLParser>{singleDictionary.examples}</HTMLParser>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="goback btn btn-small btn-green mb-3" onClick={goBack}>
            Go Back
          </div>
        </div>
      )}
    </>
  );
};

export default DictionaryDetails;
