import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { toast } from "react-toastify";
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASEKEY,
  projectId: process.env.REACT_APP_PROJECTID,
  storageBucket: process.env.REACT_APP_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGINSENDERID,
  appId: process.env.REACT_APP_APPID,
  measurementId: process.env.REACT_APP_MEASUREMENTID,
};
const app = initializeApp(firebaseConfig);
export const firestore = getMessaging(app);
export const get_Token = async () => {
  return getToken(firestore, {
    vapidKey: process.env.REACT_APP_PUBLICKEY,
  }).then((currentToken) => {
    try {
      if (currentToken) {
        return currentToken;
        // Track the token -> client mapping, by sending to backend server
        // show on the UI that permission is secured
      } else {
        toast(
          "No registration token available. Request permission to generate one."
        );
        // shows on the UI that permission is required
      }
    } catch (err) {
      if (err) {
        toast("An error occurred while retrieving token.", err);
      }
    }
  });
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(firestore, (payload) => {
      resolve(payload);
    });
  });
