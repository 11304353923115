import { createSlice } from "@reduxjs/toolkit";
const allLoginInialView = {
  viewLoginForm: false,
  viewOTP: false,
  viewForgotPassword: false,
  viewNewPassword: false,
  viewPasswordSuccess: false,
};
const allRegisterInitialView = {
  viewRegisterForm: false,
  viewRegisterImgRefForm: false,
};
const modalView = {
  loginView: false,
  registerView: false,
  resultSubmitView: false,
};
const initialState = {
  openModal: false,
  loginInStates: {
    ...allLoginInialView,
  },
  registerInStates: {
    ...allRegisterInitialView,
  },
  modalState: {
    ...modalView,
  },
  displayedRoadblockAd: false,
  alreadyDisplayed: false,
};

const ViewsLice = createSlice({
  name: "view",
  initialState,
  reducers: {
    openModal: (state, action) => {
      state.openModal = true;
      state.modalState = { ...modalView };
      state.modalState[`${action.payload}`] = true;
    },
    closeModal: (state) => {
      state.openModal = false;
      state.modalState = { ...modalView };
    },
    openLoginForm: (state) => {
      state.loginInStates = { ...allLoginInialView };
      state.loginInStates.viewLoginForm = true;
    },
    openViewOTP: (state) => {
      state.loginInStates = { ...allLoginInialView };
      state.loginInStates.viewOTP = true;
    },
    openViewForgotPassword: (state) => {
      state.loginInStates = { ...allLoginInialView };
      state.loginInStates.viewForgotPassword = true;
    },
    openViewNewPassword: (state) => {
      state.loginInStates = { ...allLoginInialView };
      state.loginInStates.viewNewPassword = true;
    },
    openPasswordSuccess: (state) => {
      state.loginInStates = { ...allLoginInialView };
      state.loginInStates.viewPasswordSuccess = true;
    },
    openRegisterForm: (state) => {
      state.registerInStates = { ...allRegisterInitialView };
      state.registerInStates.viewRegisterForm = true;
    },
    openRegisterImgRefForm: (state) => {
      state.registerInStates = { ...allRegisterInitialView };
      state.registerInStates.viewRegisterImgRefForm = true;
    },
  },
});
export const {
  openModal,
  closeModal,
  openLoginForm,
  openViewOTP,
  openViewForgotPassword,
  openViewNewPassword,
  openPasswordSuccess,
  openRegisterForm,
  openRegisterImgRefForm,
  displayRoadBlock,
  hideRoadBlock,
} = ViewsLice.actions;
export default ViewsLice.reducer;
