import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { ReactComponent as PageBannerSVG } from "../../../Assets/img/big-lkwb.svg";
import { useSelector, useDispatch } from "react-redux";
import { Link, useParams, useSearchParams } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import HTMLParser from "../../Components/HTMLParser";
import Loader from "../../Components/Loader/Loader";
import { fetchSingleBook } from "../../../Features/BookApi/singleBookslice";

const Chapterscontent = () => {
  const dispatch = useDispatch();
  const {contentid} = useParams();


  const { data: singleBookData, loading } = useSelector(
    (state) => state.singleBook
  );
  useEffect(() => {
    dispatch(fetchSingleBook(contentid));
  }, [dispatch, contentid]);

  const [chapName, setChapName] = useState('');
  const [chapId, setChapId] = useState('');
  
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const name = urlParams.get('name');
    const chapterId = urlParams.get('chapter');
    setChapName(name); // This will log 'Chapter1' to the console
    setChapId(chapterId); // This will log 'Chapter1' to the console
    // You can use 'name' variable here as needed
  }, []);

  const [chapterContent, setChapterContent]=useState([])

  useEffect(()=>{
   const filteredChapter = singleBookData.chapters.filter(chapter => chapter.id === parseInt(contentid));
    setChapterContent(filteredChapter)
  },[contentid])

  return (
    <>
      <div className="pageBanner">
        <PageBannerSVG />

        <Container>
          <div className="page-wrap">
            {singleBookData && <h3>{singleBookData.bookName} - {chapName} </h3>}
          </div>
          <div className="custom-shape-divider-bottom-1678688493"></div>
        </Container>
      </div>
      <section className="section">
        <Container fluid="sm">
          <Link className="anchor-text goback" to={`/books/chapters/${chapId}`} >
            Go Back
          </Link>

          <div className="mt-3">
            {chapterContent ? (
              chapterContent.map((item, index) => (
                <Row>
                  <Col md={8} xs={12}>
                    <h3 key={index}>
                      <iframe
                        key={index}
                        src={item.url}
                        className="bookTitle"
                        title={`Book ${index + 1}`}
                        width="100%"
                        height={500}
                        allowFullScreen
                      ></iframe>
                    </h3>
                  </Col>
                  <Col>
                    <>
                      <span>Audio</span>
                      {item.audio_files &&
                        item.audio_files.length > 0 &&
                        item.audio_files.map((audio, audioIndex) => (
                          <audio
                            key={audioIndex}
                            controls
                            controlsList="nodownload  "
                          >
                            <source src={audio.url} type="audio/mp3" />
                            Your browser does not support the audio tag.
                          </audio>
                        ))}
                    </>
                  </Col>
                </Row>
              ))
            ) : (
              <div className="youhavenot-container">
                <p>No Content Available</p>
              </div>
            )}
          </div>
        </Container>
      </section>
    </>
  );
};

export default Chapterscontent;
