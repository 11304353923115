import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router";
import { Row, Col, Container } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import HTMLParser from "../HTMLParser";
import { openLoginForm, openModal } from "../../../Features/ViewSlice";
import { singleGrammar } from "../../../Features/GrammarApi/GrammarSlice";
import {
  setFavorites,
  removeFavorites,
} from "../../../Features/FavoriteApi/FavoriteSlice";
import { textToSpeech } from "../../../Constants";
import ShareModal from "../Share/ShareModal";
import MetaHelmet from "../Helmet/MetaHelmet";
import WordDay from "../../DashboardView/Components/WordDay";
import CustomAd from "../CustomAd/CustomAd";
import nepalFlag from "../../../Assets/img/nepal-flag.png";
import ukFlag from "../../../Assets/img/uk-usa-flag.png";
import koreaFlag from "../../../Assets/img/korea-flag.png";
import { Link } from "react-router-dom";
const ModuleSingleGrammar = () => {
  const dispatch = useDispatch();
  let ad = useSelector(
    (state) =>
      state.ad.allAdPositions["sidebar-grammar-detail-homepage-306-x-250"]
  );
  let token = useSelector((state) => state.auth?.currentUser?.token);
  const [single_grammar, setSingleGrammar] = useState();
  const [isFavorite, setFavorite] = useState();
  let { id } = useParams();
  useEffect(() => {
    if (token) {
      dispatch(singleGrammar({ token, id })).then((res) => {
        if (res.payload.status === 200) {
          setSingleGrammar(res.payload.data.data);
          setFavorite(res.payload.data.data.isFavorite);
        }
      });
    } else {
      dispatch(singleGrammar({ id })).then((res) => {
        if (res.payload.status === 200) {
          setSingleGrammar(res.payload.data.data);
        }
      });
    }
  }, [dispatch, id]);
  const makeFavorite = (e) => {
    e.preventDefault();
    const formdata = new FormData();
    formdata.append("favoritable_id", id);
    formdata.append("favoritable_type", "App\\Models\\Grammar");
    dispatch(setFavorites({ formdata, token })).then((res) => {
      if (res.payload.status === 200) {
        setFavorite(true);
      }
    });
  };
  const removeFavorite = (e) => {
    e.preventDefault();
    const formdata = new FormData();
    formdata.append("favoritable_id", id);
    formdata.append("favoritable_type", "App\\Models\\Grammar");
    dispatch(removeFavorites({ formdata, token })).then((res) => {
      if (res.payload.status === 200) {
        setFavorite(false);
      }
    });
  };
  const loginModel = () => {
    if (!token) {
      dispatch(openModal("loginView"));
      dispatch(openLoginForm());
    }
  };
  const textForSpeech = useRef(null);
  const textSpeechHandler = (e) => {
    e.preventDefault();
    if (textForSpeech != null) {
      textToSpeech(textForSpeech.current.textContent);
    }
  };

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      {single_grammar && (
        <>
          <MetaHelmet
            data={single_grammar}
            shareTo={`/grammardetail/${singleGrammar.id}`}
          />
          <Container>
            <div className="home__dictionary">
              <Link to="/grammar" className="viewallbtn">
                View all Grammar
              </Link>
              <div className="grammar-container ">
                <Row>
                  <Col md={8} className="grammar--details singleselected">
                    <div className="header-wrap">
                      <div className="d-flex align-items-center justify-content-between">
                        <h2
                          className="dashboard-page-title my-0"
                          ref={textForSpeech}
                        >
                          {single_grammar.word}
                          <a
                            aria-label="volume up"
                            href="/#"
                            onClick={textSpeechHandler}
                          >
                            <i className="bi bi-volume-up"></i>
                          </a>
                        </h2>
                        <div className="grammar--share d-flex align-item-center">
                          <i className="bi bi-share" onClick={handleShow}></i>
                          <ShareModal
                            shareTo={`/grammardetail/${singleGrammar.id}`}
                            show={show}
                            handleClose={handleClose}
                          />
                          {token ? (
                            <>
                              {isFavorite ? (
                                <a
                                  aria-label="heart-fill"
                                  href="/#"
                                  onClick={removeFavorite}
                                >
                                  <i className="bi bi-heart-fill"></i>
                                </a>
                              ) : (
                                <a
                                  aria-label="heart"
                                  href="/#"
                                  onClick={makeFavorite}
                                >
                                  <i className="bi bi-heart"></i>
                                </a>
                              )}
                            </>
                          ) : (
                            <i
                              className="bi bi-heart "
                              onClick={loginModel}
                            ></i>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="grammar__section">
                      {single_grammar.meanings && (
                        <div className="grammarDetails">
                          <h3>Meaning:</h3>
                          <div>
                            {single_grammar.meanings.np && (
                              <div className="d-flex">
                                <h4>
                                  <img src={nepalFlag} alt="" />{" "}
                                  <span>네팔어:</span>
                                </h4>
                                <div>
                                  <HTMLParser>
                                    {single_grammar.meanings.np}
                                  </HTMLParser>
                                </div>
                              </div>
                            )}
                            {single_grammar.meanings.en && (
                              <div className="d-flex">
                                <h4>
                                  <img src={ukFlag} alt="" />
                                  <span>영어:</span>
                                </h4>
                                <div>
                                  <HTMLParser>
                                    {single_grammar.meanings.en}
                                  </HTMLParser>
                                </div>
                              </div>
                            )}
                            {single_grammar.meanings.kr && (
                              <div className="d-flex">
                                <h4>
                                  <img src={koreaFlag} alt="" />{" "}
                                  <span>한국어:</span>
                                </h4>
                                <div>
                                  <HTMLParser>
                                    {single_grammar.meanings.kr}
                                  </HTMLParser>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                      {single_grammar.rules && (
                        <div className="grammarDetails">
                          <h3>Rules:</h3>
                          <div>
                            {single_grammar.rules.np && (
                              <div className="d-flex">
                                <h4>네팔어:</h4>
                                <div>
                                  <HTMLParser>
                                    {single_grammar.rules.np}
                                  </HTMLParser>
                                </div>
                              </div>
                            )}

                            {single_grammar.rules.en && (
                              <div className="d-flex">
                                <h4>영어:</h4>
                                <div>
                                  <HTMLParser>
                                    {single_grammar.rules.en}
                                  </HTMLParser>
                                </div>
                              </div>
                            )}
                            {single_grammar.rules.kr && (
                              <div className="d-flex">
                                <h4>한국어:</h4>
                                <div>
                                  <HTMLParser>
                                    {single_grammar.rules.kr}
                                  </HTMLParser>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                      {single_grammar.examples && (
                        <div className="grammarDetails">
                          <h3>Examples:</h3>
                          <div>
                            <div className="d-flex">
                              <HTMLParser>{single_grammar.examples}</HTMLParser>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </Col>
                  <Col md={4}>
                    <WordDay from="home" type="grammar"></WordDay>
                    {ad.map((item, id) => (
                      <CustomAd item={item} key={id} />
                    ))}
                  </Col>
                </Row>
              </div>
            </div>
          </Container>
        </>
      )}
    </>
  );
};

export default ModuleSingleGrammar;
