import React from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router";
const EsewaFailure = () => {
  const { state } = useLocation();
  let errors;
  if (state != null) {
    errors = state;
  }
  return (
    <div>
      <div className="esewaFailure text-center">
        {errors ? (
          <h4>{errors.errors}</h4>
        ) : (
          <h4>Your Transaction has Failed.</h4>
        )}
        <span>Please Try Again.</span>
        <Link to="/dashboard/ubt-test" class="btn">
          Return to UBT Test
        </Link>
      </div>
    </div>
  );
};
export default EsewaFailure;
