import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Loader from "../../../Components/Loader/Loader";
import HTMLParser from "../../../Components/HTMLParser";
import {
  deselectGrammar,
  grammarCategory,
  selectGrammar,
  singleGrammar,
} from "../../../../Features/GrammarApi/GrammarSlice";
import WordDay from "../../Components/WordDay";
import { getSearch } from "../../../../Features/SearchApi/SearchSlice";
import CustomAd from "../../../Components/CustomAd/CustomAd";
import GrammarDetails from "./GrammarDetails";
const Grammer = () => {
  const dispatch = useDispatch();
  let ad = useSelector(
    (state) =>
      state.ad.allAdPositions["sidebar-grammar-list-dashboard-306-x-250"]
  );
  let token = useSelector((state) => state.auth.currentUser.token);
  let grammar_cat = useSelector((state) => state.grammar.grammarCategory);
  let searchText = useSelector((state) => state.search.getSearchValue);
  let searchLoading = useSelector((state) => state.search.isLoading);
  let isLoading = useSelector((state) => state.grammar.isLoading);
  let isLoadingSingle = useSelector((state) => state.grammar.isLoadingSingle);
  let grammarSingleView = useSelector(
    (state) => state.grammar.isSingleSelected
  );
  const [defaultGrammar, setDefaultGrammar] = useState([]);
  const [currentActive, setCurrentActive] = useState();
  const [isFocused, setIsFocused] = useState(false);
  const [value, setValue] = useState("");
  useEffect(() => {
    if (token) {
      if (grammar_cat) {
        dispatch(grammarCategory(token)).then((res) => {
          if (res.payload.status === 200) {
            setDefaultGrammar(res.payload.data.data[0]);
            setCurrentActive(res.payload.data.data[0].id);
          }
        });
      }
    } else {
      dispatch(grammarCategory()).then((res) => {
        if (res.payload.status === 200) {
          setDefaultGrammar(res.payload.data.data[0]);
          setCurrentActive(res.payload.data.data[0].id);
        }
      });
    }
  }, [dispatch]);
  const changeGrammar = (item) => {
    setValue("");
    dispatch(deselectGrammar());
    setIsFocused(false);
    setCurrentActive(item.id);
    let defaultGrammar =
      grammar_cat &&
      grammar_cat.find((obj) => {
        return obj.title === item.title;
      });
    setDefaultGrammar(defaultGrammar);
  };
  const checkactive = (id) => {
    if (id === currentActive) {
      if (!isFocused) {
        return "btn-active";
      }
    } else {
      return "";
    }
  };
  const handleChange = (e) => {
    setValue(e.target.value);
  };

  const searchValue = (e) => {
    setTimeout(() => {
      if (value.length != 0) {
        dispatch(deselectGrammar());
        dispatch(getSearch({ param: value, model: "Grammar" }));
      }
    }, 250);
  };

  const haveFocus = () => {
    setIsFocused(true);
  };
  const leaveFocus = () => {
    setIsFocused(false);
    if (value.length === 0) {
      setValue("");
    }
  };
  const handleSelectGrammar = (id) => {
    dispatch(selectGrammar());
    if (token) {
      dispatch(singleGrammar({ token, id }));
    } else {
      dispatch(singleGrammar({ id }));
    }
  };
  return (
    <>
      <div className="grammar-container">
        <Row>
          <Col md={8}>
            <div className="grammar__section">
              <div className="grammar-search">
                {grammarSingleView && (
                  <div className="grammar-container">
                    {!isLoadingSingle ? (
                      <GrammarDetails> </GrammarDetails>
                    ) : (
                      <div className="bigLoading loading__mode mb-4">
                        <Loader />
                      </div>
                    )}
                  </div>
                )}
                <Form>
                  <Form.Group controlId="grammar--search">
                    <Form.Control
                      type="search"
                      placeholder="Search"
                      onChange={handleChange}
                      value={value}
                      onFocus={haveFocus}
                      onClick={haveFocus}
                      onBlur={leaveFocus}
                      onKeyUp={searchValue}
                    />
                  </Form.Group>
                  <Button variant="primary" type="submit">
                    <i className="bi bi-search"></i>
                  </Button>
                </Form>
              </div>
              {!isLoading ? (
                <>
                  {defaultGrammar && defaultGrammar.grammars && (
                    <div className="grammar--topics">
                      {grammar_cat.map((item, id) => (
                        <div key={id}>
                          <Button
                            to=""
                            className={`btn btn-outline ${checkactive(
                              item.id
                            )} `}
                            key={id}
                            onClick={() => changeGrammar(item)}
                          >
                            {item.title}
                          </Button>
                        </div>
                      ))}
                    </div>
                  )}
                  {!isFocused && value.length === 0 ? (
                    <div
                      className={
                        grammarSingleView
                          ? " hidesingle_g_d"
                          : "grammar--details"
                      }
                    >
                      {defaultGrammar && defaultGrammar.grammars && (
                        <>
                          {defaultGrammar.grammars.map((item, id) => {
                            return (
                              <div className="grammar-listeach" key={id}>
                                <h3
                                  key={id}
                                  onClick={() => handleSelectGrammar(item.id)}
                                >
                                  {item.word}
                                </h3>
                                <HTMLParser>{item.excerpt}</HTMLParser>
                              </div>
                            );
                          })}
                        </>
                      )}
                    </div>
                  ) : (
                    <div
                      className={
                        grammarSingleView
                          ? " hidesingle_g_d"
                          : "grammar--details"
                      }
                    >
                      {!searchLoading ? (
                        <>
                          {searchText.length > 0 ? (
                            <>
                              {searchText.map((item, id) => {
                                return (
                                  <div key={id}>
                                    <h3
                                      key={id}
                                      onClick={() =>
                                        handleSelectGrammar(item.id)
                                      }
                                    >
                                      {item.word}
                                    </h3>
                                    <HTMLParser>{item.description}</HTMLParser>
                                  </div>
                                );
                              })}
                            </>
                          ) : (
                            <>
                              {value.length > 0 && (
                                <div className="text-center font-weight-bold nosearchresult">
                                  No Search Result Found
                                </div>
                              )}
                            </>
                          )}
                          <>
                            {value.length === 0 && (
                              <div className="search">Search...</div>
                            )}
                          </>
                        </>
                      ) : (
                        <div className="loading__mode bigLoading">
                          <Loader label="Searching" />
                        </div>
                      )}
                    </div>
                  )}
                </>
              ) : (
                <div className="loading__mode bigLoading">
                  <Loader />
                </div>
              )}
            </div>
          </Col>
          <Col md={4}>
            <div className="sticky">
              <WordDay
                handleSelection={handleSelectGrammar}
                type="grammar"
                from="dashboard"
              />
              {ad.map((item, id) => (
                <CustomAd item={item} key={id} />
              ))}
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Grammer;
