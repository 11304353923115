import React, { useState } from "react";
import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createNewPassword } from "../../../Features/AuthSlice";
import { openPasswordSuccess } from "../../../Features/ViewSlice";
const CreateNewPassword = () => {
  let dispatch = useDispatch();
  let inputPassword = useRef(null);
  let confirmPassword = useRef(null);
  let otpToken = useSelector((state) => state.auth.otpToken);
  const [passwords, setPasswords] = useState({
    password: "",
    password_confirmation: "",
  });
  const togglePasswordShow = () => {
    inputPassword.current.getAttribute("type") === "password"
      ? inputPassword.current.setAttribute("type", "text")
      : inputPassword.current.setAttribute("type", "password");
  };
  const toggleConfirmPasswordShow = () => {
    confirmPassword.current.getAttribute("type") === "password"
      ? confirmPassword.current.setAttribute("type", "text")
      : confirmPassword.current.setAttribute("type", "password");
  };
  const handleChange = (e) => {
    setPasswords({ ...passwords, [e.target.name]: e.target.value });
  };
  const createNew = () => {
    dispatch(createNewPassword({ otpToken, passwords }));
    dispatch(openPasswordSuccess());
  };
  return (
    <div>
      <h2>Create new password</h2>
      <p>Your new password must be unique from those previously used.</p>
      <label htmlFor="password" className="password-container">
        <input
          name="password"
          ref={inputPassword}
          placeholder="Enter your password"
          type="password"
          onChange={handleChange}
        ></input>
        <i className="bi bi-eye-slash" onClick={togglePasswordShow}></i>
      </label>
      <label htmlFor="password_confirmation" className="password-container">
        <input
          ref={confirmPassword}
          name="password_confirmation"
          type="password"
          placeholder="Confirm Password"
          onChange={handleChange}
        ></input>
        <i className="bi bi-eye-slash" onClick={toggleConfirmPasswordShow}></i>
      </label>
      <button onClick={createNew}>Create New Password</button>
    </div>
  );
};

export default CreateNewPassword;
