import React, { useState, useRef, useCallback } from "react";
import facebooklogin from "../../../Assets/img/facebook-logo.png";
import googlelogin from "../../../Assets/img/google.png";
import { LoginSocialGoogle, LoginSocialFacebook } from "reactjs-social-login";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import {
  openModal,
  openLoginForm,
  openRegisterImgRefForm,
} from "../../../Features/ViewSlice";
import { toast } from "react-toastify";
import { saveTempData } from "../../../Features/AuthSlice";
const RegisterForm = ({ handleFirstStep, handleChange }) => {
  //const REDIRECT_URI = "https://localhost:3000";
  const REDIRECT_URI = "https://lkwb.com.np/dashboard";
  let inputPassword = useRef(null);
  let confirmPassword = useRef(null);
  let navigate = useNavigate();
  let dispatch = useDispatch();
  const togglePasswordShow = () => {
    inputPassword.current.getAttribute("type") === "password"
      ? inputPassword.current.setAttribute("type", "text")
      : inputPassword.current.setAttribute("type", "password");
  };
  const toggleConfirmPasswordShow = () => {
    confirmPassword.current.getAttribute("type") === "password"
      ? confirmPassword.current.setAttribute("type", "text")
      : confirmPassword.current.setAttribute("type", "password");
  };

  const onLogoutSuccess = useCallback(() => {
    alert("logout success");
  }, []);
  return (
    <form className="registerForm">
      <i className="bi bi-x-lg closebutton"></i>
      <h2>Register</h2>
      <p>We help you to learn korean language with ease.</p>
      <div className="loginCustom">
        <label htmlFor="username">
          <input
            name="username"
            type="text"
            placeholder="Enter your name"
            onChange={handleChange}
          ></input>
        </label>
        <label htmlFor="email">
          <input
            name="email"
            type="text"
            placeholder="Enter your email or username"
            onChange={handleChange}
          ></input>
        </label>
        <label htmlFor="password" className="password-container">
          <input
            name="password"
            ref={inputPassword}
            placeholder="Enter your password"
            type="password"
            onChange={handleChange}
          ></input>
          <i className="bi bi-eye-slash" onClick={togglePasswordShow}></i>
        </label>
        <label htmlFor="password_confirmation" className="password-container">
          <input
            ref={confirmPassword}
            name="password_confirmation"
            type="password"
            placeholder="Confirm Password"
            onChange={handleChange}
          ></input>
          <i
            className="bi bi-eye-slash"
            onClick={toggleConfirmPasswordShow}
          ></i>
        </label>
        <label htmlFor="termsCondition" className="termsCondions">
          *By signing up, you agree to our Terms and Conditions.
        </label>
        <button onClick={handleFirstStep}>Register</button>
      </div>
      <div className="socialLogin">
        <div className="orloginwith">
          <p>or sign up with</p>
        </div>
        <div className="socialmedia-login">
          <LoginSocialGoogle
            client_id={process.env.REACT_APP_GOOGLEKEY}
            redirect_uri={REDIRECT_URI}
            scope="openid profile email"
            discoveryDocs="claims_supported"
            access_type="offline"
            onResolve={({ provider, data }) => {
              let registerData = {
                name: data.name,
                email: data.email,
                photo: data.picture,
                provider_id: data.sub,
                provider: provider,
              };
              dispatch(saveTempData(registerData));
              dispatch(openRegisterImgRefForm());
            }}
            onReject={(err) => {
              toast.error(err);
            }}
          >
            <p className="eachlogin-btn google-login">
              <img src={googlelogin} alt="" />
              <span>Continue with Google</span>
            </p>
          </LoginSocialGoogle>
          <LoginSocialFacebook
            appId="1341775266585111"
            onLogoutSuccess={onLogoutSuccess}
            redirect_uri={REDIRECT_URI}
            onResolve={({ provider, data }) => {
              let registerData = {
                name: data.name,
                email: data.email,
                photo: data.picture.data.url,
                provider_id: data.id,
                provider: provider,
              };
              dispatch(saveTempData(registerData));
              dispatch(openRegisterImgRefForm());
            }}
            onReject={(err) => {
              toast.error(err);
            }}
          >
            <p className="eachlogin-btn facebook-login">
              <img src={facebooklogin} alt="" />
              <span>Continue with Facebook</span>
            </p>
          </LoginSocialFacebook>
        </div>
        <p className="registernow">
          Already have an account?{" "}
          <span
            onClick={() => {
              dispatch(openModal("loginView"));
              dispatch(openLoginForm());
            }}
          >
            Sign In
          </span>
        </p>
      </div>
    </form>
  );
};

export default RegisterForm;
