import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getProfile,
  editProfile,
} from "../../../../Features/Profile_management/profileSlice";
import { toast } from "react-toastify";
import Compressor from "compressorjs";
const EditProfile = () => {
  let dispatch = useDispatch();
  let authToken = useSelector((state) => state.auth.currentUser.token);
  let profileInfo = useSelector((state) => state.profile.profileInfo);
  const [imageUrl, setImageUrl] = useState("");
  const [image, setImage] = useState("");
  const [profileDetails, setProfileDetails] = useState({
    username: "",
    password: "",
    password_confirmation: "",
  });
  let inputPassword = useRef(null);
  let confirmPassword = useRef(null);
  const togglePasswordShow = () => {
    inputPassword.current.getAttribute("type") === "password"
      ? inputPassword.current.setAttribute("type", "text")
      : inputPassword.current.setAttribute("type", "password");
  };
  const toggleConfirmPasswordShow = () => {
    confirmPassword.current.getAttribute("type") === "password"
      ? confirmPassword.current.setAttribute("type", "text")
      : confirmPassword.current.setAttribute("type", "password");
  };
  const handleLogoChange = (e) => {
    let files = e.target.files[0];
    setImageUrl(URL.createObjectURL(e.target.files[0]));
    new Compressor(files, {
      quality: 0.1,
      success: (compressedResult) => {
        setImage(compressedResult);
      },
    });
  };
  const handleChange = (e) => {
    setProfileDetails({ ...profileDetails, [e.target.name]: e.target.value });
  };
  useEffect(() => {
    dispatch(getProfile(authToken));
  }, [dispatch, authToken]);
  const saveChange = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("_method", "PUT");
    if (profileDetails.username.length !== 0) {
      formData.append("name", profileDetails.username);
    }
    if (
      profileDetails.password.length > 0 &&
      profileDetails.password_confirmation.length === 0
    ) {
      toast.error("Please enter password confirmation");
      return;
    }
    if (
      profileDetails.password.length === 0 &&
      profileDetails.password_confirmation.length < 0
    ) {
      toast.error("Please enter password ");
      return;
    }
    if (
      profileDetails.password.length !== 0 &&
      profileDetails.password_confirmation.length !== 0
    ) {
      if (profileDetails.password === profileDetails.password_confirmation) {
        formData.append("password", profileDetails.password);
        formData.append(
          "password_confirmation",
          profileDetails.password_confirmation
        );
      } else {
        toast.error("Passwords does not match");
        return;
      }
    }
    if (image !== "") {
      formData.append("image", image);
    }
    dispatch(editProfile({ formData, authToken })).then((res) => {
      if (res.payload.status === 200) {
        toast.success("Profile Updated Successfully");
      }
    });
  };

  return (
    <Row>
      <h2 className="dashboard-page-title">Edit Your Profile</h2>
      <Col md={6}>
        <div className="currentprofile-view">
          <div className="insertimage">
            <div className="image-container editprofile">
              {imageUrl ? (
                <img src={imageUrl} alt={imageUrl} />
              ) : (
                <img src={profileInfo.image} alt={profileInfo.image} />
              )}
              <label htmlFor="fileupload">
                <input
                  id="fileupload"
                  type="file"
                  accept="image/*"
                  name="myImage"
                  onChange={handleLogoChange}
                />
                <i className="bi bi-cloud-plus"></i>
              </label>
            </div>
            <div className="profile-text">
              <h4>{profileInfo.name}</h4>
              <span>{profileInfo.refCode}</span>
              <p>{profileInfo.email}</p>
            </div>
          </div>
        </div>
      </Col>
      <Col md={6}>
        <form className="editprofile-container" onSubmit={saveChange}>
          <label htmlFor="username">
            Full Name
            <input
              type="name"
              name="username"
              placeholder="Full Name *"
              onChange={handleChange}
            />
          </label>
          <label htmlFor="password" className="password-container">
            Password
            <input
              type="password"
              name="password"
              ref={inputPassword}
              onChange={handleChange}
              placeholder="*****"
            />
            <i className="bi bi-eye-slash" onClick={togglePasswordShow}></i>
          </label>
          <label htmlFor="password_confirmation" className="password-container">
            <input
              ref={confirmPassword}
              name="password_confirmation"
              type="password"
              placeholder="Confirm Password"
              onChange={handleChange}
            ></input>
            <i
              className="bi bi-eye-slash"
              onClick={toggleConfirmPasswordShow}
            ></i>
          </label>
          <button className="btn"> Save Changes</button>
        </form>
      </Col>
    </Row>
  );
};

export default EditProfile;
