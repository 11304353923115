import React from "react";

const ContactLinks = ({ links, extraClass }) => {
  return (
    <>
      <h2 className="footerhead">{links.header}</h2>
      <ul className={`${extraClass} footer-links`}>
        <li>
          <i className="bi bi-geo-alt-fill"></i>
          <span>{links.address}</span>
        </li>
        <li>
          <i className="bi bi-telephone-fill"></i>
          <a aria-label={links.phoneNum} href={`tel:${links.phoneNum}`}>
            {links.phoneNum}
          </a>
        </li>
        <li>
          <i className="bi bi-envelope-fill"></i>
          <a aria-label={links.mail} href={`mailto:${links.mail}`}>{links.mail}</a>
        </li>
      </ul>
    </>
  );
};

export default ContactLinks;
