import React, { useEffect, useState } from "react";
import { Row, Col, Modal } from "react-bootstrap";
import PointImg from "../../Assets/img/lkwb-reward.png";
import rewardPoint from "../../Assets/img/checked.png";
import ProgressBar from "./Components/ProgressBar/ProgressBar";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { allSets, mostBoughtSets } from "../../Features/SetsAPI/SetsSlice";
import { getProfile } from "../../Features/Profile_management/profileSlice";
import SetsItem from "../../views/DashboardView/Components/SetsItem";
import { blogInfo } from "../../Features/HomeApi/HomeSlice";
import { progressScore } from "../../Features/ProgressApi/ProgressSlice";
import { ChartContainer } from "./Components/ChartJS/ChartContainer";
import { hasDisplayedToast } from "../../Features/AuthSlice";
import UBTPackages from "./Container/UBTPages/UBTPackages";
import NormalSetsItem from "./Components/NormalSetsItem";
import MainBlog from "../Components/Blog/MainBlog";
import CustomAd from "../Components/CustomAd/CustomAd";
import ReclaimModal from "../DashboardView/Components/ReclaimModal";

const Dashboard = () => {
  let ad = useSelector(
    (state) => state.ad.allAdPositions["banner-home-dashboard-1920-x-100"]
  );
  let isNewUser = useSelector((state) => state.auth.currentUser.isNewUser);
  let isEmailVerified = useSelector((state) => state.auth.isEmailVerified);
  let newUserToast = useSelector((state) => state.auth.newUserToast);
  let dispatch = useDispatch();
  let authToken = useSelector((state) => state.auth.currentUser.token);
  let profileInfo = useSelector((state) => state.profile.profileInfo);
  useEffect(() => {
    isEmailVerified && dispatch(getProfile(authToken));
  }, [dispatch]);
  let token = useSelector((state) => state.auth.currentUser.token);
  let blog_Info = useSelector((state) => state.home.blogInfo);
  let allSetslist = useSelector((state) => state.set.allSets);
  let most_boughtSet = useSelector((state) => state.set.mostBoughtSets);
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  useEffect(() => {
    dispatch(allSets(token));
    dispatch(mostBoughtSets());
    dispatch(blogInfo(token));
    dispatch(progressScore(token));
    if (isNewUser) {
      if (!newUserToast) {
        handleShow();
        dispatch(hasDisplayedToast());
      }
    }
  }, [dispatch]);

  const [reclaim, setReclaim] = useState(false);
  const showReclaim = () => setReclaim(true);
  const closeReclaim = () => setReclaim(false);
  return (
    <>
      {profileInfo && (
        <div className="dashboard__main">
          <h2 className="dashboard-page-title greetinguser">
            Hi!👋 <span>{profileInfo.name}</span>
          </h2>
          <div className="bigreclaim">
            <p>
              Click here, If transaction is complete, but the set has not
              opened.
            </p>
            <div className=" reclaimbtn">
              <button className="btn" onClick={showReclaim}>
                Reclaim
              </button>
              <ReclaimModal reclaim={reclaim} closeReclaim={closeReclaim} />
            </div>
          </div>
          <Row>
            <Row className="secondrow-pad">
              <Col md={7}>
                <Row>
                  <Col md={12}>
                    <div className="dashboard--card">
                      <div className="totalscore">
                        <div className="score">
                          <p>Reward Points</p>
                          <h3>{profileInfo.rewardPoint}</h3>
                        </div>
                        <img src={PointImg} alt="" />
                      </div>
                    </div>
                  </Col>
                  <Col md={12}>
                    <ChartContainer />
                  </Col>
                </Row>
              </Col>
              <Col md={5}>
                <ProgressBar />
                <div className="purchasedset-dashboard mt-3">
                  {allSetslist && (
                    <>
                      <h3>
                        Purchased Set{" "}
                        <Link to="/dashboard/ubt-test/purchasedset">
                          View All
                        </Link>
                      </h3>
                      <div className="purchasedSetsItem setstitem-container">
                        {allSetslist.filter((item) => item.status === true)
                          .length > 0 ? (
                          <>
                            {allSetslist
                              .filter((item) => item.status === true)
                              .slice(0, 3)
                              .map((item, id) => (
                                <NormalSetsItem key={id} details={item} />
                              ))}
                          </>
                        ) : (
                          <div className="youhavenot-container mt-3">
                            <p>You have not purchased any set.</p>
                            <Link
                              className="btn btn-small mt-2"
                              to="/dashboard/ubt-test"
                            >
                              Buy sets
                            </Link>
                          </div>
                        )}
                      </div>
                    </>
                  )}
                </div>
              </Col>
            </Row>
            <Col md={12} className="dashboard-package-wrapper mt-3">
              <h3>All packages</h3>
              <UBTPackages type="columns" />
              {ad.map((item, id) => (
                <CustomAd key={id} item={item} />
              ))}
            </Col>
            <Col md={6}>
              <div className="dashboard-section ubtTest__section">
                <h3 className="mb-3">
                  Most Purchased Sets
                  <Link to="/dashboard/ubt-test">View All</Link>
                </h3>
                <div className="setstitem-container">
                  {mostBoughtSets.length > 0 && (
                    <>
                      {most_boughtSet.slice(0, 3).map((item, id) => (
                        <SetsItem key={id} details={item} />
                      ))}
                    </>
                  )}
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className="dashboard-section ubtTest__section">
                <h3 className="mb-3">
                  New Sets <Link to="/dashboard/ubt-test">View All</Link>
                </h3>
                <div className="setstitem-container">
                  {allSetslist
                    .filter((item) => item.isNew === true)
                    .slice(0, 3)
                    .map((item, id) => (
                      <SetsItem key={id} details={item} />
                    ))}
                </div>
              </div>
            </Col>

            <Col md={12}>
              {blog_Info && (
                <div className="dashboard-section page-container">
                  <h3 className="mb-3">LKWB Blogs</h3>
                  <div className="blog-container">
                    <Row md={3} sm={2} xs={1} style={{ rowGap: 20 }}>
                      {blog_Info.map((item, id) => (
                        <Col key={id}>
                          <MainBlog
                            type="singlecolumn"
                            blog={item}
                            blogFrom="dashboard"
                          />
                        </Col>
                      ))}
                    </Row>
                  </div>
                </div>
              )}
            </Col>
          </Row>
          <Modal show={show} onHide={handleClose} centered>
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
              <div className="rewardNotice">
                <img src={rewardPoint} alt="Reward Point earned" />
                <h4>
                  Congratulations <br />
                  You’ve successfully set up your account.
                </h4>
                <p>You have Earned 5 points for signing with us!</p>
              </div>
            </Modal.Body>
          </Modal>
        </div>
      )}
    </>
  );
};

export default Dashboard;
