import { BASE_URL } from "../../Constants";
import axios from "axios";

export const progress_score = async (token) => {
  let url = `${BASE_URL}/api/last-test-score`;
  let headers = {
    authorization: `Bearer ${token}`,
  };
  const res = await axios.get(url, {
    headers: headers,
  });
  return res;
};
