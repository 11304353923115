import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { search_data } from "./SearchApi";
const initialState = {
  isLoading: false,
  getSearchValue: [],
};

export const getSearch = createAsyncThunk(
  "search/getSearch",
  async (data, { rejectWithValue }) => {
    try {
      return await search_data(data);
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err);
    }
  }
);
const SearchSlice = createSlice({
  name: "search",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getSearch.pending, (state) => {
        state.isLoading = true;
        state.getSearchValue = [];
      })
      .addCase(getSearch.fulfilled, (state, action) => {
        state.isLoading = false;
        state.getSearchValue =
          action.payload.data.data === null ? [] : action.payload.data.data;
      })
      .addCase(getSearch.rejected, (state) => {
        state.isLoading = false;
        state.getSearchValue = [];
      });
  },
});
export default SearchSlice.reducer;
