import { BASE_URL } from "../../Constants";
import axios from "axios";

export const all_sets = async (token) => {
  let url = `${BASE_URL}/api/all-sets`;
  let headers = {
    authorization: `Bearer ${token}`,
  };
  const res = await axios.get(url, {
    headers: headers,
  });
  return res;
};

export const each_Sets = async (data) => {
  let url = `${
    data.from === "package"
      ? `${BASE_URL}/api/sets/${data.id}?type=package`
      : `${BASE_URL}/api/sets/${data.id}`
  }`;
  let headers = {
    authorization: `Bearer ${data.token}`,
  };
  const res = await axios.get(url, {
    headers: headers,
  });
  return res;
};

export const buy_Sets = async (data) => {
  // let url = `${BASE_URL}/api/sets/${data.id}/buy`;
  let url = `${BASE_URL}/api/sets/${data.id}/buy/${data.provider}`;
  let headers = {
    authorization: `Bearer ${data.token}`,
  };
  const res = await axios.post(url, data.formdata, {
    headers: headers,
  });
  // const res = await axios.post(url, data.formdata);
  return res;
};

export const buy_Package = async (data) => {
  // let url = `${BASE_URL}/api/sets/${data.id}/buy`;
  let url = `${BASE_URL}/api/package/${data.id}/buy/${data.provider}`;
  let headers = {
    authorization: `Bearer ${data.token}`,
  };
  const res = await axios.post(url, data.formdata, {
    headers: headers,
  });
  // const res = await axios.post(url, data.formdata);
  return res;
};

export const redirectKhalti = async (data) => {
  let url = `${BASE_URL}/api/khalti-redirect`;
  let headers = {
    authorization: `Bearer ${data.token}`,
  };
  const res = await axios.post(url, data.data, {
    headers: headers,
  });
  return res;
};
export const claim_sets = async (data) => {
  let url = `${BASE_URL}/api/sets/${data.id}/buy/free`;
  let headers = {
    authorization: `Bearer ${data.token}`,
  };
  const res = await axios.post(url, data.formdata, {
    headers: headers,
  });
  // const res = await axios.post(url, data.formdata);
  return res;
};
export const buy_setsRewardPoint = async (data) => {
  let url = `${BASE_URL}/api/sets/${data.id}/buy/rewardPoint`;
  let headers = {
    authorization: `Bearer ${data.token}`,
  };
  const res = await axios.post(url, data.formdata, {
    headers: headers,
  });
  // const res = await axios.post(url, data.formdata);
  return res;
};
export const buy_packageRewardPoint = async (data) => {
  let url = `${BASE_URL}/api/package/${data.id}/buy/rewardPoint`;
  let headers = {
    authorization: `Bearer ${data.token}`,
  };
  const res = await axios.post(url, data.formdata, {
    headers: headers,
  });
  // const res = await axios.post(url, data.formdata);
  return res;
};

export const questions = async (data) => {
  let url = `${BASE_URL}/api/sets/${data.setsid}/questions`;
  let headers = {
    authorization: `Bearer ${data.token}`,
  };
  const res = await axios.get(url, {
    headers: headers,
  });
  return res;
};

export const each_Question = async (data) => {
  let url = `${BASE_URL}/api/question/${data.id}`;
  let headers = {
    authorization: `Bearer ${data.token}`,
  };
  const res = await axios.get(url, {
    headers: headers,
  });
  return res;
};

export const save_results = async (data) => {
  let url = `${BASE_URL}/api/sets/store-result`;
  let headers = {
    authorization: `Bearer ${data.token}`,
  };
  const res = await axios.post(url, data.formData, {
    headers: headers,
  });
  return res;
};

export const all_package = async (token) => {
  let url = `${BASE_URL}/api/all-packages`;
  let headers = {
    authorization: `Bearer ${token}`,
  };
  const res = await axios.get(url, {
    headers: headers,
  });
  return res;
};
export const single_package = async (data) => {
  let url = `${BASE_URL}/api/all-packages/${data.id}`;
  let headers = {
    authorization: `Bearer ${data.token}`,
  };
  const res = await axios.get(url, {
    headers: headers,
  });
  return res;
};
export const prelog = async (data) => {
  let url = `${BASE_URL}/api/prelogs`;
  let headers = {
    authorization: `Bearer ${data.token}`,
  };
  const formdata = new FormData();
  formdata.append("gateway", data.gateway);
  formdata.append("response", data.response);
  const res = await axios.post(url, formdata, {
    headers: headers,
  });
  return res;
};

export const reclaim = async (data) => {
  let url = `${BASE_URL}/api/store/transaction-claims`;
  let headers = {
    authorization: `Bearer ${data.token}`,
  };
  const formdata = new FormData();
  formdata.append("txn_id", data.tid);
  formdata.append("gateway", data.gateway);
  const res = await axios.post(url, formdata, {
    headers: headers,
  });
  return res;
};

export const most_bought_set = async () => {
  let url = `${BASE_URL}/api/home/most-bought-sets`;
  const res = await axios.get(url);
  return res;
};
