import React from "react";
import { Link } from "react-router-dom";

const DictionarySidebar = ({ setConvert, currentConvert }) => {
  return (
    <div className=" dictonarySidebar">
      <h2 className="footerhead">Words To Discover</h2>
      <ul>
        <li>
          <Link
            className={currentConvert === "k-n" ? "active" : " "}
            to="#"
            onClick={(e) => {
              e.preventDefault();
              setConvert("k-n");
            }}
          >
            Korean To Nepali{" "}
          </Link>
        </li>
        <li>
          <Link
            className={currentConvert === "k-e" ? "active" : " "}
            to="#"
            onClick={(e) => {
              e.preventDefault();
              setConvert("k-e");
            }}
          >
            Korean To English{" "}
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default DictionarySidebar;
