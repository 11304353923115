import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { closeModal } from "../../../Features/ViewSlice";
const Modal = ({ children, size }) => {
  const dispatch = useDispatch();
  const closeLogin = (e) => {
    if (
      e.target.classList.contains("modalContainer") ||
      e.target.classList.contains("closebutton") ||
      e.target.classList.contains("container")
    ) {
      dispatch(closeModal());
    }
  };
  let isModelOpen = useSelector((state) => state.view.openModal);
  return (
    <>
      {isModelOpen ? (
        <div className="modalContainer" onClick={closeLogin}>
          <div className={`${size} modalbody`}>
            <i className="bi bi-x-lg closebutton"></i>
            {children}
          </div>
        </div>
      ) : null}
    </>
  );
};

export default Modal;
