import { BASE_URL } from "../../Constants";
import axios from "axios";

export const get_grammar = async () => {
  let url = `${BASE_URL}/api/home/all-grammar`;
  const res = await axios.get(url);
  return res;
};
export const grammar_category = async () => {
  let url = `${BASE_URL}/api/home/grammar-category`;
  const res = await axios.get(url);
  return res;
};
export const single_grammar = async (data) => {
  let res;
  if (data.token) {
    let url = `${BASE_URL}/api/grammar-word/${data.id}`;
    let headers = {
      authorization: `Bearer ${data.token}`,
    };
    res = await axios.get(url, {
      headers: headers,
    });
  } else {
    let url = `${BASE_URL}/api/home/grammar-word/${data.id}`;
    res = await axios.get(url);
  }
  return res;
};

export const grammarOf_theDay = async () => {
  let url = `${BASE_URL}/api/grammar-of-the-day`;
  const res = await axios.get(url);
  return res;
};
