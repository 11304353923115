import React, { useEffect, useRef, useState } from "react";
import listeningQues from "../../../../Assets/img/listening.png";
import audioPlay from "../../../../Assets/img/play.png";
import HTMLParser from "../../../Components/HTMLParser";
import { shuffleArray } from "../../../../Constants";
import { useDispatch, useSelector } from "react-redux";
import Modal from "react-bootstrap/Modal";
import {
  saveSolvedQuestions,
  updateSolvedQuestions,
  selectLocalQuestion,
  questionAudioPlayed,
  audioPlayed,
} from "../../../../Features/SetsAPI/LocalSetSlice";
import QuestionOption from "../../Components/QuestionOption";
import { toast } from "react-toastify";
const UBTQuestion = ({ question, questionNo }) => {
  const dispatch = useDispatch();
  const audioRef = useRef(null);
  let countForAudio = 0;
  const [selectedQuestion, setSelectedQuestion] = useState({});
  const toggleAudio = () => {
    if (audioRef.current === null) {
      toast.error("Audio component is not loaded yet.");
    } else {
      if (audioRef.current.paused) {
        countForAudio++;
        if (countForAudio <= 1) {
          let idOfQuestion = selectedQuestion.id;
          dispatch(questionAudioPlayed({ idOfQuestion }));
          audioRef.current.parentElement.classList.add("played");
          audioRef.current.play();
        }
      } else {
        audioRef.current.paused();
      }
    }
  };
  const optionsAudio = useRef([]);
  const toggleOptionsAudio = (e) => {
    audioRef.current.pause();
    if (optionsAudio.current[e.target.id] != null) {
      for (let i = 0; i < optionsAudio.current.length; i++) {
        optionsAudio.current[i].pause();
        if (optionsAudio.current[i] === optionsAudio.current[e.target.id]) {
          optionsAudio.current[i].parentElement.classList.add("played");
          let idOfOption =
            optionsAudio.current[
              i
            ].parentElement.parentElement.previousSibling.getAttribute("id");
          let idOfQuestion = selectedQuestion.id;
          dispatch(
            audioPlayed({
              idOfOption,
              idOfQuestion,
            })
          );
          if (optionsAudio.current[i].pause) {
            optionsAudio.current[i].play();
          } else {
            optionsAudio.current[i].pause();
          }
        }
      }
    } else {
      alert("No Audio File!!!!");
    }
  };
  let solvedQuestions = useSelector((state) => state.localset.solvedQuestion);
  const findExistingQuestion = (array) => {
    return array.find(({ id }) => id === question.id);
  };
  const [answerChange, setAnswerChange] = useState("");
  const removePlayedClass = () => {
    if (optionsAudio.current[0] !== null) {
      for (let i = 0; i < optionsAudio.current.length; i++) {
        optionsAudio.current[i].parentElement.classList.remove("played");
      }
    }
    if (audioRef.current !== null) {
      audioRef.current.parentElement.classList.remove("played");
    }
  };
  useEffect(() => {
    let FoundQuestion = findExistingQuestion(solvedQuestions);
    let result = false;
    if (findExistingQuestion(solvedQuestions) !== undefined) {
      setSelectedQuestion({ ...FoundQuestion });
      FoundQuestion.answers.options.forEach((item, id) => {
        if (item.hasOwnProperty("userPlayed")) {
          result = true;
        }
      });
      if (!result) {
        removePlayedClass();
      }
      let answerid = FoundQuestion.answers.options.find(
        ({ userSelected }) => userSelected === true
      );
      if (answerid === undefined) {
        setAnswerChange("");
      }
      if (answerid !== undefined) {
        setAnswerChange(answerid.id);
      }
    }
    if (findExistingQuestion(solvedQuestions) === undefined) {
      removePlayedClass();
      let newQuestion = JSON.parse(JSON.stringify(question));
      newQuestion.answers.options = shuffleArray(newQuestion.answers.options);
      setSelectedQuestion({ ...selectedQuestion, ...newQuestion });
      dispatch(saveSolvedQuestions(newQuestion));
      dispatch(selectLocalQuestion(newQuestion));
    }
  }, [dispatch, question]);
  const submitAnswer = (e) => {
    e.stopPropagation();
    let FoundQuestion = findExistingQuestion(solvedQuestions);
    if (FoundQuestion !== undefined) {
      dispatch(
        updateSolvedQuestions({
          id: FoundQuestion.id,
          value: parseInt(e.currentTarget.id),
        })
      );
      setAnswerChange(e.currentTarget.id);
    }
  };
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [optionImg, setOptionImg] = useState();
  const handleClose1 = () => setShow1(false);
  const handleClose = () => setShow(false);
  const handleShow = (img) => {
    setOptionImg(img);
    setShow(true);
  };
  const handleShow1 = () => setShow1(true);
  return (
    <>
      {selectedQuestion ? (
        <div className="questions">
          <h4>
            {/* {question.id}  */}
            {selectedQuestion.groupTitle}
          </h4>
          <div className="d-flex questionGroups">
            <span>{questionNo}.</span>
            <div>
              {selectedQuestion.mainTitle && (
                <div className="d-flex">
                  <h4>
                    <HTMLParser>{question.mainTitle}</HTMLParser>
                  </h4>
                </div>
              )}
              {selectedQuestion.type === "audio" && selectedQuestion.file && (
                <div
                  className={`image-container audioImg ${
                    selectedQuestion.userPlayedQuestion && "played"
                  }`}
                >
                  <img
                    loading="lazy"
                    src={audioPlay}
                    alt=""
                    onClick={toggleAudio}
                  />
                  <audio
                    loading="lazy"
                    ref={audioRef}
                    src={question.file}
                  ></audio>
                  <p>Click here to play.</p>
                  {selectedQuestion.answers.type === "audio" && (
                    <p className="note">
                      You cannot play a sound more than once.
                    </p>
                  )}
                </div>
              )}
              {selectedQuestion.subTitle && (
                <div className="d-flex mt-2">
                  <h5>
                    <HTMLParser>{question.subTitle}</HTMLParser>
                  </h5>
                </div>
              )}
              {selectedQuestion.type === "image" && selectedQuestion.file && (
                <div className="image-container">
                  <img src={question.file} alt="" onClick={handleShow1} />
                  <Modal show={show1} onHide={handleClose1}>
                    <Modal.Header closeButton></Modal.Header>
                    <Modal.Body>
                      <img src={question.file} alt="" />
                    </Modal.Body>
                    <Modal.Footer></Modal.Footer>
                  </Modal>
                </div>
              )}
              <div className="mcqAnswers">
                {selectedQuestion.answers &&
                  selectedQuestion.answers.options.map((item, id) => (
                    <div className="mb-3" key={id}>
                      <QuestionOption
                        optionId={id}
                        label={
                          <>
                            {selectedQuestion.answers.type === "audio" && (
                              <p
                                className={`optionimg ${item.userPlayed ? "played" : ""}`}
                              >
                                <img
                                  loading="lazy"
                                  id={id}
                                  src={listeningQues}
                                  className="audioPlayClass"
                                  alt=""
                                  onClick={toggleOptionsAudio}
                                />
                                <audio
                                  loading="lazy"
                                  ref={(el) => (optionsAudio.current[id] = el)}
                                  src={item.option}
                                />
                              </p>
                            )}
                            {selectedQuestion.answers.type === "text" &&
                              item.option}
                            {selectedQuestion.answers.type === "image" && (
                              <img
                                loading="lazy"
                                src={item.option}
                                onClick={() => handleShow(item.option)}
                                alt=""
                              />
                            )}

                            <Modal show={show} onHide={handleClose}>
                              <Modal.Header closeButton></Modal.Header>
                              <Modal.Body>
                                <img src={optionImg} alt="" />
                              </Modal.Body>
                            </Modal>
                          </>
                        }
                        type="questionItem"
                        selected={answerChange}
                        handleClick={submitAnswer}
                        value={item.id}
                      />
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div>Question Has not Loaded Properly Please Retry</div>
      )}
    </>
  );
};

export default UBTQuestion;
