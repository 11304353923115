import React, { useEffect } from "react";
import { Row, Col, Container } from "react-bootstrap";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import DiscussionSingleCommentItem from "../DiscussionComponents/DiscussionSingleCommentItem";
import DiscussionCommentAdd from "../DiscussionComponents/DiscussionCommentAdd";
import { Link } from "react-router-dom";
import { singleDiscussion } from "../../../Features/DiscussionsAPI/DiscussionsSlice";
import { openLoginForm, openModal } from "../../../Features/ViewSlice";
const ModuleSingleDiscussion = () => {
  let navigate = useNavigate();
  let token = useSelector((state) => state.auth?.currentUser?.token);
  let selectedDiscussion = useSelector(
    (state) => state.discussion.selectedDiscussion
  );
  const dispatch = useDispatch();
  const { id } = useParams();
  useEffect(() => {
    dispatch(singleDiscussion({ token, id }));
  }, [dispatch, id]);
  const getCreatedAt = (date) => {
    let newDate = new Date(date);
    return `${newDate.toLocaleDateString()} at ${newDate.getUTCHours()}:${newDate.getUTCMinutes()}:${newDate.getUTCSeconds()}`;
  };
  const showLoginDialog = () => {
    dispatch(openModal("loginView"));
    dispatch(openLoginForm());
  };
  return (
    <Container>
      {Object.keys(selectedDiscussion).length > 0 && (
        <div className="home__dictionary ">
          <Link to="/discussion" className="viewallbtn">
            View all Dictionary
          </Link>
          <Row>
            <Col md={9} className="single-discussion">
              <div className="single__comment">
                <div>
                  <div className="d-flex flex-wrap">
                    <div className="person-image">
                      <img src={selectedDiscussion.user_image} alt="test" />
                    </div>

                    <div className="userCommentAll">
                      <>
                        <h3>{selectedDiscussion.title}</h3>
                        <div className="userProfile">
                          <strong>Posted By {selectedDiscussion.user}</strong>
                          <p>
                            on {getCreatedAt(selectedDiscussion.created_at)}
                          </p>
                        </div>
                        <div className="userMenu">
                          <span
                            className="bi bi-share-fill"
                            onClick={showLoginDialog}
                          >
                            <span>Share</span>
                          </span>
                        </div>
                      </>

                      <div className="contentArea">
                        <p>{selectedDiscussion.description}</p>
                      </div>
                      <div className="addComment">
                        <DiscussionCommentAdd
                          discussionId={selectedDiscussion.id}
                          handleComment={showLoginDialog}
                          addComment={() => {
                            dispatch(openModal("loginView"));
                            dispatch(openLoginForm());
                          }}
                        />
                        {selectedDiscussion.comments &&
                          selectedDiscussion.comments.map((item, id) => (
                            <DiscussionSingleCommentItem
                              comment={item}
                              key={id}
                            />
                          ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      )}
    </Container>
  );
};

export default ModuleSingleDiscussion;
