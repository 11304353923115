import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { progress_score } from "./ProgressApi";
const initialState = {
  isLoading: false,
  progressScore: {},
};

export const progressScore = createAsyncThunk("progress/progressScore", async (token) => {
  try {
    return await progress_score(token);
  } catch (error) {
    return error;
  }
});

const ProgressSlice = createSlice({
  name: "Progress",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(progressScore.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(progressScore.fulfilled, (state, action) => {
        state.isLoading = false;
        state.progressScore = action.payload.data.data;
      })
      .addCase(progressScore.rejected, (state, action) => {
        state.isLoading = false;
      });
  },
});
export default ProgressSlice.reducer;
