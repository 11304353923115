import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as PageBannerSVG } from "../../Assets/img/big-lkwb.svg";
import { servicesInfo } from "../../Features/HomeApi/HomeSlice";
import EachServiceCard from "../Components/services/eachservicecard";
const ServicesPage = () => {
  const dispatch = useDispatch();
  let services_Info = useSelector((state) => state.home.servicesInfo);
  useEffect(() => {
    dispatch(servicesInfo());
  }, [dispatch]);
  return (
    <>
      <div className="pageBanner">
        <PageBannerSVG />
        <Container>
          <div className="page-wrap">
            <h3>Our Services</h3>
            <span>
              Our mission at Learn Korean with Bibek is to provide high-quality,
              comprehensive Korean language instruction to individuals of all
              skill levels, from beginner to advanced.
            </span>
          </div>
          <div class="custom-shape-divider-bottom-1678688493">
            <svg
              data-name="Layer 1"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 1200 120"
              preserveAspectRatio="none"
            >
              <path
                d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z"
                class="shape-fill"
              ></path>
            </svg>
          </div>
        </Container>
      </div>
      {services_Info && services_Info.services && (
        <section className="services-section-page section">
          <Container>
            <Row className="servicecard-container justify-content-center">
              {services_Info.services.map((item, id) => (
                <Col className="eachservice-wrapper" xs={12} md={4} key={id}>
                  <EachServiceCard {...item} />
                </Col>
              ))}
            </Row>
          </Container>
        </section>
      )}
    </>
  );
};

export default ServicesPage;
