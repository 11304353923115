import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import ShareItem from "./ShareItem";
import { CopyTextToClipBoard } from "../../../Constants";

const ShareModal = ({ show, handleClose, shareTo }) => {
  const [copied, setCopied] = useState(false);
  const copyTextFn = (e) => {
    setCopied(true);
    CopyTextToClipBoard(
      e,
      window.location.href.replace("/dashboard", "") + (shareTo ? shareTo : "")
    );
  };
  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Share This On:</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="showShareButtons">
          <ShareItem />
          <div className="copyLink">
            Or Copy Link:
            <input
              type="text"
              className={` ${copied ? "copied" : "notCopied"}`}
              defaultValue={
                window.location.href.replace("/dashboard", "") +
                (shareTo ? shareTo : "")
              }
              disabled
            />
            <i
              className={` ${
                copied
                  ? "copied bi bi-clipboard2-check"
                  : "notCopied bi bi-clipboard"
              }`}
              onClick={(e) => {
                copyTextFn(e);
              }}
            >
              &nbsp; {copied ? "Copied to clipboard" : "Copy to clipboard"}
            </i>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ShareModal;
