import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import {
  allPackage,
  allSets,
  reClaimFn,
} from "../../../Features/SetsAPI/SetsSlice";
import { useDispatch, useSelector } from "react-redux";
const ReclaimModal = ({ reclaim, closeReclaim }) => {
  const dispatch = useDispatch();
  let token = useSelector((state) => state.auth.currentUser.token);
  const [hanldeID, setHandleIDChange] = useState("");
  const [gatewayVal, setGatewayVal] = useState("esewa");
  const handleTransactionID = (e) => {
    setHandleIDChange(e.target.value);
  };
  const handleGatewayChange = (e) => {
    setGatewayVal(e.target.value);
  };
  const submitTransaction = () => {
    dispatch(reClaimFn({ token, tid: hanldeID, gateway: gatewayVal })).then(
      (res) => {
        if(res.payload.status === 200){
            dispatch(allSets(token));
            dispatch(allPackage(token));
            closeReclaim();
        }
      }
    );
  };
  return (
    <Modal
      centered
      className="transactionStatusContainer"
      show={reclaim}
      onHide={closeReclaim}
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <p>
          If your transaction has been completed but the set has not opened.
          Then insert your product ID from the payment statement of your
          respective gateway in the box below. Then send it to us.
        </p>
        <label>
          Gateway:
          <select onChange={handleGatewayChange}>
            <option value="esewa" defaultValue>
              Esewa
            </option>
            <option value="khalti">Khalti</option>
          </select>
        </label>
        <input
          type="text"
          placeholder="x-xx-xxxx-xxxxxxxxx"
          onChange={handleTransactionID}
        />
        <button className="btn btn-green mt-3" onClick={submitTransaction}>
          Submit
        </button>
      </Modal.Body>
    </Modal>
  );
};

export default ReclaimModal;
