import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import {
  create_Discussion,
  get_Discussions,
  single_Discussion,
  delete_discussion,
  comment_Discussion,
  update_comment,
  delete_comment,
  reply_comment,
  update_reply,
  update_discussion,
  delete_reply,
} from "./DiscussionsApi";
import { blog_single, comment_on_blog } from "../HomeApi/HomeApi";
const initialState = {
  isLoading: false,
  selectedDiscussion: {},
  getDiscussions: [],
  searchDiscussion: [],
  errorHandle: [],
  singleBlogContent: {},
};

export const singleHomeBlog = createAsyncThunk(
  "home/singleBlog",
  async (data) => {
    try {
      return await blog_single(data);
    } catch (error) {
      return error;
    }
  }
);

export const commentOnBlog = createAsyncThunk(
  "home/commentOnBlog",
  async (data) => {
    try {
      return await comment_on_blog(data);
    } catch (error) {
      return error;
    }
  }
);

export const createDiscussion = createAsyncThunk(
  "discussion/createDiscussion",
  async (data, { rejectWithValue }) => {
    try {
      return await create_Discussion(data);
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err);
    }
  }
);
export const singleDiscussion = createAsyncThunk(
  "discussion/singleDiscussion",
  async (data, { rejectWithValue }) => {
    try {
      return await single_Discussion(data);
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err);
    }
  }
);
export const getDiscussions = createAsyncThunk(
  "discussion/getDiscussions",
  async (data, { rejectWithValue }) => {
    try {
      return await get_Discussions(data);
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err);
    }
  }
);
export const deleteDiscussion = createAsyncThunk(
  "discussion/deleteDiscussion",
  async (data, { rejectWithValue }) => {
    try {
      return await delete_discussion(data);
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err);
    }
  }
);
export const updateDiscussion = createAsyncThunk(
  "discussion/updateDiscussion",
  async (data, { rejectWithValue }) => {
    try {
      return await update_discussion(data);
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err);
    }
  }
);
export const commentOnDiscussion = createAsyncThunk(
  "discussion/commentOnDiscussion",
  async (data, { rejectWithValue }) => {
    try {
      return await comment_Discussion(data);
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err);
    }
  }
);
export const updateComment = createAsyncThunk(
  "discussion/updateComment",
  async (data, { rejectWithValue }) => {
    try {
      return await update_comment(data);
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err);
    }
  }
);
export const deleteComment = createAsyncThunk(
  "discussion/deleteComment",
  async (data, { rejectWithValue }) => {
    try {
      return await delete_comment(data);
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err);
    }
  }
);
export const replyComment = createAsyncThunk(
  "discussion/replyComment",
  async (data, { rejectWithValue }) => {
    try {
      return await reply_comment(data);
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err);
    }
  }
);
export const updateReply = createAsyncThunk(
  "discussion/updateReply",
  async (data, { rejectWithValue }) => {
    try {
      return await update_reply(data);
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err);
    }
  }
);
export const deleteReply = createAsyncThunk(
  "discussion/deleteReply",
  async (data, { rejectWithValue }) => {
    try {
      return await delete_reply(data);
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err);
    }
  }
);
const DiscussionSlice = createSlice({
  name: "Discussion",
  initialState,
  reducers: {
    deleteDiscussionWithId: (state, action) => {
      let index = state.getDiscussions.findIndex(
        (item) => action.payload === item.id
      );
      let newArr = [...state.getDiscussions];
      newArr.splice(index, 1);
      state.getDiscussions = newArr;
    },
    deleteCommentWithID: (state, action) => {
      if (Object.keys(state.selectedDiscussion).length != 0) {
        let index = state.selectedDiscussion.comments.findIndex(
          (item) => action.payload === item.id
        );
        let newArr = [...state.selectedDiscussion.comments];
        newArr.splice(index, 1);
        state.selectedDiscussion.comments = newArr;
      }
      if (Object.keys(state.singleBlogContent).length != 0) {
        let index = state.singleBlogContent.comments.findIndex(
          (item) => action.payload === item.id
        );
        let newArr = [...state.singleBlogContent.comments];
        newArr.splice(index, 1);
        state.singleBlogContent.comments = newArr;
      }
    },
    deleteRepliesWithID: (state, action) => {
      if (Object.keys(state.selectedDiscussion).length != 0) {
        let findComment = state.selectedDiscussion.comments.findIndex(
          (item) => item.id === parseInt(action.payload.comment_id)
        );
        let findReply = state.selectedDiscussion.comments[
          findComment
        ].replies.findIndex(
          (item) => item.id === parseInt(action.payload.reply_id)
        );
        state.selectedDiscussion.comments[findComment].replies.splice(
          findReply,
          1
        );
      }
      if (Object.keys(state.singleBlogContent).length != 0) {
        let findComment = state.singleBlogContent.comments.findIndex(
          (item) => item.id === parseInt(action.payload.comment_id)
        );
        let findReply = state.singleBlogContent.comments[
          findComment
        ].replies.findIndex(
          (item) => item.id === parseInt(action.payload.reply_id)
        );
        state.singleBlogContent.comments[findComment].replies.splice(
          findReply,
          1
        );
      }
    },
    clearSelectedDiscussions: (state) => {
      state.selectedDiscussion = {};
    },
    // sortByLatestDate: (state) => {
    //   state.getDiscussions = state.getDiscussions.sort((a, b) => {
    //     console.log(b.created_at)
    //     console.log(new Date(b.created_at) - new Date(a.created_at));
    //     return new Date(b.created_at) - new Date(a.created_at);
    //   });
    // },
    // sortByOldestDate: (state) => {
    //   state.getDiscussions = state.getDiscussions.sort((a, b) => {
    //     return new Date(a.created_at) - new Date(b.created_at);
    //   });
    // },
    // sortByHigherComment: (state) => {
    //   state.getDiscussions = state.getDiscussions.sort((a, b) => {
    //     return b.total_comments - a.total_comments;
    //   });
    // },
    // sortByLowerComment: (state) => {
    //   state.getDiscussions = state.getDiscussions.sort((a, b) => {
    //     return a.total_comments - b.total_comments;
    //   });
    // },
    // searchDiscussion: (state, action) => {
    //   const newLowerValue = action.payload;
    //   let newArr = [];
    //   for (let i = 0; i < state.getDiscussions.length; i++) {
    //     if (
    //       state.getDiscussions[i].title.toLowerCase().indexOf(newLowerValue) !==
    //       -1
    //     ) {
    //       newArr.push(state.getDiscussions[i]);
    //     }
    //   }
    //   state.searchDiscussionCollection = newArr;
    // },
  },
  extraReducers: (builder) => {
    builder
      .addCase(singleHomeBlog.pending, (state, action) => {
        state.isLoading = true;
        state.singleBlogContent = {};
        state.selectedDiscussion = {};
      })
      .addCase(singleHomeBlog.fulfilled, (state, action) => {
        state.isLoading = false;
        state.singleBlogContent = action.payload.data.data;
        state.selectedDiscussion = {};
      })
      .addCase(singleHomeBlog.rejected, (state, action) => {
        state.isLoading = false;
        state.singleBlogContent = {};
        state.selectedDiscussion = {};
      })
      .addCase(commentOnBlog.fulfilled, (state, action) => {
        state.singleBlogContent.comments = [
          action.payload.data.data,
          ...state.singleBlogContent.comments,
        ];
        toast.success("Comment");
      })
      .addCase(getDiscussions.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getDiscussions.fulfilled, (state, action) => {
        state.isLoading = false;
        state.getDiscussions = action.payload.data.data;
        state.selectedDiscussion = {};
      })
      .addCase(getDiscussions.rejected, (state, action) => {
        state.isLoading = false;
        state.selectedDiscussion = {};
      })
      .addCase(createDiscussion.fulfilled, (state, action) => {
        toast.success("Discussion has been added successfully");
        state.getDiscussions = [
          action.payload.data.data,
          ...state.getDiscussions,
        ];
      })
      .addCase(createDiscussion.rejected, (state, action) => {
        if (typeof action.payload.response.data.message === "object") {
          for (const [key, value] of Object.entries(
            action.payload.response.data.message
          )) {
            state.errorHandle.push({ key, value });
          }
          state.errorHandle.forEach((item) => {
            toast.error(item.value[0]);
          });
        }
        if (typeof action.payload.response.data.message === "string") {
          state.errorHandle.push(action.payload.response.data.message);
          state.errorHandle.forEach((item) => {
            toast.error(item);
          });
        }
      })
      .addCase(deleteDiscussion.fulfilled, (state, action) => {
        toast.success("Discussion has been successfully deleted");
      })
      .addCase(singleDiscussion.pending, (state, action) => {
        state.selectedDiscussion = "";
        state.singleBlogContent = {};
      })
      .addCase(singleDiscussion.fulfilled, (state, action) => {
        state.selectedDiscussion = action.payload.data.data;
        state.singleBlogContent = {};
      })
      .addCase(singleDiscussion.rejected, (state) => {
        state.selectedDiscussion = "";
        state.singleBlogContent = {};
      })
      .addCase(commentOnDiscussion.fulfilled, (state, action) => {
        let newComment = action.payload.data.data;
        let findDiscussion = state.getDiscussions.findIndex(
          (item) => parseInt(newComment.commentable_id) === item.id
        );
        if (Object.keys(state.selectedDiscussion).length != 0) {
          state.selectedDiscussion.comments = [
            newComment,
            ...state.selectedDiscussion.comments,
          ];
        } else {
          let newEl = state.getDiscussions[findDiscussion];
          newEl.comments = { ...newComment };
          state.getDiscussions.splice(findDiscussion, 1, newEl);
        }
        toast.success("Comment Added Successfully.");
      })
      .addCase(updateComment.fulfilled, (state, action) => {
        let newComment = action.payload.data.data;
        if (Object.keys(state.selectedDiscussion).length != 0) {
          let findComment = state.selectedDiscussion.comments.findIndex(
            (item) => parseInt(newComment.id) === item.id
          );
          state.selectedDiscussion.comments[findComment].body = newComment.body;
        }
        if (Object.keys(state.singleBlogContent).length != 0) {
          let findComment = state.singleBlogContent.comments.findIndex(
            (item) => parseInt(newComment.id) === item.id
          );
          state.singleBlogContent.comments[findComment].body = newComment.body;
        }
        toast.success("Comment Updated Successfully.");
      })
      .addCase(deleteComment.fulfilled, () => {
        toast.success("Comment deleted Successfully");
      })
      .addCase(updateReply.fulfilled, (state, action) => {
        let newReply = action.payload.data.data;
        if (Object.keys(state.selectedDiscussion).length != 0) {
          let findComment = state.selectedDiscussion.comments.findIndex(
            (item) => parseInt(newReply.comment_id) === item.id
          );
          if (state.selectedDiscussion.comments[findComment].replies) {
            let index = state.selectedDiscussion.comments[
              findComment
            ].replies.findIndex((item) => item.id === parseInt(newReply.id));
            state.selectedDiscussion.comments[findComment].replies[index].body =
              newReply.body;
          }
        }
        if (Object.keys(state.singleBlogContent).length != 0) {
          let findComment = state.singleBlogContent.comments.findIndex(
            (item) => parseInt(newReply.comment_id) === item.id
          );
          if (state.singleBlogContent.comments[findComment].replies) {
            let index = state.singleBlogContent.comments[
              findComment
            ].replies.findIndex((item) => item.id === parseInt(newReply.id));
            state.singleBlogContent.comments[findComment].replies[index].body =
              newReply.body;
          }
        }
        toast.success("Reply updated Successfully");
      })
      .addCase(deleteReply.fulfilled, () => {
        toast.success("Reply Deleted Successfully");
      })
      .addCase(replyComment.fulfilled, (state, action) => {
        let newReply = action.payload.data.data;

        if (Object.keys(state.selectedDiscussion).length != 0) {
          let findComment = state.selectedDiscussion.comments.findIndex(
            (item) => parseInt(newReply.comment_id) === item.id
          );
          if (!state.selectedDiscussion.comments[findComment].replies) {
            state.selectedDiscussion.comments[findComment].replies = [newReply];
          } else {
            state.selectedDiscussion.comments[findComment].replies = [
              newReply,
              ...state.selectedDiscussion.comments[findComment].replies,
            ];
          }
        }
        if (Object.keys(state.singleBlogContent).length != 0) {
          let findComment = state.singleBlogContent.comments.findIndex(
            (item) => parseInt(newReply.comment_id) === item.id
          );
          if (!state.singleBlogContent.comments[findComment].replies) {
            state.singleBlogContent.comments[findComment].replies = [newReply];
          } else {
            state.singleBlogContent.comments[findComment].replies = [
              newReply,
              ...state.singleBlogContent.comments[findComment].replies,
            ];
          }
        }
        toast.success("Relpy Added Succesfully");
      })
      .addCase(updateDiscussion.fulfilled, (state, action) => {
        let newDiscussion = action.payload.data.data;
        let findDiscussion = state.getDiscussions.findIndex(
          (item) => parseInt(newDiscussion.id) === item.id
        );
        if (Object.keys(state.selectedDiscussion).length != 0) {
          state.selectedDiscussion.description = newDiscussion.description;
          state.selectedDiscussion.title = newDiscussion.title;
        } else {
          let newEl = state.getDiscussions[findDiscussion];
          newEl.title = newDiscussion.title;
          newEl.description = newDiscussion.description;
          state.getDiscussions.splice(findDiscussion, 1, newEl);
        }
        toast.success("Discussion Updated Successfully");
      })
      .addCase(updateDiscussion.rejected, (state, action) => {
        if (typeof action.payload.response.data.message === "object") {
          for (const [key, value] of Object.entries(
            action.payload.response.data.message
          )) {
            state.errorHandle.push({ key, value });
          }
          state.errorHandle.forEach((item) => {
            toast.error(item.value[0]);
          });
        }
        if (typeof action.payload.response.data.message === "string") {
          state.errorHandle.push(action.payload.response.data.message);
          state.errorHandle.forEach((item) => {
            toast.error(item);
          });
        }
      });
  },
});
export const {
  sortByLatestDate,
  sortByHigherComment,
  sortByLowerComment,
  sortByOldestDate,
  searchDiscussion,
  deleteDiscussionWithId,
  clearSelectedDiscussions,
  deleteCommentWithID,
  deleteRepliesWithID,
} = DiscussionSlice.actions;
export default DiscussionSlice.reducer;
