import React, { useState } from "react";
import logo from "../../../Assets/img/logo-bibek.png";
import { Container } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import SideMenu from "./sidebarNav";
import { useDispatch, useSelector } from "react-redux";
import {
  openModal,
  openLoginForm,
  openRegisterForm,
} from "../../../Features/ViewSlice";
import Login from "../Login/login";
import Register from "../Register/Register";
import { AllRoutes } from "../../../AllRoutes";
import { useRef } from "react";
import { loadingEnd, logoutFunc, signOut } from "../../../Features/AuthSlice";
import { endTest } from "../../../Features/SetsAPI/LocalSetSlice";
import { clearAllValue } from "../../../Features/SetsAPI/LocalSetSlice";
import { clearSession } from "../../../Features/SetsAPI/SetsSlice";
import { emptyProfile } from "../../../Features/Profile_management/profileSlice";
import { Modal } from "react-bootstrap";
export default function Navbar() {
  const dispatch = useDispatch();
  const currentLocation = useLocation().pathname;
  const [showModal, setShowModal] = useState({
    onlineclass: false,
    registration: false,
  });
  const handleShowModal = (label) => {
    setShowModal({ ...showModal, [label]: true });
  };
  const handleCloseModal = (label) => {
    setShowModal({ ...showModal, [label]: false });
  };

  let token = useSelector((state) => state.auth?.currentUser?.token);
  let deviceToken = useSelector((state) => state.auth?.deviceToken);
  const checkActiveLink = (linkId) => {
    if (currentLocation === linkId) {
      return "active";
    } else {
      return "inactive";
    }
  };
  let isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const loginModel = () => {
    dispatch(openModal("loginView"));
    dispatch(openLoginForm());
    dispatch(loadingEnd());
  };
  const registerModel = () => {
    dispatch(openModal("registerView"));
    dispatch(openRegisterForm());
  };

  let navbar = useRef(null);
  if (navbar) {
    window.addEventListener("scroll", () => {
      if (window.location.pathname.includes("/dashboard") === false) {
        const windowPos = window.innerHeight / 1.5;
        // console.log(windowPos);
        // let offset =
        //   navbar.current.getBoundingClientRect().top +
        //   navbar.current.getBoundingClientRect().height;
        if (window.pageYOffset > windowPos) {
          navbar.current.classList.add("sticky-top");
        } else {
          navbar.current.classList.remove("sticky-top");
        }
      }
    });
  }

  const getheaderstatus = () => {
    if (
      currentLocation.endsWith("#") ||
      (currentLocation.split("/").length <= 2 && currentLocation.endsWith("/"))
    ) {
      return "absolute";
    } else {
      return "relative";
    }
  };
  const logout = () => {
    dispatch(signOut({ token, deviceToken }));
    dispatch(emptyProfile());
    dispatch(clearAllValue());
    dispatch(endTest());
    dispatch(clearSession());
    dispatch(logoutFunc());
  };
  return (
    <>
      <header ref={navbar} className={`site__header ${getheaderstatus()}`}>
        <Container fluid="lg">
          <div className="d-flex justify-content-between align-items-center flex-wrap">
            <div>
              <div className="logo">
                <Link to="/">
                  <img src={logo} alt="Learn Korean With Bibek" />
                </Link>
              </div>
            </div>
            <div>
              <nav className="navbar">
                <ul>
                  {AllRoutes.homeRoute.map((item, id) => {
                    if (item.inNavBar) {
                      return (
                        <li
                          key={id}
                          className={`${
                            item.submenu ? "hovermenu-container" : ""
                          }`}
                        >
                          <Link
                            to={item.link}
                            id={item.label}
                            className={`${checkActiveLink(item.link)}`}
                          >
                            {item.label}
                          </Link>
                          {item.submenu && (
                            <ul className="hovermenu">
                              {item.submenu.map((subitem, id) => (
                                <li key={id}>
                                  {subitem.type === "external" ? (
                                    <a
                                      target="_blank"
                                      href={subitem.link}
                                      id={subitem.label}
                                      className={`${checkActiveLink(
                                        subitem.link
                                      )}`}
                                    >
                                      {subitem.label}
                                    </a>
                                  ) : (
                                    <>
                                      {subitem.type === "model" ? (
                                        <>
                                          <a
                                            id={subitem.label}
                                            onClick={() =>
                                              handleShowModal(subitem.label)
                                            }
                                          >
                                            {subitem.label}
                                          </a>
                                          <Modal
                                            show={showModal[subitem.label]}
                                            onHide={() =>
                                              handleCloseModal(subitem.label)
                                            }
                                          >
                                            <Modal.Body>
                                              <p>
                                                To get the {subitem.label} of
                                                the class, please fill up this
                                                form
                                              </p>
                                              <a
                                                href={subitem.link}
                                                target="_blank"
                                                className="btn btn-small btn-green"
                                              >
                                                Register
                                              </a>
                                            </Modal.Body>
                                          </Modal>
                                        </>
                                      ) : (
                                        <Link
                                          to={subitem.link}
                                          id={subitem.label}
                                          className={`${checkActiveLink(
                                            subitem.link
                                          )}`}
                                        >
                                          {subitem.label}
                                        </Link>
                                      )}
                                    </>
                                  )}
                                </li>
                              ))}
                              <li><a href="https://lkwb.com.np/blog/">LKWB Blogs</a></li>
                            </ul>
                          )}
                        </li>
                      );
                    }
                  })}

                  <li><a href="https://lkwb.com.np/blog/">Blogs</a></li>
                </ul>
              </nav>
            </div>
            <div className="cta--btns">
              {isLoggedIn ? (
                <>
                  <Link to="/dashboard" className="btn">
                    Dashboard
                  </Link>
                  <button onClick={logout} className="btn btn-secondary">
                    Log Out
                  </button>
                </>
              ) : (
                <>
                  <button onClick={loginModel} className="btn ">
                    Sign In
                  </button>
                  <button className="btn btn-secondary" onClick={registerModel}>
                    Sign Up
                  </button>
                </>
              )}
            </div>
            <div className="burger--menu">
              <SideMenu
                showModal={showModal}
                handleCloseModal={(label) => {
                  handleCloseModal(label);
                }}
                handleShowModal={(label) => {
                  handleShowModal(label);
                }}
              />
            </div>
          </div>
        </Container>
      </header>
      <Login />
      <Register />
    </>
  );
}
