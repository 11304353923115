import { BASE_URL } from "../../Constants";
import axios from "axios";

export const all_dictionary = async (data) => {
  let url = `${BASE_URL}/api/dictionaries?page=${data.page}`;
  let headers = {
    authorization: `Bearer ${data.token}`,
  };
  const res = await axios.get(url, {
    headers: headers,
  });
  return res;
};

export const dictionary_collection = async () => {
  let url = `${BASE_URL}/api/home/dictionaries`;
  const res = await axios.get(url);
  return res;
};

export const single_dictionary = async (data) => {
  let res;
  if (data.token) {
    let url = `${BASE_URL}/api/dictionaries/${data.id}`;
    let headers = {
      authorization: `Bearer ${data.token}`,
    };
    res = await axios.get(url, {
      headers: headers,
    });
  } else {
    let url = `${BASE_URL}/api/home/dictionaries/${data.id}`;
    res = await axios.get(url);
  }
  return res;
};
