import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import AboutImg1 from "../../Assets/img/learn_korean.jpg";
import AboutImg2 from "../../Assets/img/about_img2.png";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { aboutusInfo } from "../../Features/HomeApi/HomeSlice";
import HTMLParser from "../Components/HTMLParser";
import CustomAd from "../Components/CustomAd/CustomAd";
const About = () => {
  const dispatch = useDispatch();
  let aboutus_Info = useSelector((state) => state.home.aboutusInfo);
  let ad = useSelector(
    (state) =>
      state.ad.allAdPositions["banner-aboutus-section-homepage-1920-x-100"]
  );
  useEffect(() => {
    dispatch(aboutusInfo());
  }, [dispatch]);
  return (
    <>
      {aboutus_Info && (
        <section className="section about__section">
          <Container>
            <Row className="align-items-center about__wrapper">
              <Col md={6}>
                <div className="about-banner">
                  <img
                    className="img img-1"
                    src={aboutus_Info.main_image}
                    alt=""
                  />
                  <img
                    className="img img-2"
                    src={aboutus_Info.front_image}
                    alt=""
                  />
                </div>
              </Col>
              <Col md={6}>
                <div className="about--content">
                  <div className="section-head">
                    <span>{aboutus_Info.top_text}</span>
                    <h2>{aboutus_Info.header_text}</h2>
                  </div>
                  <p>
                    <HTMLParser>{aboutus_Info.description}</HTMLParser>
                  </p>
                  <Link to="/aboutus" className="btn">
                    {aboutus_Info.cta_text}
                  </Link>
                </div>
              </Col>
            </Row>
            {ad.map((item, id) => (
              <CustomAd key={id} item={item} />
            ))}
          </Container>
        </section>
      )}
    </>
  );
};

export default About;
