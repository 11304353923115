import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  get_grammar,
  grammar_category,
  single_grammar,
} from "./GrammarApi";
const initialState = {
  isLoading: false,
  isLoadingSingle: false,
  getGrammar: [],
  grammarCategory: [],
  isSingleSelected: false,
  singleGrammar: {},
  errorhandle: {},
};

export const getGrammar = createAsyncThunk(
  "grammar/getGrammar",
  async (token) => {
    try {
      return await get_grammar(token);
    } catch (error) {
      return error;
    }
  }
);

export const grammarCategory = createAsyncThunk(
  "grammar/grammarCategory",
  async (data) => {
    try {
      return await grammar_category(data);
    } catch (error) {
      return error;
    }
  }
);

export const singleGrammar = createAsyncThunk(
  "grammar/singleGrammar",
  async (data) => {
    try {
      return await single_grammar(data);
    } catch (error) {
      return error;
    }
  }
);
const GrammarSlice = createSlice({
  name: "grammar",
  initialState,
  reducers: {
    selectGrammar: (state) => {
      state.isSingleSelected = true;
    },
    deselectGrammar: (state) => {
      state.isSingleSelected = false;
      state.singleGrammar = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getGrammar.pending, (state, action) => {
        state.getGrammar = [];
      })
      .addCase(getGrammar.fulfilled, (state, action) => {
        state.getGrammar = action.payload.data.data;
      })
      .addCase(getGrammar.rejected, (state, action) => {
        state.errorhandle = action.payload.data.message;
        state.getGrammar = [];
      })
      .addCase(grammarCategory.pending, (state, action) => {
        state.isLoading = true;
        state.grammarCategory = [];
      })
      .addCase(grammarCategory.fulfilled, (state, action) => {
        state.isLoading = false;
        state.grammarCategory = action.payload.data.data;
      })
      .addCase(grammarCategory.rejected, (state, action) => {
        state.grammarCategory = [];
        state.isLoading = false;
      })
      .addCase(singleGrammar.pending, (state, action) => {
        state.isLoadingSingle = true;
      })
      .addCase(singleGrammar.fulfilled, (state, action) => {
        state.isLoadingSingle = false;
        state.singleGrammar = action.payload.data.data;
      })
      .addCase(singleGrammar.rejected, (state, action) => {
        state.isLoadingSingle = false;
      })
      ;
  },
});
export const { selectGrammar, deselectGrammar } = GrammarSlice.actions;
export default GrammarSlice.reducer;
