import React, { useEffect } from "react";
import { blogInfo } from "../../Features/HomeApi/HomeSlice";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import MainBlog from "../Components/Blog/MainBlog";
import MiniBlog from "../Components/Blog/MiniBlog";
const LatestBlog = () => {
  const dispatch = useDispatch();
  let blog_Info = useSelector((state) => state.home.blogInfo);
  useEffect(() => {
    dispatch(blogInfo());
  }, [dispatch]);
  // console.log(blog_Info && blog_Info);
  return (
    <>
      {blog_Info && (
        <section className="blog-section section">
          <Container>
            <div className="section-head d-flex flex-wrap align-items-center justify-content-between">
              <h2>Read Blogs</h2>
              <a href={`https://lkwb.com.np/blog/`} className="btn"> See all Blog Posts </a>
              {/* <Link to="/blog/" className="btn">
              See all Blog Posts
              </Link> */}
            </div>
            <Row className="latestblog-container ">
              <Col xs={12} md={8}>
                <Row>
                  {blog_Info.slice(0, 2).map((item, id) => {
                    return (
                      <Col xs={12} md={6} key={id}>
                        <MainBlog blog={item} />
                      </Col>
                    );
                  })}
                </Row>
              </Col>
              <Col xs={12} md={4}>
                {blog_Info.slice(2, 6).map((item, id) => {
                  return <MiniBlog blog={item} key={id} />;
                })}
              </Col>
            </Row>
          </Container>
        </section>
      )}
    </>
  );
};

export default LatestBlog;
