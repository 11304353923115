import React from "react";
import { Row, Col, Form } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import {
  filterByDiscussion,
  filterBySearch,
} from "../../../../Features/NotificationApi/NotificationSlice";
import NotificationList from "../../Components/Notification/NotificationList";
const NotificationPage = () => {
  const dispatch = useDispatch();
  let allnotification = useSelector(
    (state) => state.notification.notifications
  );
  let filteredNotifications = useSelector(
    (state) => state.notification.filteredNotifications
  );
  let notification =
    filteredNotifications.length > 0 ? filteredNotifications : allnotification;
  const handleSortChange = (e) => {
    dispatch(filterByDiscussion(e.target.value));
  };
  return (
    <div>
      <div className="notification-header">
        <Form>
          <Form.Group className="d-flex justify-content-between align-items-center notificationFormGroup">
            <Form.Select
              aria-label="Default select example"
              onChange={handleSortChange}
            >
              <option value="all-notificatoin">All Notification</option>
              <option value="Comment">Comment</option>
              <option value="Reply">Reply</option>
              <option value="Referral">Referral Code</option>
            </Form.Select>
          </Form.Group>
        </Form>
      </div>
      <Row>
        <Col md={8}>
          <ul className="notificationwrapper">
            {notification.map((item, id) => (
              <NotificationList item={item} key={id} />
            ))}
          </ul>
        </Col>
        <Col md={4}>
          <div className="notificationlabels">
            <h3 className="dashboard-page-title">Notification Labels</h3>
            <ul>
              <li>
                <span className="read"></span> Read Notification
              </li>
              <li>
                <span className="unread"></span> UnRead Notification
              </li>
            </ul>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default NotificationPage;
