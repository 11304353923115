import React from "react";
import { Container } from "react-bootstrap";
import { ReactComponent as PageBannerSVG } from "../../Assets/img/big-lkwb.svg";
import HTMLParser from "./HTMLParser";

const PageHeadings = ({ item, hasIcon }) => {
  return (
    <div className="pageBanner">
      <PageBannerSVG />
      <Container>
        <div className={`page-wrap ${hasIcon && "services-wrap"}`}>
          {hasIcon ? (
            <>
              <div className="img">
                <img src={item.icon} alt="" />
              </div>
              <div className="services-text">
                <h3>{item.title}</h3>
                <span>{item.excerpt}</span>
              </div>
            </>
          ) : (
            <div className="page-wrap">
              <h3>{item.title}</h3>
              {item.excerpt && (
                <p>
                  <HTMLParser>{item.excerpt}</HTMLParser>
                </p>
              )}
            </div>
          )}
        </div>
      </Container>
      <div class="custom-shape-divider-bottom-1678688493">
        <svg
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1200 120"
          preserveAspectRatio="none"
        >
          <path
            d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z"
            class="shape-fill"
          ></path>
        </svg>
      </div>
    </div>
  );
};
export default PageHeadings;
