import React from "react";
import BlogDate from "./BlogDate";
import { Link } from "react-router-dom";
const MainBlog = (props) => {
  let navigateTo = `${
    props.blogFrom === "dashboard"
      ? `/dashboard/blog/${props.blog.id}`
      : `/blog/${props.blog.slug}`
  }`;
  return (
    <div
      className={`mainblog-container  ${
        props.type === "twocolumn" ? "twocolumn" : "single"
      } blog-container`}
    >

<a href={`https://lkwb.com.np/blog/${props.blog.slug}`}>
<img src={props.blog.image} alt={props.blog.title} />
        <span>{props.blog.category}</span>
      </a>
      
      <div className="mainblog-content">
        <h3>
        <a href={`https://lkwb.com.np/blog/${props.blog.slug}`}> {props.blog.title} </a>
         
        </h3>
        <div className="mainblog-meta">
          <BlogDate blogdate={props.blog.published_at} />
          <div className="divider"></div>
          <div className="viewers">
            <i className="bi bi-eye"></i>
            <span>{props.blog.view_count}</span>
          </div>
        </div>
        <p>{props.blog.excerpt ? props.blog.excerpt : null}</p>
        <a href={`https://lkwb.com.np/blog/${props.blog.slug}`} className="anchor-text">
          Read More
        </a>
      </div>
    </div>
  );
};

export default MainBlog;
