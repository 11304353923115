import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { get_favorites, add_favorites, remove_favorites } from "./FavoriteApi";
import { toast } from "react-toastify";
const initialState = {
  isLoading: false,
  getFavorites: [],
};

export const getFavorites = createAsyncThunk(
  "favorite/getFavorites",
  async (token) => {
    try {
      return await get_favorites(token);
    } catch (error) {
      return error;
    }
  }
);

export const setFavorites = createAsyncThunk(
  "favorite/setFavorites",
  async (data) => {
    try {
      return await add_favorites(data);
    } catch (err) {
      return err;
    }
  }
);
export const removeFavorites = createAsyncThunk(
  "favorite/removeFavorites",
  async (data) => {
    try {
      return await remove_favorites(data);
    } catch (err) {
      return err;
    }
  }
);
const FavSlice = createSlice({
  name: "favorite",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getFavorites.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getFavorites.fulfilled, (state, action) => {
        state.isLoading = false;
        state.getFavorites = action.payload.data.data;
      })
      .addCase(getFavorites.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(setFavorites.fulfilled, (state, action) => {
        state.isLoading = false;
        toast.success(action.payload.data.message);
      })
      .addCase(setFavorites.rejected, (state, action) => {
        state.isLoading = false;
        toast.success(action.payload.data.message);
      })
      .addCase(removeFavorites.fulfilled, (state, action) => {
        state.isLoading = false;
        toast.success(action.payload.data.message);
      });
  },
});
export default FavSlice.reducer;
