import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";
import { get_notification, update_notifcation } from "./NotificationAPI";

const initialState = {
  isLoading: false,
  notifications: [],
  filteredNotifications: [],
};
export const getNotification = createAsyncThunk(
  "notifaction/getNotification",
  async (data, { rejectWithValue }) => {
    try {
      return await get_notification(data);
    } catch (error) {
      if (!error.response) throw error;
      return rejectWithValue(error);
    }
  }
);

export const updateNotification = createAsyncThunk(
  "notifaction/updateNotification",
  async (data, { rejectWithValue }) => {
    try {
      return await update_notifcation(data);
    } catch (error) {
      if (!error.response) throw error;
      return rejectWithValue(error);
    }
  }
);

const NotificationSlice = createSlice({
  name: "notifaction",
  initialState,
  reducers: {
    filterByDiscussion: (state, action) => {
      state.filteredNotifications = state.notifications.filter(
        (item) => item.notification_type === action.payload
      );
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getNotification.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getNotification.fulfilled, (state, action) => {
        state.isLoading = false;
        state.notifications = action.payload.data.data;
      })
      .addCase(getNotification.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(updateNotification.fulfilled, (state, action) => {
        const updated = action.payload.data.data;
        let index = state.notifications.findIndex(
          (item) => item.id === updated.id
        );
        state.notifications[index] = updated;
      });
  },
});

export const { filterByDiscussion, filterBySearch } = NotificationSlice.actions;
export default NotificationSlice.reducer;
