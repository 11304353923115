import React, { useEffect } from "react";
import {
  HeroSection,
  About,
  Service,
  FAQ,
  Video,
  DownloadApp,
  LatestBlog,
} from "./routesContainer";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { displayRoadBlock, hideRoadBlock } from "../../Features/AdApi/AdSlice";
import CustomAd from "../Components/CustomAd/CustomAd";
function MainContainer() {
  const handleClose = () => {
    dispatch(hideRoadBlock());
  };
  let roadblockAd = useSelector((state) => state.ad.allAdPositions.roadblock);
  let displayRoadBlockAd = useSelector(
    (state) => state.ad.displayedRoadblockAd
  );
  let roadBlockStatus = useSelector((state) => state.ad.alreadyDisplayed);
  const dispatch = useDispatch();
  useEffect(() => {
    if (roadBlockStatus === false) {
      dispatch(displayRoadBlock());
    }
  }, [dispatch]);
  return (
    <main>
      {displayRoadBlockAd && roadblockAd && roadblockAd.length > 0 && (
        <>
          {roadblockAd.map((item, id) => (
            <Modal
              key={id}
              show={displayRoadBlockAd}
              centered
              onHide={handleClose}
              size="xl"
            >
              <Modal.Header closeButton></Modal.Header>
              <Modal.Body>
                <CustomAd item={item} />
                <a target="_blank" href={item.url} className="btn mt-2">
                  Register Now
                </a>
              </Modal.Body>
            </Modal>
          ))}
        </>
      )}
      <HeroSection />
      <About />
      <Video />
      <LatestBlog />
      <FAQ />
      <DownloadApp />
    </main>
  );
}

export default MainContainer;
