import React from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { openLoginForm, openModal } from "../../../Features/ViewSlice";
const DiscussionCommentAdd = ({
  addComment,
  replyStateHandle,
  handleComment,
  value,
}) => {
  const dispatch = useDispatch();
  let token = useSelector((state) => state.auth.currentUser.token);
  const haveFocus = () => {
    if (!token) {
      dispatch(openModal("loginView"));
      dispatch(openLoginForm());
    }
  };
  return (
    <div className="userComments">
      <div className="">
        {/* <div className="userImage">
          <img src={userImg} alt="" />
        </div> */}
        <div className="userComment">
          <textarea
            onFocus={haveFocus}
            name="comment"
            id=""
            cols="20"
            rows="5"
            placeholder="Add A Comment"
            onChange={handleComment}
            value={value}
          ></textarea>
          {/* <input type="text" placeholder="Add A Comment" onChange={handleComment} value={value} /> */}
        </div>
        <button
          className="btn btn-small"
          onClick={() => {
            if (replyStateHandle) {
              replyStateHandle();
            }
            addComment();
          }}
        >
          Add Comment
        </button>
      </div>
    </div>
  );
};

export default DiscussionCommentAdd;
