import { BASE_URL } from "../../Constants";
import axios from "axios";
import { async } from "q";

export const get_favorites = async (token) => {
  let url = `${BASE_URL}/api/favorites`;
  let headers = {
    authorization: `Bearer ${token}`,
  };
  const res = await axios.get(url, {
    headers: headers,
  });
  return res;
};

export const add_favorites = async (data) => {
  let url = `${BASE_URL}/api/add-to-favorites`;
  let headers = {
    authorization: `Bearer ${data.token}`,
  };
  const res = await axios.post(url, data.formdata, {
    headers: headers,
  });
  return res;
};
export const remove_favorites = async (data) => {
  let url = `${BASE_URL}/api/remove-from-favorites`;
  let headers = {
    authorization: `Bearer ${data.token}`,
  };
  const res = await axios.post(url, data.formdata, {
    headers: headers,
  });
  return res;
};
