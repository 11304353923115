import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { progressScore } from "../../../../Features/ProgressApi/ProgressSlice";
import {
  buildStyles,
  CircularProgressbarWithChildren,
} from "react-circular-progressbar";
import { Row, Col } from "react-bootstrap";
import ProgressProvider from "./ProgressProvider";
const ProgressBar = () => {
  const dispatch = useDispatch();
  let token = useSelector((state) => state.auth.currentUser.token);
  let progress_score = useSelector((state) => state.progress.progressScore);
  useEffect(() => {
    dispatch(progressScore(token));
  }, [dispatch]);

  const setScore = (score, cssproperty, divisibleBy = 40) => {
    document.documentElement.style.setProperty(
      `${cssproperty}`,
      (parseInt(score) / divisibleBy) * 100
    );
    return score;
  };
  return (
    <>
      {progress_score && (
        <div className="progressbar-container ">
          <p>UBT Last Test Summary</p>
          <Row className="dashboard-progress">
            <Col md={6} className="testscore correctanswer">
              <div className="prog">
                <ProgressProvider
                  valueStart={0}
                  valueEnd={progress_score.correct_answers}
                >
                  {(value) => (
                    <CircularProgressbarWithChildren
                      minValue={0}
                      strokeWidth={2}
                      styles={buildStyles({
                        trailColor: "#00437b",
                        pathColor: `#c7303c`,
                        textColor: "#f88",
                        trailColor: "#00437b1a",
                        backgroundColor: "#00437b1a",
                        textSize: "16px",
                        pathTransitionDuration: 1,
                      })}
                      maxValue={progress_score.number_of_questions}
                      value={value}
                    >
                      <div className="number test-percentage">
                        <p>
                          {setScore(
                            progress_score.correct_answers,
                            "--unsolvedquestion"
                          )}
                          /{progress_score.number_of_questions}
                        </p>
                        <span>Correct Answers</span>
                      </div>
                    </CircularProgressbarWithChildren>
                  )}
                </ProgressProvider>
              </div>
            </Col>
            <Col md={6} className="testscore scoreresult">
              <div className="prog">
                <ProgressProvider
                  valueStart={0}
                  valueEnd={progress_score.out_of_100}
                >
                  {(value) => (
                    <CircularProgressbarWithChildren
                      minValue={0}
                      strokeWidth={2}
                      styles={buildStyles({
                        textColor: "#f88",
                        pathColor: `#00437b`,
                        trailColor: "#00437b1a",
                        backgroundColor: "#00437b1a",
                        textSize: "16px",
                        pathTransitionDuration: 1,
                      })}
                      maxValue={100}
                      value={value}
                    >
                      <div className="number test-percentage">
                        <p>
                          {setScore(
                            progress_score.out_of_100,
                            "--unsolvedquestion"
                          )}
                          /100
                        </p>
                        <span>Total Score</span>
                      </div>
                    </CircularProgressbarWithChildren>
                  )}
                </ProgressProvider>
              </div>
            </Col>
          </Row>
        </div>
      )}
    </>
  );
};

export default ProgressBar;
