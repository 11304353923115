import React from "react";
import BlogDate from "./BlogDate";
import { Link } from "react-router-dom";
const MiniBlog = (props) => {
  let navigateTo = `${
    props.blogFrom === "dashboard"
      ? `/dashboard/blog/${props.blog.id}`
      : `/blog/${props.blog.slug}`
  }`;
  return (
    <div className="miniblog-container blog-container">
      <a href={`https://lkwb.com.np/blog/${props.blog.slug}`}>
      <img src={props.blog.image} alt={props.blog.title} />
      </a>
     
      <div className="miniblog-right">
        <BlogDate
          blogdate={props.blog.published_at}
          category={props.blog.category}
        />
       
        <a href={`https://lkwb.com.np/blog/${props.blog.slug}`}>  <h4>{props.blog.title} </h4> </a>
      </div>
    </div>
  );
};

export default MiniBlog;
