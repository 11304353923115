import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router";
import { Row, Col, Container } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { singleHomeDictionary } from "../../../Features/HomeApi/HomeSlice";
import {
  dictionaryCollection,
  singleDictionary,
} from "../../../Features/DictionaryApi/DictionarySlice";
import HTMLParser from "../HTMLParser";
import { openModal, openLoginForm } from "../../../Features/ViewSlice";
import { textToSpeech } from "../../../Constants";
import {
  setFavorites,
  removeFavorites,
} from "../../../Features/FavoriteApi/FavoriteSlice";
import ShareModal from "../Share/ShareModal";
import MetaHelmet from "../Helmet/MetaHelmet";
import { Link } from "react-router-dom";
import WordDay from "../../DashboardView/Components/WordDay";
import CustomAd from "../CustomAd/CustomAd";
import nepalFlag from "../../../Assets/img/nepal-flag.png";
import ukFlag from "../../../Assets/img/uk-usa-flag.png";
import koreaFlag from "../../../Assets/img/korea-flag.png";

const ModuleSingleDictionary = () => {
  const dispatch = useDispatch();
  let token = useSelector((state) => state.auth?.currentUser?.token);
  let ad = useSelector(
    (state) =>
      state.ad.allAdPositions["sidebar-dictionary-detail-homepage-306-x-250"]
  );
  let dicCollection = useSelector((state) => state.dictionary.dicCollection);
  const [single_dictionary, setSingleDictionary] = useState();
  const [isFavorite, setFavorite] = useState();
  let { id } = useParams();
  useEffect(() => {
    setSingleDictionary();
    if (!token) {
      dispatch(singleHomeDictionary({ id })).then((res) => {
        if (res.payload.status === 200) {
          setSingleDictionary(res.payload.data.data);
        }
      });
    } else {
      dispatch(singleDictionary({ token, id })).then((res) => {
        if (res.payload.status === 200) {
          setSingleDictionary(res.payload.data.data);
          setFavorite(res.payload.data.data.isFavorite);
        }
      });
    }
  }, [dispatch, id]);

  useEffect(() => {
    if (dicCollection.length === 0) {
      dispatch(dictionaryCollection());
    }
  }, [dispatch]);

  const makeFavorite = (e) => {
    e.preventDefault();
    const formdata = new FormData();
    formdata.append("favoritable_id", id);
    formdata.append("favoritable_type", "App\\Models\\Dictionary");
    dispatch(setFavorites({ formdata, token })).then((res) => {
      if (res.payload.status === 200) {
        setFavorite(true);
      }
    });
  };
  const removeFavorite = (e) => {
    e.preventDefault();
    const formdata = new FormData();
    formdata.append("favoritable_id", id);
    formdata.append("favoritable_type", "App\\Models\\Dictionary");
    dispatch(removeFavorites({ formdata, token })).then((res) => {
      if (res.payload.status === 200) {
        setFavorite(false);
      }
    });
  };
  const loginModel = () => {
    if (!token) {
      dispatch(openModal("loginView"));
      dispatch(openLoginForm());
    }
  };
  const textForSpeech = useRef(null);
  const textSpeechHandler = (e) => {
    e.preventDefault();
    if (textForSpeech != null) {
      textToSpeech(textForSpeech.current.textContent);
    }
  };

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      {single_dictionary && (
        <>
          <MetaHelmet
            data={single_dictionary}
            shareTo={`/dictionarydetail/${singleDictionary.id}`}
          />
          <Container>
            <div className="home__dictionary">
              <Link to="/dictionary" className="viewallbtn">
                View all Dictionary
              </Link>
              <div className="grammar-container singleselected">
                <Row>
                  <Col md={8} className="grammar--details">
                    <div className="header-wrap">
                      <div className="d-flex align-items-center justify-content-between">
                        <h2
                          className="dashboard-page-title my-0"
                          ref={textForSpeech}
                        >
                          {single_dictionary.word}
                          <a
                            aria-label="volume up"
                            href="/#"
                            onClick={textSpeechHandler}
                          >
                            <i className="bi bi-volume-up"></i>
                          </a>
                        </h2>
                        <div className="grammar--share">
                          <i className="bi bi-share" onClick={handleShow}></i>
                          <ShareModal
                            shareTo={`/dictionarydetail/${singleDictionary.id}`}
                            show={show}
                            handleClose={handleClose}
                          />
                          {token ? (
                            <>
                              {isFavorite ? (
                                <a
                                  aria-label="heart filled"
                                  href="/#"
                                  onClick={removeFavorite}
                                >
                                  <i className="bi bi-heart-fill"></i>
                                </a>
                              ) : (
                                <a
                                  aria-label="heart shape"
                                  href="/#"
                                  onClick={makeFavorite}
                                >
                                  <i className="bi bi-heart"></i>
                                </a>
                              )}
                            </>
                          ) : (
                            <i className="bi bi-heart" onClick={loginModel}></i>
                          )}
                        </div>
                      </div>
                    </div>
                    {single_dictionary.meaning && (
                      <div className="grammar__section">
                        <div className="grammarDetails">
                          <div>
                            <div className="d-flex">
                              <h4>
                                <img src={koreaFlag} alt="" />{" "}
                                <span>한국어:</span>
                              </h4>
                              <div>
                                {single_dictionary.meaning.kr_meaning ? (
                                  <HTMLParser>
                                    {single_dictionary.meaning.kr_meaning}
                                  </HTMLParser>
                                ) : (
                                  "N/A"
                                )}
                              </div>
                            </div>
                            <div className="d-flex">
                              <h4>
                                <img src={nepalFlag} alt="" />{" "}
                                <span>네팔어:</span>
                              </h4>
                              <div>
                                {single_dictionary.meaning.np_meaning ? (
                                  <HTMLParser>
                                    {single_dictionary.meaning.np_meaning}
                                  </HTMLParser>
                                ) : (
                                  "N/A"
                                )}
                              </div>
                            </div>
                            <div className="d-flex">
                              <h4>
                                <img src={ukFlag} alt="" /> <span>영어:</span>
                              </h4>
                              <div>
                                {single_dictionary.meaning.en_meaning ? (
                                  <HTMLParser>
                                    {single_dictionary.meaning.en_meaning}
                                  </HTMLParser>
                                ) : (
                                  "N/A"
                                )}
                              </div>
                            </div>
                            <div className="d-flex">
                              <h4>Examples:</h4>
                              <div>
                                {single_dictionary.examples ? (
                                  <HTMLParser>
                                    {single_dictionary.examples}
                                  </HTMLParser>
                                ) : (
                                  "N/A"
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </Col>
                  <Col md={4}>
                    <WordDay from="home" type="dictionary"></WordDay>
                    {ad.map((item, id) => (
                      <CustomAd item={item} key={id} />
                    ))}
                  </Col>
                </Row>
              </div>
            </div>
          </Container>
        </>
      )}
    </>
  );
};

export default ModuleSingleDictionary;
