import { BASE_URL } from "../../Constants";
import axios from "axios";

export const search_data = async (data) => {
  let url = `${BASE_URL}/api/search`;
  const formData = new FormData();
  formData.append("param", data.param);
  formData.append("model", data.model);
  return await axios.post(url, formData);
};
