import React, { useEffect } from "react";

const CustomAd = ({ item }) => {
  useEffect(() => {
    try {
      (window.adsbygoogle = window.adsbygoogle || []).push({});
    } catch (e) {}
  });
  return (
    <>
      {item.type === "adsense" && (
        <ins
          className="adsbygoogle"
          style={{ display: "block" }}
          data-ad-client="ca-pub-4698367235216604"
          data-ad-slot={item.dataadslot}
          data-ad-format="auto"
          data-full-width-responsive="true"
        ></ins>
      )}
      {item.type === "custom" && (
        <a href={item.url} target="_blank" className="ad-banner">
          <img src={item.ad_banner} />
        </a>
      )}
    </>
  );
};

export default CustomAd;
