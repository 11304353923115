import React from "react";
import { ReactComponent as PageBannerSVG } from "../../../Assets/img/lkwb.svg";

const EachServiceCard = (props) => {
  return (
    <div className="eachservicecard">
      <div className="eachservice-img">
        <img src={props.icon} alt={props.title} />
      </div>
      <h2>{props.title}</h2>
      <p>{props.excerpt}</p>
      <PageBannerSVG />
    </div>
  );
};

export default EachServiceCard;
