import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { login } from "../../../Features/AuthSlice";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { Container } from "react-bootstrap";
import CreateNewPassword from "./CreateNewPassword";
import ForgotPassword from "./ForgotPassword";
import PasswordSuccess from "./PasswordSuccess";
import { closeModal } from "../../../Features/ViewSlice";
import Modal from "../Modal/Modal";
import OTPVerification from "./OTPVerification";
import LoginForm from "./LoginForm";
const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let loginModal = useSelector((state) => state.view.modalState.loginView);
  let loginViews = useSelector((state) => state.view.loginInStates);
  const [isChecked, setIsChecked] = useState(false);
  const [logindetails, setlogindetails] = useState({
    email: "",
    password: "",
    rememberme: false,
  });
  const handleCheckbox = () => {
    setIsChecked(!isChecked);
  };
  const handleChange = (e) => {
    if (e.target.name === "checkbox") {
      setlogindetails({
        ...logindetails,
        [e.target.name]: e.target.checked,
      });
      return;
    }
    setlogindetails({
      ...logindetails,
      [e.target.name]: e.target.value,
    });
  };
  const handleLogin = async (e) => {
    e.preventDefault();
    const email = logindetails.email;
    const password = logindetails.password;
    dispatch(login({ email, password })).then((res) => {
      if (res.payload.status === 200) {
        dispatch(closeModal());
        navigate("/dashboard");
      }
    });
  };
  return (
    <>
      {loginModal && (
        <div className="loginFormContainer defaultInputs">
          <Container>
            <Modal size="lg">
              {loginViews.viewLoginForm && (
                <LoginForm
                  handleChange={handleChange}
                  handleSubmit={handleLogin}
                  handleCheckbox={handleCheckbox}
                />
              )}
              {loginViews.viewOTP && <OTPVerification />}
              {loginViews.viewForgotPassword && <ForgotPassword />}
              {loginViews.viewNewPassword && <CreateNewPassword />}
              {loginViews.viewPasswordSuccess && <PasswordSuccess />}
            </Modal>
          </Container>
        </div>
      )}
    </>
  );
};
export default Login;
