import React, { useEffect } from "react";
import { singleService } from "../../../Features/HomeApi/HomeSlice";
import { useDispatch, useSelector } from "react-redux";
import { Container } from "react-bootstrap";
import PageHeadings from "../PageHeadings";
import Grammer from "../../DashboardView/Container/GrammarPages/Grammer";
import Loader from "../Loader/Loader";
const ModuleGrammar = () => {
  const dispatch = useDispatch();
  let selectedService = useSelector(
    (state) => state.home.selectedSingleService
  );
  let isLoading = useSelector((state) => state.home.homeModuleLoading);
  useEffect(() => {
    dispatch(singleService({ id: 2 }));
  }, [dispatch]);
  return (
    <>
      {!isLoading ? (
        <>
          <PageHeadings item={selectedService} hasIcon="true" />
          <Container>
            <div className="home__dictionary">
              <Grammer />
            </div>
          </Container>
        </>
      ) : (
        <div className="loading__mode bigLoading">
          <Loader />
        </div>
      )}
    </>
  );
};

export default ModuleGrammar;
