import React, { useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getFavorites } from "../../../Features/FavoriteApi/FavoriteSlice";
import MainBlog from "../../Components/Blog/MainBlog";
import { useNavigate } from "react-router-dom";
import {
  selectGrammar,
  singleGrammar,
} from "../../../Features/GrammarApi/GrammarSlice";
import {
  selectDictionary,
  singleDictionary,
} from "../../../Features/DictionaryApi/DictionarySlice";
const Favorite = () => {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  let token = useSelector((state) => state.auth.currentUser.token);
  let allFavorites = useSelector((state) => state.favorite.getFavorites);
  useEffect(() => {
    dispatch(getFavorites(token));
  }, [dispatch]);
  const handleSelectGrammar = (id) => {
    navigate("/dashboard/grammar", {
      replace: true,
    });
    dispatch(selectGrammar());
    dispatch(singleGrammar({ token, id }));
  };
  const handleSelectDictionary = (id) => {
    navigate("/dashboard/dictionary", {
      replace: true,
    });
    dispatch(selectDictionary());
    dispatch(singleDictionary({ token, id }));
  };
  return (
    <div className="grammar-container favContent">
      <Row>
        {allFavorites.dictionaries && (
          <div className="grammar__section">
            <h2 className="dashboard-page-title">LKWB Dictionary</h2>
            {allFavorites.dictionaries.length > 0 ? (
              <div className="grammar--details">
                {allFavorites.dictionaries.map((item, id) => (
                  <div key={id}>
                    <h3 onClick={() => handleSelectDictionary(item.id)}>
                      {item.word}
                    </h3>
                    <p>{item.np_meaning}</p>
                  </div>
                ))}
              </div>
            ) : (
              <div className="youhavenot-container">
                <p>You have not selected any dictionary</p>
              </div>
            )}
          </div>
        )}
        {allFavorites.grammars && (
          <div className="grammar__section">
            <h2 className="dashboard-page-title">LKWB Grammar</h2>
            {allFavorites.grammars.length > 0 ? (
              <div className="grammar--details">
                {allFavorites.grammars.map((item, id) => (
                  <div key={id}>
                    <h3 onClick={() => handleSelectGrammar(item.id)}>
                      {item.word}
                    </h3>
                    <p>{item.np_meaning}</p>
                  </div>
                ))}
              </div>
            ) : (
              <div className="youhavenot-container">
                <p>You have not selected any grammar</p>
              </div>
            )}
          </div>
        )}
        {allFavorites.blogs && (
          <div className="grammar__section">
            <h2 className="dashboard-page-title">LKWB Blogs</h2>
            <Row md={3} sm={2} xs={1} style={{ rowGap: 20 }}>
              {allFavorites.blogs.length > 0 ? (
                <>
                  {allFavorites.blogs.map((item, id) => (
                    <Col key={id}>
                      <MainBlog blogFrom="dashboard" blog={item} />
                    </Col>
                  ))}
                </>
              ) : (
                <div className="youhavenot-container">
                  <p>You have not selected any blogs</p>
                </div>
              )}
            </Row>
          </div>
        )}
      </Row>
    </div>
  );
};

export default Favorite;
