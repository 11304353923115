import React, { useState, useRef, useEffect } from "react";
const Timer = ({ time, testStatus, handeChangeTimer, endTest }) => {
  // let initial_Time = 0.5 * 60;
  //in minutes converted into seconds
  let initial_Time = localStorage.getItem("testTime")
    ? localStorage.getItem("testTime")
    : parseInt(time) * 60;
  const [secondsRemaining, setSecondRemaining] = useState(initial_Time);
  const [timerStatus, setTimerStatus] = useState(false);
  const secondsToDisplay = secondsRemaining % 60;
  const minutesRemaining = (secondsRemaining - secondsToDisplay) / 60;
  const minutesToDisplay = minutesRemaining % 60;
  const hoursToDisplay = (minutesRemaining - minutesToDisplay) / 60;
  function useInterval(callback, delay) {
    const savedCallback = useRef();
    // Remember the latest callback.
    useEffect(() => {
      savedCallback.current = callback;
    }, [callback]);

    // Set up the interval.
    useEffect(() => {
      function tick() {
        savedCallback.current();
      }
      if (delay !== null) {
        let id = setInterval(tick, delay);
        return () => clearInterval(id);
      }
    }, [delay]);
  }
  const twoDigits = (num) => String(num).padStart(2, "0");
  useInterval(
    () => {
      if (testStatus) {
        if (secondsRemaining > 0) {
          setSecondRemaining(secondsRemaining - 1);
          localStorage.setItem("testTime", secondsRemaining - 1);
        } else {
          setTimerStatus(!timerStatus);
          endTest();
        }
        handeChangeTimer(Math.floor(secondsRemaining / 60));
      }
    },
    timerStatus === timerStatus ? 1000 : null
  );

  return (
    <div>
      {twoDigits(hoursToDisplay)}:{twoDigits(minutesToDisplay)}:
      {twoDigits(secondsToDisplay)}
    </div>
  );
};
export default Timer;
