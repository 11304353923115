import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { all_ads } from "./AdApi";
const initialState = {
  isLoading: false,
  allAdPositions: {
    roadblock: [],
    "banner-aboutus-section-homepage-1920-x-100": [],
    "sidebar-blog-detail-homepage-306-x-250": [],
    "banner-blog-detail-homepage-1920-x-100": [],
    "sidebar-grammar-list-homepage-306-x-250": [],
    "sidebar-grammar-detail-homepage-306-x-250": [],
    "sidebar-dictionary-list-homepage-306-x-250": [],
    "sidebar-dictionary-detail-homepage-306-x-250": [],
    "banner-home-dashboard-1920-x-100": [],
    "banner-blog-detail-dashboard-1920-x-100": [],
    "sidebar-blog-detail-dashboard-306-x-250": [],
    "sidebar-grammar-list-dashboard-306-x-250": [],
    "sidebar-grammar-detail-dashboard-306-x-250": [],
    "sidebar-dictionary-list-dashboard-306-x-250": [],
    "sidebar-dictionary-detail-dashboard-306-x-250": [],
  },
  displayedRoadblockAd: false,
  alreadyDisplayed: false,
};
export const allAds = createAsyncThunk("ad/allAds", async () => {
  try {
    return await all_ads();
  } catch (error) {
    return error;
  }
});
const AdSlice = createSlice({
  name: "ad",
  initialState,
  reducers: {
    displayRoadBlock: (state) => {
      state.displayedRoadblockAd = true;
    },
    hideRoadBlock: (state) => {
      state.displayedRoadblockAd = false;
      state.alreadyDisplayed = true;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(allAds.pending, (state) => {
        state.isLoading = true;
        for (const [key] of Object.entries(state.allAdPositions)) {
          state.allAdPositions[key] = [];
        }
      })
      .addCase(allAds.fulfilled, (state, action) => {
        state.isLoading = false;
        let allAds = action.payload.data.data;
        for (const [key] of Object.entries(state.allAdPositions)) {
          for (let i = 0; i < allAds.length; i++) {
            if (allAds[i].section_name === key) {
              state.allAdPositions[key] = allAds[i].advertisements;
            }
          }
        }
      })
      .addCase(allAds.rejected, (state, action) => {
        for (const [key] of Object.entries(state.allAdPositions)) {
          state.allAdPositions[key] = [];
        }
        state.isLoading = false;
        toast.error(action.payload.data.message);
      });
  },
});
export const { displayRoadBlock, hideRoadBlock } = AdSlice.actions;
export default AdSlice.reducer;
