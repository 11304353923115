import React from "react";
import { Link } from "react-router-dom";

const FooterLinks = ({ links }) => {
  return (
    <>
      <h2 className="footerhead">{links.header}</h2>
      <ul className="footer-links">
        {links.links.map((item, id) => (
          <li key={id}>
            <Link to={item.url}>{item.display_title}</Link>
          </li>
        ))}
      </ul>
    </>
  );
};

export default FooterLinks;
