import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getNotification } from "../../../../Features/NotificationApi/NotificationSlice";
import NotificationList from "./NotificationList";
import { Link } from "react-router-dom";
const NotificationComponent = () => {
  const dispatch = useDispatch();
  let allnotification = useSelector(
    (state) => state.notification.notifications
  );
  let token = useSelector((state) => state.auth.currentUser.token);
  useEffect(() => {
    dispatch(getNotification({ token }));
  }, [dispatch]);
  return (
    <>
      {allnotification && (
        <div className="notificationbell hovermenu-container">
          <div className="notificationicon">
            <i className="bi bi-bell"></i>
            <span>
              {allnotification.filter((item) => item.read === false).length}
            </span>
          </div>
          {allnotification.length > 0 ? (
            <ul className="notificationContainer hovermenu">
              <>
                {" "}
                {allnotification.slice(0, 5).map((item, id) => (
                  <NotificationList item={item} key={id} />
                ))}
                <Link
                  to="/dashboard/notification"
                  className="readAllNotification"
                >
                  Read All Notification
                </Link>
              </>
            </ul>
          ) : (
            <ul className="notificationContainer no-notification hovermenu">
              <li>You do not have any notification.</li>
            </ul>
          )}
        </div>
      )}
    </>
  );
};

export default NotificationComponent;
