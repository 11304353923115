import React from "react";
const DashboardSummary = ({ view }) => {
  return (
    <div className="test-summary">
      <h3>Summary</h3>
      {
        <>
          {view === "questionView" && (
            <div className="eachsummary">
              <span className="attemptedQuestions">Attempted Questions</span>
              <span className="unsolvedQuestions">Unattempted Questions</span>
            </div>
          )}
          {view === "resultView" && (
            <div className="eachsummary resultSummary">
              <span className="correctQuestions">Correct Answer</span>
              <span className="wrongQuestions">Incorrect Answer</span>
              <span className="unsolvedQuestions">Unattempted Questions</span>
            </div>
          )}
        </>
      }
    </div>
  );
};

export default DashboardSummary;
