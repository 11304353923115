import React, { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import ubtTest from "../../../../Assets/img/test-img.png";
import HTMLParser from "../../../Components/HTMLParser";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { eachSets } from "../../../../Features/SetsAPI/SetsSlice";
import { resetTest } from "../../../../Features/SetsAPI/LocalSetSlice";
import Loader from "../../../Components/Loader/Loader";
const UBTCourse = () => {
  let { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let token = useSelector((state) => state.auth.currentUser.token);
  let selectedList = useSelector((state) => state.set.selectedSet);
  let isLoading = useSelector((state) => state.set.isLoading);
  let selectedPackage = useSelector((state) => state.set.selectedPackage);
  useEffect(() => {
    dispatch(
      eachSets({
        token,
        id,
        from: Object.keys(selectedPackage).length > 0 && "package",
      })
    );
  }, [dispatch]);
  const getCreatedAt = (date) => {
    let newDate = new Date(date);
    return `${newDate.toLocaleDateString()} at ${newDate.getUTCHours()}:${newDate.getUTCMinutes()}:${newDate.getUTCSeconds()}`;
  };
  const navigateTo = (item) => {
    dispatch(resetTest());
    navigate(item, {
      replace: true,
    });
  };
  return (
    <>
      {!isLoading ? (
        <>
          {selectedList && (
            <Row>
              <Col md={9}>
                <div className="dashboard-contents dashboard-test">
                  <Link to="/dashboard/ubt-test" className="btn btn-small">
                    Go Back
                  </Link>
                  <div className="dashboard-contentshead">
                    <img src={ubtTest} alt="" />
                    <div className="packageName">
                      <h3 className="eachdashboard-title">
                        {selectedList.title}
                      </h3>
                      <p>
                        <HTMLParser>
                          {selectedList.description
                            ? selectedList.description
                            : null}
                        </HTMLParser>
                      </p>
                      <p className="boughtat">
                        Bought At: {getCreatedAt(selectedList.bought_at)}
                      </p>
                      <div className="packageTime">
                        <i className="bi bi-stopwatch"></i>
                        {selectedList.time} Min
                      </div>
                    </div>
                  </div>
                  <div className="eachdashboard-detail">
                    <h3 className="eachdashboard-title">Marks</h3>
                    <p>
                      Full Marks: <strong> {selectedList.full_marks}</strong>
                    </p>
                  </div>
                  <div className="eachdashboard-detail">
                    <h3 className="eachdashboard-title">Total Questions</h3>
                    <p>
                      Listening Questions:{" "}
                      <strong>
                        {selectedList.listening ? selectedList.listening : 0}
                      </strong>
                    </p>
                    <p>
                      Reading Questions:{" "}
                      <strong>
                        {selectedList.reading ? selectedList.reading : 0}
                      </strong>
                    </p>
                  </div>
                </div>
                <p className="dashboard-note">
                  <strong>Note:</strong> Once you submit the test, you cannot
                  take the test again. You can leave the test at any time. Do
                  not reload the page the test will be over. Do not try to
                  navigate to other pages the test will be over.
                </p>
                <button
                  onClick={() =>
                    navigateTo(`/dashboard/ubt-test/ubt-test-questions/${id}`)
                  }
                  className="btn"
                >
                  Start Test
                </button>
              </Col>
              <Col md={3} className="rightsidebar"></Col>
            </Row>
          )}
        </>
      ) : (
        <div className="loading__mode bigLoading">
          <Loader></Loader>
        </div>
      )}
    </>
  );
};

export default UBTCourse;
