import React, { useState } from "react";
import { openViewOTP } from "../../../Features/ViewSlice";
import { useDispatch } from "react-redux";
import { forgotPassword } from "../../../Features/AuthSlice";
import { toast } from "react-toastify";
const ForgotPassword = () => {
  let disatch = useDispatch();
  const [email, setEmail] = useState("");
  const handleChange = (e) => {
    setEmail(e.target.value);
  };
  const sendCode = () => {
    if (email.length === 0) {
      toast.error("Email Field is empty. Please Enter your email");
    } else {
      if (
        email.toLowerCase().includes("@") &&
        email.toLowerCase().includes(".")
      ) {
        localStorage.setItem("email", email);
        disatch(forgotPassword(email)).then((res) => {
          if (res.payload.status === 200) {
            disatch(openViewOTP());
          }
        });
      } else {
        toast.error("Please enter valid email");
      }
    }
  };

  return (
    <div className="forgotpassword-container">
      <h2>Forgot Password?</h2>
      <p>
        Don't worry! It occurs. Please enter the email address linked with your
        account.
      </p>
      <label htmlFor="">
        <input
          type="email"
          name="email"
          placeholder="Enter Your Email"
          onChange={handleChange}
        ></input>
      </label>
      <button value="Send Code" onClick={sendCode}>
        Submit
      </button>
    </div>
  );
};

export default ForgotPassword;
