import React from "react";
import { Accordion } from "react-bootstrap";
const AccordionItems = ({ mockFAQ }) => {
  return (
    <Accordion defaultActiveKey={["0"]} alwaysOpen>
      {mockFAQ.map((item, id) => {
        return (
          <Accordion.Item eventKey={id.toString()} key={id}>
            <Accordion.Header>{item.question}</Accordion.Header>
            <Accordion.Body>{item.answer}</Accordion.Body>
          </Accordion.Item>
        );
      })}
    </Accordion>
  );
};

export default AccordionItems;
