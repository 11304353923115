import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import Timer from "../../Components/Timer";
import { useParams } from "react-router-dom";
import DashboardSummary from "../../Components/DashboardSummary";
import QuestionsItem from "../../Components/QuestionsItem";
import { useSelector, useDispatch } from "react-redux";
import { saveResult } from "../../../../Features/SetsAPI/LocalSetSlice";
import Accordion from "react-bootstrap/Accordion";
import {
  getQuestions,
  selectQuestion,
} from "../../../../Features/SetsAPI/SetsSlice";
import { endTest, startTest } from "../../../../Features/SetsAPI/LocalSetSlice";
import { useNavigate } from "react-router-dom";
import UBTQuestion from "./UBTQuestion";
import { Modal } from "react-bootstrap";
import Loader from "../../../Components/Loader/Loader";
import clock from "../../../../Assets/img/clock.png";
import TestOver from "../../Components/TestOver";
import { useLocation } from "react-router-dom";
const UBTQuestions = () => {
  let { setsid } = useParams();
  const navigate = useNavigate();
  let location = useLocation();
  const dispatch = useDispatch();
  let isloading = useSelector((state) => state.set.isLoading);
  let token = useSelector((state) => state.auth.currentUser.token);
  let allQuestions = useSelector((state) => state.set.questions);
  let selectedSet = useSelector((state) => state.set.selectedSet);
  let selectedPackage = useSelector((state) => state.set.selectedPackage);
  let selectedQuestion = useSelector((state) => state.set.selectedQ);
  let testState = useSelector((state) => state.localset.questionState);
  let solvedQuestion = useSelector((state) => state.localset.solvedQuestion);
  const [error, setError] = useState();
  const [timeToStart, setTimeToStart] = useState(3);
  const [getCurrentTime, setCurrentTime] = useState("");
  const [countdownState, setCountDownState] = useState(false);
  const start_Test = () => {
    dispatch(startTest());
  };
  const end_Test = () => {
    localStorage.removeItem("testTime");
    localStorage.removeItem("currentTestUrl");
    localStorage.removeItem("currentTimeStamp");
    dispatch(endTest());
  };
  const getCurrent = (currentID) => {
    let idOfQuestions = {};
    allQuestions &&
      allQuestions.forEach((item, index) => {
        idOfQuestions[index] = item.id;
      });
    for (const [key, value] of Object.entries(idOfQuestions)) {
      if (parseInt(currentID) === parseInt(value)) {
        let keyTO = key;
        let nextKey = parseInt(keyTO) + 1;
        let prevKey = keyTO - 1;
        let nextValue = idOfQuestions[nextKey];
        let prevValue = idOfQuestions[prevKey];
        return {
          keyId: parseInt(key),
          nextVal: parseInt(nextValue),
          prevVal: parseInt(prevValue),
        };
      } else {
        continue;
      }
    }
  };
  const checkIfFIrstQuestions = () => {
    if (allQuestions.length > 1) {
      let index = allQuestions.findIndex(
        ({ id }) => id === selectedQuestion.id
      );
      return parseInt(index);
    }
    if (allQuestions.length === 1) {
      let index = 1;
      return parseInt(index);
    }
  };
  useEffect(() => {
    if (!testState.end) {
      dispatch(getQuestions({ setsid, token })).then((res) => {
        if (res.payload.status === 200) {
          if (res.payload.data.data.length > 0) {
            setCountDownState(true);
            dispatch(selectQuestion(res.payload.data.data[0].id));
          } else {
            setError("No Questions");
          }
        }
      });
    }
  }, [dispatch]);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [submit, setSubmit] = useState(false);
  const handleCloseSubmit = () => setSubmit(false);
  const handleShowSubmit = () => setSubmit(true);

  const [cannotgoBack, setCannotGoBack] = useState(false);
  const handleShowCannotGoBack = () => {
    setCannotGoBack(true);
  };
  const handleCloseCannotGoBack = () => {
    setCannotGoBack(false);
  };
  const [cancel, setCancel] = useState(false);
  const handleCloseCancel = () => {
    setCancel(false);
  };
  const [showReloadModal, setShowReloadModal] = useState(false);
  const handleShowReloadModal = () => setShowReloadModal(true);
  const handleShowCancel = () => setCancel(true);
  let audioQuestions = [];
  let readingQuestions = [];
  if (allQuestions) {
    allQuestions.forEach((item) => {
      if (item.type === "audio") {
        audioQuestions.push(item);
      }
      if (item.type === "image" || item.type === "text") {
        readingQuestions.push(item);
      }
    });
  }
  useEffect(() => {
    if (countdownState === true) {
      if (testState.end === false) {
        timeToStart > 0 &&
          setTimeout(() => setTimeToStart(timeToStart - 1), 1000);
        if (timeToStart === 0) {
          start_Test();
        }
      }
    }
  }, [timeToStart, countdownState]);
  const timerStopped = () => {
    end_Test();
    handleShow();
  };
  useEffect(() => {
    if (testState.end === true) {
      handleShow();
    }
  }, [testState]);
  const handeChangeTimer = (time) => {
    setCurrentTime(time);
  };
  const saveAnswer = () => {
    handleCloseCannotGoBack();
    let attempted = [];
    solvedQuestion.forEach((item) =>
      item.answers.options.forEach((optionItem) => {
        if (optionItem.hasOwnProperty("userSelected")) {
          attempted.push(item);
        }
      })
    );
    const getCurrentAnswer = () => {
      let result = 0;
      let allInput = [];
      solvedQuestion.forEach((item) => {
        allInput.push(
          ...item.answers.options.filter((selectedItem) => {
            return selectedItem.userSelected === true;
          })
        );
      });
      allInput.forEach((item) => {
        if (item.is_correct === 1) {
          result++;
        }
      });
      return parseInt(result);
    };
    let formData = new FormData();
    formData.append("set_id", setsid);
    formData.append("attempted_questions", attempted.length);
    formData.append(
      "unsolved_questions",
      allQuestions && allQuestions.length - attempted.length
    );
    formData.append("time_spent", parseInt(selectedSet.time) - getCurrentTime);
    formData.append("correct_answers", getCurrentAnswer());
    formData.append("score", getCurrentAnswer() * 2.5);
    if (Object.keys(selectedPackage) != 0) {
      formData.append("package_id", selectedPackage.id);
    }
    dispatch(saveResult({ formData, token }));
    navigate(`/dashboard/dashboard-results`, {
      replace: true,
    });
    ///to end the test
    end_Test();
  };
  useEffect(() => {
    window.addEventListener("beforeunload", alertUser);
    return () => {
      window.removeEventListener("beforeunload", alertUser);
    };
  }, []);
  const alertUser = (e) => {
    localStorage.setItem("currentUrl", window.location.href);
    localStorage.setItem("currentTimeStamp", Date.now());
    e.preventDefault();
    e.returnValue = "";
  };
  useEffect(() => {
    if (
      window.performance.getEntriesByType("navigation")[0].type === "reload" &&
      localStorage.getItem("currentUrl") === window.location.href &&
      localStorage.getItem("currentTimeStamp") &&
      testState.start
    ) {
      handleShowReloadModal();
      setTimeout(() => {
        saveAnswer();
      }, 5000);
    }
  }, [localStorage]);
  //to prevent from navigating backwards
  useEffect(() => {
    localStorage.setItem("currentTestUrl", location.pathname);
    if (testState.start === true) {
      handleShowCannotGoBack();
    }
  }, [location]);
  window.onpopstate = () => {
    localStorage.setItem("currentTestUrl", location.pathname);
    handleShowCannotGoBack();
    if (testState.start === true) {
      navigate(localStorage.getItem("currentTestUrl"), {
        replace: true,
      });
      setTimeout(() => {
        handleShowCannotGoBack();
      }, 3000);
    }
  };
  return (
    <>
      {!isloading ? (
        <>
          <Modal show={submit} onHide={handleCloseSubmit}>
            <Modal.Body>
              <p>Do you really want to submit the test? You can't undo it.</p>
              <button className="btn btn-small" onClick={saveAnswer}>
                Yes
              </button>
              <button
                className="btn btn-small btn-secondary"
                onClick={handleCloseSubmit}
              >
                No
              </button>
            </Modal.Body>
          </Modal>
          <Modal show={cannotgoBack} onHide={handleCloseCannotGoBack}>
            <Modal.Body>
              <p>
                You must submit the test before navigating to other pages. Do
                you really want to submit?{" "}
              </p>
              <button className="btn btn-small" onClick={saveAnswer}>
                Yes
              </button>
              <button
                className="btn btn-small btn-secondary"
                onClick={handleCloseCannotGoBack}
              >
                No
              </button>
            </Modal.Body>
          </Modal>
          <div className="closeTest">
            <h3>{selectedSet.title}</h3>
            <span className="close" onClick={handleShowCancel}>
              <i className="bi bi-x-lg"></i>
            </span>
          </div>
          {!error ? (
            <>
              {selectedQuestion && Object.keys(selectedQuestion).length > 0 && (
                <Row>
                  <TestOver
                    cancel={cancel}
                    handleCloseCancel={handleCloseCancel}
                    saveAnswer={saveAnswer}
                  />
                  {testState.start ? (
                    <>
                      <Col md={9}>
                        {allQuestions && (
                          <div className="timer">
                            <div className="d-flex align-items-center justify-content-between">
                              <span>
                                Question No: {checkIfFIrstQuestions() + 1} /
                                {allQuestions.length}
                              </span>
                              <span>
                                <i className="bi bi-stopwatch"></i>
                                {selectedSet && (
                                  <Timer
                                    time={
                                      localStorage.getItem("testTime")
                                        ? localStorage.getItem("testTime")
                                        : selectedSet.time
                                    }
                                    handeChangeTimer={(time) =>
                                      handeChangeTimer(time)
                                    }
                                    testStatus={testState}
                                    endTest={timerStopped}
                                  />
                                )}
                              </span>
                            </div>
                          </div>
                        )}
                        <div className="testQuestions">
                          <UBTQuestion
                            question={selectedQuestion}
                            questionNo={checkIfFIrstQuestions() + 1}
                          />
                          <div className="d-flex align-items-center justify-content-between">
                            <button className="btn hideonmobile" onClick={handleShowSubmit}>
                              Submit Now
                            </button>
                            <div className="cta__btns text-end mt-2">
                              {checkIfFIrstQuestions() >= 1 && (
                                <button
                                  className="btn btn-outline"
                                  onClick={() =>
                                    dispatch(
                                      selectQuestion(
                                        getCurrent(selectedQuestion.id).prevVal
                                      )
                                    )
                                  }
                                >
                                  <i className="bi bi-chevron-left"></i>
                                  Previous
                                </button>
                              )}
                              {checkIfFIrstQuestions() + 1 !=
                                allQuestions.length && (
                                <button
                                  className="btn"
                                  onClick={() =>
                                    dispatch(
                                      selectQuestion(
                                        getCurrent(selectedQuestion.id) &&
                                          getCurrent(selectedQuestion.id)
                                            .nextVal
                                      )
                                    )
                                  }
                                >
                                  Next <i className="bi bi-chevron-right"></i>
                                </button>
                              )}
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col md={3} className="rightsidebar">
                        <div className="sticky">
                          <button
                            className="btn fw showonmobile"
                            onClick={handleShowSubmit}
                          >
                            Submit Now
                          </button>
                          <DashboardSummary view="questionView" />
                          <Accordion defaultActiveKey={["0"]} alwaysOpen>
                            {readingQuestions.length > 1 && (
                              <QuestionsItem
                                eventId="0"
                                setsid={setsid}
                                label="Reading Questions"
                                questions={readingQuestions}
                              />
                            )}
                            {audioQuestions.length > 1 && (
                              <QuestionsItem
                                eventId="1"
                                setsid={setsid}
                                label="Listening Questions"
                                questions={audioQuestions}
                                startFrom={
                                  readingQuestions.length > 1
                                    ? readingQuestions.length
                                    : 0
                                }
                              />
                            )}
                          </Accordion>
                        </div>
                      </Col>
                    </>
                  ) : (
                    <>
                      {testState.end ? (
                        <Modal
                          show={show}
                          onHide={handleClose}
                          backdrop="static"
                        >
                          <Modal.Body>
                            <p>Your test has ended.</p>
                            <button
                              className="btn"
                              onClick={() => {
                                navigate(
                                  "/dashboard/dashboard-viewall-results",
                                  {
                                    replace: true,
                                  }
                                );
                              }}
                            >
                              Proceed To View Results
                            </button>
                          </Modal.Body>
                        </Modal>
                      ) : (
                        <div className="startTest text-center">
                          <h5>Your Test is going to start in</h5>
                          <div className="timetostart">
                            <span>
                              <img src={clock}></img>
                              {timeToStart}
                            </span>
                            <span>s</span>
                          </div>
                          <p>Be Ready!</p>
                          <p>All the Best</p>
                        </div>
                      )}
                    </>
                  )}
                </Row>
              )}
            </>
          ) : (
            <div>{error}</div>
          )}
          <Modal show={showReloadModal} backdrop="static">
            <Modal.Body>
              <div className="loading__mode bigloading">
                <Loader label="Submiting you results" />
              </div>
            </Modal.Body>
          </Modal>
        </>
      ) : (
        <div className=" dashboard-contents bigLoading loading__mode">
          <Loader label="Fetching all the questions. Please wait!"></Loader>
        </div>
      )}
    </>
  );
};

export default UBTQuestions;
