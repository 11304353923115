import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import { AllRoutes } from "../../../AllRoutes";
import Logo from "../../../Assets/img/logo-bibek3.png";
import { clearAllValue } from "../../../Features/SetsAPI/LocalSetSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Modal, Offcanvas } from "react-bootstrap";
import { logoutFunc, signOut } from "../../../Features/AuthSlice";
import {
  emptyProfile,
  getProfile,
} from "../../../Features/Profile_management/profileSlice";
import { siteInfo } from "../../../Features/HomeApi/HomeSlice";
import { socialOBJKEY } from "../../../Constants";
import { clearSession } from "../../../Features/SetsAPI/SetsSlice";
import fallBackImg from "../../../Assets/img/fallbackimg.png";
import NotificationComponent from "./Notification/NotificationComponent";
import SocialLinks from "../../Components/Footer/SocialLinks";
import ReclaimModal from "./ReclaimModal";
export default function DashboardNav() {
  let dispatch = useDispatch();
  let navigate = useNavigate();
  let userMenu = useRef(null);
  let testState = useSelector((state) => state.localset.questionState);
  let token = useSelector((state) => state.auth.currentUser.token);
  let deviceToken = useSelector((state) => state.auth?.deviceToken);
  const currentLocation = useLocation().pathname;
  function getSecondlink(str) {
    return str.split("/")[2];
  }
  const checkActiveLink = (linkId) => {
    if (currentLocation === linkId) {
      return "active";
    } else {
      if (getSecondlink(currentLocation) === getSecondlink(linkId)) {
        return "active";
      }
      return "inactive";
    }
  };

  const [showMenu, setShowMenu] = useState(false);
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [infoStatus, setShowInfo] = useState(false);
  const [reclaim, setReclaim] = useState(false);
  const closeReclaim = () => setReclaim(false);
  const handleClose = () => setShow(false);
  const handleClose1 = () => setShow1(false);
  const handleShow = () => setShow(true);

  const showReclaim = () => {
    if (testState.start === true) {
      handleShow();
    } else {
      setReclaim(true);
    }
  };
  const handleShow1 = () => {
    setShow1(true);
  };
  const showInfo = () => {
    setShowInfo(!infoStatus);
  };
  const navigateTo = (item) => {
    if (testState.start === true) {
      handleShow();
    } else {
      handleClose1();
      dispatch(clearAllValue());
      navigate(item, { replace: true });
    }
  };

  let site_Info = useSelector((state) => state.home.siteInfo);
  let isEmailVerified = useSelector((state) => state.auth.isEmailVerified);
  let authToken = useSelector((state) => state.auth.currentUser.token);
  let profileInfo = useSelector((state) => state.profile.profileInfo);
  useEffect(() => {
    {
      isEmailVerified && dispatch(getProfile(authToken));
      dispatch(siteInfo());
    }
  }, [dispatch, authToken, isEmailVerified]);

  const handleClick = () => {
    setShowMenu(true);
  };
  const getSocialLink = () => {
    let link;
    if (site_Info.social_links) {
      link = [...JSON.parse(JSON.stringify(site_Info.social_links))];
      link.forEach((item, index) => {
        if (item.facebook_page) {
          link[0].icon = socialOBJKEY.facebook_page;
          link[0].link = item.facebook_page;
        } else if (item.facebook_group) {
          link[1].link = item.facebook_group;
          link[1].icon = socialOBJKEY.facebook_group;
        } else if (item.youtube) {
          link[2].link = item.youtube;
          link[2].icon = socialOBJKEY.youtube;
        } else if (item.tik_tok) {
          link[3].link = item.tik_tok;
          link[3].icon = socialOBJKEY.tiktok;
        } else if (item.instagram) {
          link[4].link = item.instagram;
          link[4].icon = socialOBJKEY.instagram;
        }
      });
    }
    return link;
  };
  const detectClickOutside = () => {
    window.addEventListener("mousedown", (e) => {
      if (userMenu.current && !userMenu.current.contains(e.target)) {
        setShowMenu(false);
      }
    });
  };
  detectClickOutside();
  const socialLinks = {
    links: getSocialLink() && getSocialLink(),
  };

  const navigateToTest = () => {
    if (localStorage.getItem("currentTestUrl")) {
      handleClose();
      navigate(localStorage.getItem("currentTestUrl"), {
        replace: true,
      });
    } else {
      handleClose();
      navigate("/dashboard/ubt-test", {
        replace: true,
      });
    }
  };
  return (
    <nav className={`${isEmailVerified ? "" : "heightfull"} dashboard__navbar`}>
      <div className="logo">
        <div onClick={() => navigateTo("/")}>
          <img src={Logo} alt="Learn Korean With Bibek" />
        </div>
        <div className="menuProfile">
          <div className="burger__menu" onClick={handleShow1}>
            <i className="bi bi-list"></i>
          </div>
          {profileInfo && (
            <div className="dashboard__user dashboardUser__mobile">
              <Link onClick={handleClick}>
                <img
                  src={profileInfo.image ? profileInfo.image : fallBackImg}
                  alt={profileInfo.name}
                />
              </Link>
              <NotificationComponent />
              {showMenu && (
                <ul className="dashboard-settings" ref={userMenu}>
                  <li>
                    <Link to="/dashboard/profile">View Profile</Link>
                  </li>
                  <li>
                    <Link to="/dashboard/editprofile">Edit Profile</Link>
                  </li>
                  <li>
                    <Link to="/dashboard/favorite">Favorite</Link>
                  </li>
                  <li>
                    <Link onClick={handleShow}>About LKWB</Link>
                  </li>
                  <li>
                    <Link
                      onClick={(e) => {
                        e.preventDefault();
                        dispatch(signOut({ token, deviceToken }));
                        navigate("/", { replace: true });
                        dispatch(emptyProfile());
                        dispatch(logoutFunc());
                        dispatch(clearAllValue());
                        dispatch(clearSession());
                      }}
                    >
                      Logout
                    </Link>
                  </li>
                </ul>
              )}
            </div>
          )}
        </div>
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Body>
            <p>
              Your test is going on. You cannot go to other page while test is
              going on.
            </p>
            <button
              className="btn btn-small btn-green"
              onClick={navigateToTest}
            >
              Go To Test
            </button>
          </Modal.Body>
        </Modal.Header>
      </Modal>
      {isEmailVerified && (
        <div className="dashboard__navbar--items">
          <ul>
            {AllRoutes.dashboardRoute.map((item, id) => {
              if (item.inSideBar) {
                return (
                  <li
                    key={id}
                    onClick={() => navigateTo(item.link)}
                    className={`${checkActiveLink(`${item.link}`)}`}
                  >
                    <img src={item.icon} alt="Home" />
                    {item.label}
                    {/* <Link
                      to={item.link}
                      id="home"
                      className={`${checkActiveLink(`${item.link}`)}`}
                    >
                      <img src={item.icon} alt="Home" />
                      {item.label}
                    </Link> */}
                  </li>
                );
              }
            })}
          </ul>
        </div>
      )}

      <div className="quick-links reclaimbtn">
        <button className="btn" onClick={showReclaim}>
          Reclaim
        </button>
        <p>
          Click here, If transaction is complete, but the set has not opened.
        </p>
        <ReclaimModal reclaim={reclaim} closeReclaim={closeReclaim} />
      </div>
      <div className="quick-links social--links">
        <h3>Follow Us On</h3>
        {site_Info.social_links && <SocialLinks links={socialLinks} />}
      </div>
      {/* <button className="btn btn-secondary" onClick={() => dispatch(logoutFunc())}>
        Logout
      </button> */}
      <Offcanvas show={show1} onHide={handleClose1}>
        <Offcanvas.Header>
          <Offcanvas.Title>
            <a href="/#" aria-label="learn korean with bibek">
              <img src={Logo} alt="Learn Korean With Bibek" />
            </a>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <ul className="offCancvas__menu">
            {AllRoutes.dashboardRoute.map((item, id) => {
              if (item.inSideBar) {
                return (
                  <li
                    key={id}
                    onClick={() => navigateTo(item.link)}
                    className={`${checkActiveLink(`${item.link}`)}`}
                  >
                    <img src={item.icon} alt="Home" />
                    {item.label}
                  </li>
                );
              }
            })}
          </ul>
          <div className="reclaimbtn">
            <button className="btn" onClick={showReclaim}>
              Reclaim
            </button>
            <i className="bi bi-info-circle" onClick={showInfo}></i>
            <p className={`${infoStatus && "showInfo"}`}>
              Click here, If transaction is complete, but the set has not
              opened.
            </p>
            <ReclaimModal reclaim={reclaim} closeReclaim={closeReclaim} />
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </nav>
  );
}
