import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import {
  redirectToKhalti,
  selectPackage,
  buypackageWithRewardPoint,
  prelogFn,
  loadend,
} from "../../../../Features/SetsAPI/SetsSlice";
import esewa from "../../../../Assets/img/Esewa.png";
import khalti from "../../../../Assets/img/Khalti.png";
import rewardpoint from "../../../../Assets/img/rewardpoint.png";
import { Modal } from "react-bootstrap";
import Loader from "../../../Components/Loader/Loader";
import { toast } from "react-toastify";
import { mode, host, paymentUrl } from "../../../../Constants";

const UBTPackageBuy = () => {
  let { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let allPackage = useSelector((state) => state.set.packageCollection);
  let selectedPackage = useSelector((state) => state.set.selectedPackage);
  let currentUserId = useSelector((state) => state.profile.profileInfo.id);
  let token = useSelector((state) => state.auth.currentUser.token);
  const [checked, setChecked] = useState();
  const [show, setShow] = useState(false);
  const handleShow = () => {
    setShow(true);
  };
  const closeShow = () => {
    setShow(false);
  };

  const changeRadio = (e) => {
    setChecked(e.target.name);
  };
  useEffect(() => {
    dispatch(
      selectPackage(allPackage.find((item) => item.id === parseInt(id)))
    );
  }, [selectedPackage, id]);
  const randomIdGenerator = () => {
    return `p-${selectedPackage.id}-u-${currentUserId}-${Math.floor(
      Date.now() * Math.random() * 100
    )}`;
  };

  const esewaPayment = async () => {
    let form;
    let esewaURL = `${
      mode === "test" || mode === "dev"
        ? "https://uat.esewa.com.np/epay/main"
        : "https://esewa.com.np/epay/main"
    }`;
    const data = {
      amt: selectedPackage && selectedPackage.price,
      tAmt: selectedPackage && selectedPackage.price,
      psc: 0,
      pdc: 0,
      txAmt: 0,
      pid: randomIdGenerator(),
      scd: `${
        mode === "test" || mode === "dev" ? "EPAYTEST" : "NP-ES-LEARNKOREAN"
      }`,
      // su: `${host}/esewa_payment_success`,
      su: `${paymentUrl}/page/esewa_payment_success`,
      fu: `${paymentUrl}/esewa_payment_failed`,
      // su: "https://new.lkwb.com.np/page/esewa_payment_success",
      // su: "https://lkwb.com.np/page/esewa_payment_success",
      // fu: "https://new.lkwb.com.np/page/esewa_payment_failed",
      // fu: "https://lkwb.com.np/page/esewa_payment_failed",
    };
    const post = () => {
      form = document.createElement("form");
      form.setAttribute("method", "POST");
      form.setAttribute("action", esewaURL);
      for (var key in data) {
        var hiddenField = document.createElement("input");
        hiddenField.setAttribute("type", "hidden");
        hiddenField.setAttribute("name", key);
        hiddenField.setAttribute("value", data[key]);
        form.appendChild(hiddenField);
      }
      document.body.appendChild(form);
      form.submit();
    };
    dispatch(
      prelogFn({ token, gateway: "esewa", response: JSON.stringify(data) })
    ).then(() => {
      dispatch(loadend);
      post();
    });
  };
  const buyKhaltiPayment = async () => {
    const data = {
      return_url: `${paymentUrl}/page/khalti_payment_success`,
      // return_url: "https://lkwb.com.np/page/khalti_payment/",
      // return_url: "https://new.lkwb.com.np/page/khalti_payment",
      website_url: `${host}`,
      // website_url: "https://lkwb.com.np/",
      // website_url: "https://new.lkwb.com.np",
      amount: selectedPackage && selectedPackage.price * 100,
      purchase_order_id: randomIdGenerator(),
      purchase_order_name: `${
        selectedPackage && selectedPackage.title
          ? selectPackage.title
          : selectedPackage.category + "-" + Date.now()
      }`,
    };
    dispatch(
      prelogFn({ token, gateway: "khalti", response: JSON.stringify(data) })
    ).then(() => {
      dispatch(redirectToKhalti({ data, token })).then((res) => {
        if (JSON.parse(res.payload.data).detail) {
          closeShow();
        }
      });
    });
  };
  const buyPackage = () => {
    if (checked === "Esewa") {
      esewaPayment();
      dispatch(handleShow());
    }
    if (checked === "Khalti") {
      buyKhaltiPayment();
      dispatch(handleShow());
    }
    if (checked === "RewardPoint") {
      dispatch(
        buypackageWithRewardPoint({
          token,
          id,
        })
      ).then((res) => {
        if (res.payload.status === 200) {
          navigate(`/dashboard/ubt-test/ubt-test-invoice`, {
            replace: true,
          });
        }
      });
    }
    if (!checked) {
      toast.error("Please select at least one payment method.");
    }
  };

  const goBack = () => {
    navigate("/dashboard/ubt-test", {
      replace: true,
    });
  };
  const imgText = (img, text) => {
    return (
      <div className="labelImg">
        <img src={img} />
        <p>{text}</p>
      </div>
    );
  };
  return (
    <>
      <Modal show={show} backdrop="static" centered onHide={closeShow}>
        <Modal.Body>
          <div className="pleasewait">
            <Loader label="Initiating Your Payment" />
            <p>Please wait.</p>
          </div>
        </Modal.Body>
      </Modal>
      {selectedPackage && selectedPackage.sets && (
        <>
          <div className="mainPackageDetails">
            <h4>
              {selectedPackage.category} {selectedPackage.title} Package
            </h4>
            <p className="pkgprice">Rs. {selectedPackage.price} </p>
            <p>This package has {selectedPackage.sets.length} sets</p>
          </div>
          {selectedPackage.sets.length > 0 ? (
            <>
              <div className="subPackages">
                <p>This package includes following sets:</p>
                <ol>
                  {selectedPackage.sets.map((subitem, subid) => (
                    <li key={subid}>{subitem.title}</li>
                  ))}
                </ol>
              </div>
            </>
          ) : (
            <div>There are no sets in the package</div>
          )}
          <div className="dashboard-contents">
            <div className="choosedigital-payment">
              <p>
                You can use the following Digital Payment Service to buy this
                package.
              </p>
              <div className="choosepayment">
                <Form className="formDetail">
                  {["radio"].map((type, id) => (
                    <div
                      className="mb-3 d-flex align-items-center paymentoptions"
                      key={id}
                    >
                      <Form.Check
                        label={imgText(esewa, "Esewa")}
                        name="Esewa"
                        type={type}
                        checked={checked === "Esewa"}
                        onChange={changeRadio}
                      />
                      <Form.Check
                        label={imgText(khalti, "khalti")}
                        name="Khalti"
                        checked={checked === "Khalti"}
                        onChange={changeRadio}
                        type={type}
                      />
                      <Form.Check
                        label={imgText(rewardpoint, "Reward Point")}
                        name="RewardPoint"
                        checked={checked === "RewardPoint"}
                        onChange={changeRadio}
                        type={type}
                      />
                    </div>
                  ))}
                </Form>
              </div>
              <div className="btn-action-container">
                <div className="goback btn btn-primary" onClick={goBack}>
                  Go Back
                </div>
                <button onClick={() => buyPackage()} className="btn btn-green">
                  Buy This Package
                </button>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default UBTPackageBuy;
