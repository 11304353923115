import React, { useState, useRef } from "react";
import { Modal } from "react-bootstrap";
import greatJob from "../../../Assets/img/ending.mp3";
import ThumbsUp from "../../../Assets/img/thumbsupgif.gif";
import { Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import ProgressProvider from "./ProgressBar/ProgressProvider";
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import { clearAllValue } from "../../../Features/SetsAPI/LocalSetSlice";
import { clearSession } from "../../../Features/SetsAPI/SetsSlice";
import { useNavigate, useLocation } from "react-router-dom";
import Loader from "../../Components/Loader/Loader";
const DashboardResults = () => {
  const audioRef = useRef(null);
  setTimeout(() => {
    if (audioRef !== null) {
      audioRef.current.play();
    }
  }, [1000]);
  let dispatch = useDispatch();
  let navigate = useNavigate();
  let testState = useSelector((state) => state.localset.questionState);
  let saveResult = useSelector((state) => state.localset.saveResult);
  let errorMessage = useSelector((state) => state.localset.errorMessage);
  let Loading = useSelector((state) => state.localset.isLoading);
  const [cancel, setCancel] = useState(false);
  const handleCloseCancel = () => {
    setCancel(false);
  };
  const handleShowCancel = () => setCancel(true);
  const cancelTest = () => {
    handleCloseCancel();
    dispatch(clearAllValue());
    dispatch(clearSession());
    navigate("/dashboard/ubt-test");
  };
  window.onpopstate = (e) => {
    if (testState.end === true) {
      navigate("/dashboard/dashboard-results", {
        replace: true,
      });
      handleShowCancel();
    }
  };
  return (
    <>
      {!Loading ? (
        <>
          {saveResult && (
            <div className="resultUpdate">
              <div className="d-flex justify-content-between align-items-center">
                <span>
                  <img src={ThumbsUp} alt="" /> You did a great job.
                  <audio autoPlay ref={audioRef} src={greatJob}></audio>
                </span>
                <div className="close text-end" onClick={handleShowCancel}>
                  <i className="bi bi-x-lg"></i>
                </div>
              </div>
              <div className="resultText text-center">
                <h2>Test completed!</h2>
                <p>
                  You have spent <span> {saveResult.time_spent}</span> minutes.
                </p>
              </div>
              <div className="note-container">
                <p>
                  <i class="bi bi-exclamation-circle"></i>
                  Congratulations! You have earned{" "}
                  {saveResult.correct_answers / 2} reward points.
                  {/* {saveResult.correct_answers / 2} Reward point has been added
                  into your account. */}
                </p>
              </div>
              <Row className="dashboard-progress dashboard-results-progress">
                <Col md={3} className="testscore attemtptedpercent">
                  <div className="prog">
                    <ProgressProvider
                      valueStart={0}
                      valueEnd={saveResult.attempted_questions}
                    >
                      {(value) => (
                        <CircularProgressbarWithChildren
                          minValue={0}
                          strokeWidth={2}
                          styles={buildStyles({
                            pathColor: `#00437b`,
                            textColor: "#f88",
                            trailColor: "#00437b1a",
                            backgroundColor: "#00437b1a",
                            textSize: "16px",
                            pathTransitionDuration: 1,
                          })}
                          maxValue={
                            parseInt(saveResult.attempted_questions) +
                            parseInt(saveResult.unsolved_questions)
                          }
                          value={value}
                        >
                          <div className="number test-percentage">
                            <p>
                              {saveResult.attempted_questions}/
                              {parseInt(saveResult.attempted_questions) +
                                parseInt(saveResult.unsolved_questions)}
                            </p>
                            <span>Attempted Questions</span>
                          </div>
                        </CircularProgressbarWithChildren>
                      )}
                    </ProgressProvider>
                  </div>
                </Col>
                <Col md={3} className="testscore unsolvedquestion">
                  <div className="prog">
                    <ProgressProvider
                      valueStart={0}
                      valueEnd={saveResult.unsolved_questions}
                    >
                      {(value) => (
                        <CircularProgressbarWithChildren
                          minValue={0}
                          strokeWidth={2}
                          styles={buildStyles({
                            pathColor: `#c7303c`,
                            textColor: "#f88",
                            textColor: "#f88",
                            trailColor: "#d6d6d6",
                            backgroundColor: "#00437b1a",
                            textSize: "16px",
                            pathTransitionDuration: 1,
                          })}
                          maxValue={
                            parseInt(saveResult.attempted_questions) +
                            parseInt(saveResult.unsolved_questions)
                          }
                          value={value}
                        >
                          <div className="number test-percentage">
                            <p>
                              {saveResult.unsolved_questions}/
                              {parseInt(saveResult.attempted_questions) +
                                parseInt(saveResult.unsolved_questions)}
                            </p>
                            <span>Unattempted Questions</span>
                          </div>
                        </CircularProgressbarWithChildren>
                      )}
                    </ProgressProvider>
                  </div>
                </Col>
                <Col md={3} className="testscore correctanswer">
                  <div className="prog">
                    <ProgressProvider
                      valueStart={0}
                      valueEnd={saveResult.correct_answers}
                    >
                      {(value) => (
                        <CircularProgressbarWithChildren
                          minValue={0}
                          strokeWidth={2}
                          styles={buildStyles({
                            trailColor: "#d6d6d6",
                            pathColor: `#00437b`,
                            textColor: "#f88",
                            trailColor: "#00437b1a",
                            backgroundColor: "#00437b1a",
                            textSize: "16px",
                            pathTransitionDuration: 1,
                          })}
                          maxValue={
                            parseInt(saveResult.attempted_questions) +
                            parseInt(saveResult.unsolved_questions)
                          }
                          value={value}
                        >
                          <div className="number test-percentage">
                            <p>
                              {saveResult.correct_answers}/
                              {parseInt(saveResult.attempted_questions) +
                                parseInt(saveResult.unsolved_questions)}
                            </p>
                            <span>Correct Answers</span>
                          </div>
                        </CircularProgressbarWithChildren>
                      )}
                    </ProgressProvider>
                  </div>
                </Col>
                <Col md={3} className="testscore scoreresult">
                  <div className="prog">
                    <ProgressProvider
                      valueStart={0}
                      valueEnd={saveResult.score}
                    >
                      {(value) => (
                        <CircularProgressbarWithChildren
                          minValue={0}
                          strokeWidth={2}
                          styles={buildStyles({
                            textColor: "#f88",
                            pathColor: `#00437b`,
                            trailColor: "#00437b1a",
                            backgroundColor: "#00437b1a",
                            textSize: "16px",
                            pathTransitionDuration: 1,
                          })}
                          maxValue={100}
                          value={value}
                        >
                          <div className="number test-percentage">
                            <p>
                              {saveResult.score}
                              /100
                            </p>
                            <span>Obtained Marks</span>
                          </div>
                        </CircularProgressbarWithChildren>
                      )}
                    </ProgressProvider>
                  </div>
                </Col>
              </Row>
              <div className="text-center  mt-4">
                <Link to="/dashboard/dashboard-viewall-results" className="btn">
                  View Result
                </Link>
              </div>
            </div>
          )}
          {errorMessage !== "" && (
            <>
              <div className="youhavenot-container">
                <h3>{errorMessage}</h3>
                <Link to="/dashboard/ubt-test" className="btn mt-2">
                  Take Another Test
                </Link>
              </div>
            </>
          )}
        </>
      ) : (
        <div className="bigLoading loading__mode">
          <Loader label="Submitting your result. Please Wait!" />
        </div>
      )}
      <Modal show={cancel} onHide={handleCloseCancel}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <p>Do you really want to close the result? You can’t undo it.</p>
          <button onClick={cancelTest} className="btn btn-secondary">
            Yes
          </button>
          <button onClick={handleCloseCancel} className="btn btn-primary ">
            No
          </button>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </>
  );
};
export default DashboardResults;
