import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import rewardPoint from "../../../../Assets/img/rp.png";
import { updateNotification } from "../../../../Features/NotificationApi/NotificationSlice";

const NotificationList = ({ item }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let token = useSelector((state) => state.auth.currentUser.token);
  const notificationType = {
    Reply: `Replied to your comment on`,
    Comment: "Commented on your discussion",
    Referral:
      "Your Referral Code was used and you have earned 10 reward points.",
  };
  const navigateTo = (item, id) => {
    if (item.model === "Discussion" || item.type === "Discussion") {
      navigate(`/dashboard/discussions/discussionsingle/${id}`, {
        state: id,
        replace: true,
      });
    }
    if (item.model === "Blog") {
      navigate(`/dashboard/blog/${item.slug}/${id}`, {
        replace: true,
      });
    }
  };
  const updateStatus = (id) => {
    dispatch(updateNotification({ token, id }));
  };
  return (
    <li
      onClick={() => updateStatus(item.id)}
      className={`${
        item.read === false && "notificationRead"
      } eachnotification`}
    >
      <div
        onClick={() =>
          navigateTo(
            item.for.hasOwnProperty("comment_on")
              ? item.for.comment_on
              : item.for,
            item.for.hasOwnProperty("comment_on")
              ? item.for.comment_on.id
              : item.for.id,
            item.id
          )
        }
      >
        {item.notification_type != "Referral" ? (
          <div className="notificationimg">
            <img src={item.by.avatar} alt={item.by.name} />
          </div>
        ) : (
          <div className="notificationimg">
            <img src={rewardPoint} alt="Reward Point earned" />
          </div>
        )}
        <div className="notificationContent">
          <p>
            <strong>{item.by.name}</strong>{" "}
            {notificationType[item.notification_type]}{" "}
            <strong>
              {item.for.hasOwnProperty("comment_on")
                ? item.for.comment_on.title
                : item.for.title}
            </strong>
          </p>
          <p className="notificationDate">{item.date}</p>
        </div>
      </div>
    </li>
  );
};

export default NotificationList;
