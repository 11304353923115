import React, { useEffect } from "react";
import { Container } from "react-bootstrap";
import { ReactComponent as PageBannerSVG } from "../../../Assets/img/big-lkwb.svg";
import { useSelector, useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import HTMLParser from "../../Components/HTMLParser";
import Loader from "../../Components/Loader/Loader";
import { fetchSingleBook } from "../../../Features/BookApi/singleBookslice";

const BooksSingle = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.home.isLoading);
  const { data: singleBookData, loading } = useSelector(
    (state) => state.singleBook
  );
  const { id } = useParams();

  useEffect(() => {
    dispatch(fetchSingleBook(id));
  }, [dispatch, id]);

  if (loading === "loading" || isLoading) {
    return (
      <div className="loading__mode bigLoading">
        <Loader />
      </div>
    );
  }

  return (
    <>
      <div className="pageBanner">
        <PageBannerSVG />
        <Container>
          <div className="page-wrap">
            {singleBookData && <h3>{singleBookData.bookName}</h3>}
          </div>
          <div className="custom-shape-divider-bottom-1678688493"></div>
        </Container>
      </div>
      <section className="section">
        <Container>
          <Link className="anchor-text goback" to="/books">
            Go Back
          </Link>

          {/* {singleBookData && singleBookData.chapters && (
            <div className="page-content">
              <Row className="setstitem-container align-items-center">
                <Col md={6} xs={12}>
                  <HTMLParser>{singleBookData.chapters.chapter}</HTMLParser>
                </Col>
              </Row>
            </div>
          )} */}

          <div className="books-items newBookData">
            <Row>
              {singleBookData &&
              singleBookData.chapters &&
              singleBookData.chapters.length > 0 ? (
                singleBookData.chapters.map((item, index) => (
                  <Col lg={6}>
                    <div key={index} className="chapter-item">
                      <div class="book__data">
                        <span class="chapter__number">
                          {item.chapter_number}
                        </span>

                        <div class="chapter-content">
                          <div>
                            <h5>{item.title}</h5>
                            <p>{item.chapter}</p>
                          </div>

                          <Link to={`/chapters/${item.id}?name=${item.title}&chapter=${id}`} className="btn btn-small">
                           Read More
                          </Link>
                        </div>
                      </div>

                      {/* <h3>
                    <a
                      href={item.url}
                      className="bookTitle"
                      target="_blank"
                      rel="noopener noreferrer"
                    ></a>
                  </h3> */}
                    </div>
                  </Col>
                ))
              ) : (
                <div className="youhavenot-container">
                  <p>No Content Available</p>
                </div>
              )}
            </Row>
          </div>
        </Container>
      </section>
    </>
  );
};

export default BooksSingle;
