import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { AllRoutes } from "../../../AllRoutes";
import { Modal } from "react-bootstrap";
export default function SideMenu({
  showModal,
  handleCloseModal,
  handleShowModal,
}) {
  const [isSideMenu, setSideMenu] = useState(false);
  const open = (isSideMenu) => {
    return setSideMenu(!isSideMenu);
  };
  const domeNode = useRef();
  const updateState = (event) => {
    if (event.target.classList.contains("sideMenu")) {
      setSideMenu(true);
      return;
    }
    if (domeNode.current.contains(event.target)) {
      return;
    }
    setSideMenu(false);
  };
  useEffect(() => {
    document.addEventListener("click", updateState);
    return () => {
      document.removeEventListener("click", updateState);
    };
  }, []);
  return (
    <div className="topBar">
      <div className="menuBar">
        <span
          ref={domeNode}
          className="navIcon"
          onClick={() => {
            open(isSideMenu);
          }}
        >
          <i className="bi bi-list"></i>
        </span>
      </div>
      <div className="sideMenu" style={{ left: isSideMenu ? "0" : "-265px" }}>
        <ul>
          {AllRoutes.homeRoute.map((item, id) => {
            if (item.inNavBar) {
              return (
                <li
                  key={id}
                  className={`${item.submenu ? "overlay-hassubmenu" : ""}`}
                >
                  <>
                    <Link to={item.link} id={item.label}>
                      {item.label}
                    </Link>
                  </>
                  {item.submenu && (
                    <ul className="overlay-submenu">
                      {item.submenu.map((subitem, id) => (
                        <li key={id}>
                          {subitem.type === "external" ? (
                            <a
                              target="_blank"
                              href={subitem.link}
                              id={subitem.label}
                            >
                              {subitem.label}
                            </a>
                          ) : (
                            <>
                              {subitem.type === "model" ? (
                                <>
                                  <a
                                    id={subitem.label}
                                    onClick={() =>
                                      handleShowModal(subitem.label)
                                    }
                                  >
                                    {subitem.label}
                                  </a>
                                  <Modal
                                    show={showModal[subitem.label]}
                                    onHide={() =>
                                      handleCloseModal(subitem.label)
                                    }
                                  >
                                    <Modal.Body>
                                      <p>
                                        To get the {subitem.label} of the class,
                                        please fill up this form
                                      </p>
                                      <a
                                        href={subitem.link}
                                        target="_blank"
                                        className="btn btn-small btn-green"
                                      >
                                        Register
                                      </a>
                                    </Modal.Body>
                                  </Modal>
                                </>
                              ) : (
                                <Link to={subitem.link} id={subitem.label}>
                                  {subitem.label}
                                </Link>
                              )}
                            </>
                          )}
                        </li>
                      ))}
                    </ul>
                  )}
                </li>
              );
            }
          })}
        </ul>
      </div>
    </div>
  );
}
