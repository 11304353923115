import React from "react";
import { useSelector } from "react-redux";
import Accordion from "react-bootstrap/Accordion";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { selectQuestion } from "../../../Features/SetsAPI/SetsSlice";
const QuestionsItem = ({ eventId, label, questions, setsid, startFrom }) => {
  let location = useLocation().pathname;
  const dispatch = useDispatch();
  let solvedQuestion = useSelector((state) => state.localset.solvedQuestion);
  const checkActiveQuickLink = (currentID) => {
    if (location.split("/")[5] == currentID) {
      return "active";
    } else {
      return "";
    }
  };
  const isAttempted = (itemid) => {
    let result;
    if (solvedQuestion) {
      if (solvedQuestion.length != 0) {
        for (let i = 0; i < solvedQuestion.length; i++) {
          if (itemid === solvedQuestion[i].id) {
            for (let j = 0; j < solvedQuestion[i].answers.options.length; j++) {
              if (
                solvedQuestion[i].answers.options[j].hasOwnProperty(
                  "userSelected"
                )
              ) {
                result = "attempted";
                break;
              }
            }
          }
        }
      }
    }
    return result;
  };
  return (
    <Accordion.Item eventKey={eventId}>
      <Accordion.Header>
        {label} {questions.length}
      </Accordion.Header>
      <Accordion.Body>
        <div className="questions-container">
          <ul className="questions-items">
            {questions.map((item, id) => (
              <li key={id}>
                <a
                  className={`${checkActiveQuickLink(item.id)} ${isAttempted(
                    item.id
                  )}`}
                  onClick={() => {
                    dispatch(selectQuestion(item.id));
                  }}
                  to={`/dashboard/ubt-test/ubt-test-questions/${setsid}/${item.id}`}
                >
                  {startFrom > 0 ? id + 1 * questions.length + 1 : id + 1}
                </a>
              </li>
            ))}
          </ul>
        </div>
      </Accordion.Body>
    </Accordion.Item>
  );
};

export default QuestionsItem;
