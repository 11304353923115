import React, { useEffect, useState } from "react";
import { Row, Col, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import ContactSideBar from "../../Components/ContactSideBar";
import rewardPoint from "../../../../Assets/img/checked.png";
import { Link } from "react-router-dom";
import { allPackage } from "../../../../Features/SetsAPI/SetsSlice";
const UBTTestInvoice = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  let token = useSelector((state) => state.auth.currentUser?.token);
  let invoiceDetails = useSelector((state) => state.set.invoiceDetails);
  const Done = () => {
    navigate("/dashboard/ubt-test/purchasedset", { replace: true });
  };
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  useEffect(() => {
    if (Object.keys(invoiceDetails).length != 0) {
      if (invoiceDetails.payment_method != "reward-point") {
        handleShow();
      }
    }
  }, []);
  const navigateTo = (id) => {
    dispatch(allPackage(token)).then((res) => {
      if (res.payload.status === 200) {
        navigate(`/dashboard/ubt-package/${id}`);
      }
    });
  };
  return (
    <>
      {invoiceDetails && (
        <Row>
          <Col md={8}>
            <div className="dashboard-contents payment-contents">
              <h2 className="paymentsuccesful">Payment Successful</h2>
              <p className="dashboard-lighttext">
                Your {invoiceDetails.type === "package" ? " Package" : " set"}{" "}
                has been added to your account.
              </p>
              <div className="payment-info">
                <ul>
                  <li>
                    <strong>Invoice No:</strong> {invoiceDetails.invoice_number}
                  </li>
                  <li>
                    <strong>Purchased date:</strong>{" "}
                    {invoiceDetails.purchase_date}
                  </li>
                  <li>
                    <strong>
                      Purchased
                      {invoiceDetails.type === "package" ? " Package" : " set"}:
                    </strong>
                    {invoiceDetails.type === "package"
                      ? invoiceDetails.package
                      : invoiceDetails.set}
                  </li>
                  <li>
                    <strong>Payment Method:</strong>
                    {invoiceDetails.payment_method}
                  </li>
                  <li>
                    <strong>Total Amount:</strong>
                    {invoiceDetails.amount}
                  </li>
                </ul>
              </div>
              <button className="btn btn-done" onClick={Done}>
                Done
              </button>
              {invoiceDetails.type === "package" ? (
                <button
                  onClick={() => navigateTo(invoiceDetails.package_id)}
                  className="btn ml-4"
                >
                  View Package
                </button>
              ) : (
                <Link
                  to={`/dashboard/ubt-test/ubt-course/${invoiceDetails.set_id}`}
                  className="btn ml-4"
                >
                  Start Test
                </Link>
              )}
            </div>
          </Col>
          <Col md={4} className="rightsidebar">
            <ContactSideBar />
          </Col>
          <Modal show={show} onHide={handleClose} centered>
            <Modal.Header closeButton>Congratulations</Modal.Header>
            <Modal.Body>
              <div className="purchaseSuccess-modal">
                <img src={rewardPoint} alt="Reward Point earned" />
                <p>Thank you for Purchasing</p>
                <h4>
                  {invoiceDetails.type
                    ? invoiceDetails.package
                    : invoiceDetails.set}
                </h4>
                <p>
                  You have Earned <strong>5</strong> points for signing with us!
                </p>
              </div>
            </Modal.Body>
          </Modal>
        </Row>
      )}
    </>
  );
};

export default UBTTestInvoice;
