import React from "react";
import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { textToSpeech } from "../../../Constants";
import { wordOfTheDay } from "../../../Features/Profile_management/profileSlice";
import HTMLParser from "../../Components/HTMLParser";
const WordDay = ({ type, from, classes, handleSelection }) => {
  const dispatch = useDispatch();
  let allWord = useSelector((state) => state.profile.wordOfTheDay);

  useEffect(() => {
    dispatch(wordOfTheDay());
  }, [dispatch]);

  let word =
    allWord && type === "grammar" ? allWord?.grammar : allWord?.dictionary;
  const textForSpeech = useRef(null);
  const textSpeechHandler = (e) => {
    e.preventDefault();
    if (textForSpeech != null) {
      textToSpeech(textForSpeech.current.textContent);
    }
  };
  return (
    <>
      {word && (
        <div onClick={() => handleSelection(word.id)}>
          <div className="grammar-container grammarDay">
            <div className={`${classes ? classes : ""} red-bg-sidebar`}>
              <h3>{type === "grammar" ? "Grammar" : "Word"} Of The Day</h3>
              <div className="word-headertext">
                <span ref={textForSpeech}>{word.word}</span>
                <a aria-label="volume up" href="/#" onClick={textSpeechHandler}>
                  <i className="bi bi-volume-up"></i>
                </a>
              </div>
              {type === "grammar" ? (
                <>
                  {word.description && <HTMLParser>{word.excerpt}</HTMLParser>}
                </>
              ) : (
                <>
                  {word.meaning && word.meaning.np_meaning &&  (
                    <p>Nepali: {word.meaning.np_meaning}</p>
                  )}
                  {word.meaning && word.meaning.en_meaning && (
                    <p>English: {word.meaning.en_meaning}</p>
                  )}
                </>
              )}

              <h6 className="readMore">
                Read More <i className="bi bi-arrow-right"></i>
              </h6>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default WordDay;
