

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import HTMLParser from '../../Components/HTMLParser';
import { ReactComponent as PageBannerSVG } from '../../../Assets/img/big-lkwb.svg';
import Loader from '../../Components/Loader/Loader';
import { fetchBooks } from '../../../Features/BookApi/Bookslice';

const Books = () => {
  const dispatch = useDispatch();
  const { books, loading, error } = useSelector((state) => state.books);

  useEffect(() => {
    dispatch(fetchBooks());
  }, [dispatch]);


  return (
    <>
      <div className="pageBanner">
        <PageBannerSVG />
        <Container> 
          <div className="page-wrap">
            <h3>Books</h3>
          </div>
          <div className="custom-shape-divider-bottom-1678688493">
            <svg
              data-name="Layer 1"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 1200 120"
              preserveAspectRatio="none"
            >
              <path
                d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z"
                className="shape-fill"
              ></path>
            </svg>
          </div>
        </Container>
      </div>
      <section className="section">
        <>
          {loading === 'loading' ? (
            <div className="loading__mode bigLoading">
              <Loader />
            </div>
          ) : (
            <>
              {books.length > 0 ? (
                <Container>
                  <Row className="g-4">   
                     {books.map((item, index) => (
                      <Col key={index} className="eachbooknote" lg={4} sm={2}>
                        <Link to={`/books/chapters/${item.id}`}>
                          <img
                            src={item.bookCoverImageURL}
                            alt={`Book cover for ${item.chapters}`}
                          />
                        </Link>
                        <h3>
                          <Link to={`/books/chapters/${item.id}`}>
                            {item.bookName}
                          </Link>
                        </h3>
                        {item.excerpt && (
                          <p>
                            <HTMLParser>{item.excerpt}</HTMLParser>
                          </p>
                        )}
                      </Col>
                    ))}
                  </Row>
                </Container>
              ) : (
                <Container>
                  <div className="dashboard-contents">
                    <p>{error ? `Error: ${error}` : 'Books and Packages are not available at the moment.'}</p>
                  </div>
                </Container>
              )}
            </>
          )}
        </>
      </section>
    </>
  );
};

export default Books;
