import React, { useState, useRef } from "react";
import DiscussionCommentAdd from "./DiscussionCommentAdd";
import { useDispatch, useSelector } from "react-redux";
import {
  commentOnDiscussion,
  updateComment,
  deleteComment,
  deleteCommentWithID,
  replyComment,
} from "../../../Features/DiscussionsAPI/DiscussionsSlice";
import DiscussionReplies from "./DiscussionReplies";
import { openLoginForm, openModal } from "../../../Features/ViewSlice";
const DiscussionComment = ({
  comment,
  replyState,
  discussionId,
  replies,
  type,
  replyStateHandle,
}) => {
  let currentUser = useSelector((state) => state.profile.profileInfo.name);
  let token = useSelector((state) => state.auth.currentUser.token);
  const dispatch = useDispatch();
  const [value, setValue] = useState("");
  //useState value
  const [commentValue, setCommentValue] = useState(""); //for comment texts
  const [isEditable, setEditable] = useState(false); //setting editable or not
  const [replyValue, setReplyValue] = useState(""); // reply value texts
  const [showUserMenu, setShowUserMenu] = useState(false); //showing user menu
  const [replyCommentState, setReplyState] = useState(false); // all comment state
  //useState value
  let userMenu = useRef(null);
  const detectClickOutside = () => {
    window.addEventListener("mousedown", (e) => {
      if (userMenu.current && !userMenu.current.contains(e.target)) {
        setShowUserMenu(false);
      }
    });
  };
  detectClickOutside();
  const addCommentOnDiscussion = () => {
    const formData = new FormData();
    formData.append("body", value);
    dispatch(
      commentOnDiscussion({ token, discussionid: discussionId, formData })
    );
    setValue("");
    setCommentValue(value);
  };
  const handleComment = (e) => {
    setValue(e.target.value);
  };
  const handleEditable = () => {
    setEditable(true);
    setCommentValue(comment.body);
  };
  const closeEditable = () => {
    setEditable(false);
    setCommentValue(" ");
  };
  const handleEditComment = (e) => {
    setCommentValue(e.target.value);
  };
  const updateCommentAction = (id) => {
    const formData = new FormData();
    formData.append("_method", "PUT");
    formData.append("body", commentValue);
    dispatch(updateComment({ token, formData, id }));
    closeCommentMenu();
  };
  const deleteCommentAction = (id) => {
    const formData = new FormData();
    formData.append("_method", "DELETE");
    dispatch(deleteComment({ token, formData, id }));
    dispatch(deleteCommentWithID(id));
    closeCommentMenu();
  };
  const showCommentMenu = () => {
    setShowUserMenu(true);
  };
  const closeCommentMenu = () => {
    setShowUserMenu(false);
  };
  const replyHandleChange = (e) => {
    setReplyValue(e.target.value);
  };
  const addReply = (id) => {
    const formData = new FormData();
    formData.append("body", replyValue);
    dispatch(replyComment({ token, formData, id }));
  };
  const replayStateHandle = () => {
    if (token) {
      setReplyState(!replyCommentState);
      setReplyValue("");
    } else {
      dispatch(openModal("loginView"));
      dispatch(openLoginForm());
    }
  };
  return (
    <>
      {replyState && type === "Comment" && (
        <DiscussionCommentAdd
          replyStateHandle={replyStateHandle}
          discussionId={discussionId}
          addComment={addCommentOnDiscussion}
          value={value}
          handleComment={(e) => handleComment(e)}
        />
      )}
      {comment && (
        <div className="comment-details" id={comment.id}>
          <div className="d-flex justify-content-between">
            <div className="d-flex flex-wrap userCommentLvlOne">
              <div className="person-image">
                <img src={comment.user_image} alt="" />
                <div>
                  <h5>{comment.user_name}</h5>
                </div>
              </div>
              <div className="commentDetails">
                <h5>
                  {comment.user_name} <span>{comment.display_date}</span>
                </h5>
                {isEditable ? (
                  <>
                    <input
                      type="text"
                      value={commentValue}
                      onChange={handleEditComment}
                    ></input>
                    <div className="commentDetails-options">
                      <div
                        onClick={() => {
                          closeEditable();
                          updateCommentAction(comment.id);
                        }}
                      >
                        <i className="bi bi-check2"></i>
                      </div>
                      <div
                        onClick={() => {
                          closeEditable();
                        }}
                      >
                        <i className="bi bi-x-lg"></i>
                      </div>
                    </div>
                  </>
                ) : (
                  <p>{comment.body}</p>
                )}
                {type === "replies" && (
                  <button onClick={replayStateHandle}>Reply</button>
                )}
                {replyCommentState && type === "replies" && (
                  <DiscussionCommentAdd
                    value={replyValue}
                    replyStateHandle={replayStateHandle}
                    handleComment={(e) => replyHandleChange(e)}
                    addComment={() => addReply(comment.id)}
                  />
                )}
                {replies && (
                  <div className="">
                    {replies.map((subitem, id) => (
                      <DiscussionReplies
                        key={id}
                        comment={subitem}
                        commentID={comment.id}
                      />
                    ))}
                  </div>
                )}
              </div>
            </div>
            {type === "replies" && comment.user_name === currentUser && (
              <div className="user-menu">
                <i
                  className="bi bi-three-dots-vertical"
                  onClick={showCommentMenu}
                ></i>
                {showUserMenu && (
                  <ul className="userMenuOption" ref={userMenu}>
                    {!isEditable ? (
                      <li
                        onClick={() => {
                          handleEditable();
                          closeCommentMenu();
                        }}
                      >
                        Edit Comment
                      </li>
                    ) : (
                      <li className="userMenuOption-list">
                        <div
                          onClick={() => {
                            closeEditable();
                            updateCommentAction(comment.id);
                          }}
                        >
                          <i className="bi bi-check2"></i>
                        </div>
                        <div
                          onClick={() => {
                            closeEditable();
                          }}
                        >
                          <i className="bi bi-x-lg"></i>
                        </div>
                      </li>
                    )}
                    <li onClick={() => deleteCommentAction(comment.id)}>
                      Delete Comment
                    </li>
                  </ul>
                )}
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default DiscussionComment;
