import React from "react";
import { useSelector } from "react-redux";
import Loader from "../Loader/Loader";
import { Modal } from "react-bootstrap";
const RegisterImgRefForm = ({ handleSubmit, handleRefCode, show }) => {
  let loading = useSelector((state) => state.auth.isLoading);
  return (
    <div>
      {loading && (
        <Modal show={show} backdrop="static" centered>
          <Modal.Body>
            <div className="pleasewait">
              <Loader label="Register in process" />
            </div>
          </Modal.Body>
        </Modal>
      )}
      {/* <div className="insertimage">
        <div className="image-container">
          {imageUrl ? (
            <img src={imageUrl} alt={imageUrl} />
          ) : (
            <img src={insertImage} alt={insertImage} />
          )}
          <label htmlFor="fileupload">
            <input
              id="fileupload"
              type="file"
              accept="image/*"
              name="myImage"
              onChange={(e) => {
                hanldeLogoChange(e);
                handleImage(e);
              }}
            />
            <i className="bi bi-cloud-plus"></i>
          </label>
        </div>
      </div> */}
      <div className="regid">
        <label htmlFor="referID">
          Refer Code
          <p>If you have any Refer Code, please insert below.</p>
          <input type="text" placeholder="Optional" onChange={handleRefCode} />
        </label>
      </div>
      <button className="btn" onClick={handleSubmit}>
        Register
      </button>
    </div>
  );
};

export default RegisterImgRefForm;
