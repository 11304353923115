import { BASE_URL } from "../../Constants";
import axios from "axios";

export const get_notification = async (data) => {
  let url = `${BASE_URL}/api/notifications`;
  let headers = {
    authorization: `Bearer ${data.token}`,
  };
  return await axios.get(url, {
    headers: headers,
  });
};
export const update_notifcation = async (data) => {
  let url = `${BASE_URL}/api/notifications/${data.id}/update`;
  let headers = {
    authorization: `Bearer ${data.token}`,
  };
  return await axios.post(url, data.id, {
    headers: headers,
  });
};
