import React from "react";
import Accordion from "react-bootstrap/Accordion";
const ResultsQuestionItem = ({
  label,
  questions,
  solvedQuestion,
  startFrom,
  goToSection,
  eventId,
}) => {
  const solvedArr = solvedQuestion;
  const checkIfCorrect = (ques) => {
    let result;
    for (let j = 0; j < solvedArr.length; j++) {
      let options = solvedArr[j].answers.options;
      if (solvedArr[j].id === ques.id) {
        for (let i = 0; i < options.length; i++) {
          if (options[i].hasOwnProperty("userSelected")) {
            if (options[i].userSelected === true) {
              if (options[i].is_correct === 1) {
                result = "correct";
                break;
              } else {
                result = "notcorrect";
                break;
              }
            }
          } else {
            result = "not_attempted";
            continue;
          }
        }
      }
    }
    return result;
  };
  return (
    <Accordion.Item eventKey={eventId}>
      <Accordion.Header>
        {label} {questions.length}
      </Accordion.Header>
      <Accordion.Body>
        <div className="questions-container">
          <ul className="questions-items">
            {questions.map((item, id) => (
              <li
                key={id}
                onClick={() => goToSection(item.id)}
                className={`results-questions ${checkIfCorrect(item)}`}
              >
                {startFrom > 0 ? id + 1 * questions.length + 1 : id + 1}
              </li>
            ))}
          </ul>
        </div>
      </Accordion.Body>
    </Accordion.Item>
  );
};

export default ResultsQuestionItem;
