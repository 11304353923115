import React, { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import SetsItem from "../../Components/SetsItem";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import Loader from "../../../Components/Loader/Loader";
import { allSets, allPackage } from "../../../../Features/SetsAPI/SetsSlice";
const UBT = () => {
  const dispatch = useDispatch();
  let token = useSelector((state) => state.auth.currentUser.token);
  let allSetslist = useSelector((state) => state.set.allSets);
  let allPackageList = useSelector((state) => state.set.packageCollection);
  let isLoading = useSelector((state) => state.set.isLoading);
  useEffect(() => {
    dispatch(allSets(token));
    dispatch(allPackage(token));
  }, [dispatch]);
  return (
    <>
      {!isLoading ? (
        <Row>
          <Col md={8}>
            {allSetslist && (
              <div className="ubt-container purchasedSets">
                <div className="boughtSet mt-1">
                  <div className="d-flex align-items-center justify-content-between mb-4 flex-wrap">
                    <h2 className="dashboard-page-title m-0">Purchased Set</h2>
                    <Link
                      to="/dashboard/ubt-test"
                      className="btn btn-small viewAllLink"
                    >
                      View All Sets
                    </Link>
                  </div>
                  {allSetslist.filter((item) => item.status === true).length >
                  0 ? (
                    <div className="setstitem-container">
                      {allSetslist
                        .filter((item) => item.status === true)
                        .sort(
                          (a, b) =>
                            new Date(b.bought_at) - new Date(a.bought_at)
                        )
                        .map((item, id) => (
                          <SetsItem key={id} details={item} />
                        ))}
                    </div>
                  ) : (
                    <div className="youhavenot-container">
                      <p>You have not bought any set.</p>
                    </div>
                  )}
                </div>
              </div>
            )}
          </Col>
          {allPackageList && (
            <Col md={4}>
              <div className="d-flex align-items-center justify-content-between mb-4 flex-wrap">
                <h2 className="dashboard-page-title m-0">Purchased Packages</h2>
              </div>
              {allPackageList.filter((item) => item.status === true).length >
              0 ? (
                allPackageList
                  .filter((item) => item.status === true)
                  .map((item, id) => (
                    <div
                      key={id}
                      className={`buyPackages ${item.category} ${
                        item.status === true && "packagebought"
                      }`}
                    >
                      <div>
                        <div className="text-start">
                          <p>{item.category} Package</p>
                          <h6>{item.title}</h6>
                        </div>
                        <div className="text-end">
                          <p className="packageCat">{item.sets.length} Sets</p>
                        </div>
                      </div>
                      <div className="pricepkg">
                        Rs.
                        <span>{item.price} </span>
                        {item.discount != 0 && <del>{item.base_price}</del>}
                      </div>
                      {!item.status ? (
                        <Link
                          to={`/dashboard/ubt-package-buy/${item.id}`}
                          className="buyBtn"
                        >
                          Buy package
                        </Link>
                      ) : (
                        <Link
                          to={`/dashboard/ubt-package/${item.id}`}
                          className="buyBtn"
                        >
                          View package
                        </Link>
                      )}
                    </div>
                  ))
              ) : (
                <div className="youhavenot-container">
                  <p>You have not bought any packages.</p>
                </div>
              )}
            </Col>
          )}
        </Row>
      ) : (
        <div className="loading__mode bigloading">
          <Loader />
        </div>
      )}
    </>
  );
};

export default UBT;
