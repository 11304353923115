import React, { useState } from "react";
import DiscussionComment from "./DiscussionComment";

const DiscussionSingleCommentItem = ({ comment }) => {
  return (
    <DiscussionComment
      type="replies"
      comment={comment}
      replies={comment.replies}
    />
  );
};

export default DiscussionSingleCommentItem;
