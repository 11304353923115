import AppRouter from "./AppRouter";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { get_Token } from "./API/Firebase";
import { onMessageListener } from "./API/Firebase";
import { useDispatch, useSelector } from "react-redux";
import { firebasePush } from "./Features/AuthSlice";
import { toast, ToastContainer } from "react-toastify";
import { saveDeviceToken } from "./Features/AuthSlice";
import { allAds } from "./Features/AdApi/AdSlice";
import { allProducts } from "./Features/HomeApi/HomeSlice";
function App() {
  // useEffect(() => {
  //   if (process.env.NODE_ENV === "production") {
  //     const handleContextmenu = (e) => {
  //       e.preventDefault();
  //     };
  //     document.addEventListener("contextmenu", handleContextmenu);
  //     return function cleanup() {
  //       document.removeEventListener("contextmenu", handleContextmenu);
  //     };
  //   }
  // }, []);
  const dispatch = useDispatch();
  let isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  let token = useSelector((state) => state.auth?.currentUser?.token);
  const [notification, setNotification] = useState({ title: "", body: "" });
  if (isLoggedIn) {
    get_Token().then((currentToken) => {
      dispatch(saveDeviceToken(currentToken));
      dispatch(
        firebasePush({ token, device_id: currentToken, device_type: "web" })
      );
    });
  }
  onMessageListener()
    .then((payload) => {
      setNotification({
        title: payload.notification.title,
        body: payload.notification.body,
      });
    })
    .catch((err) => {
      throw err;
    });
  const Message = () => {
    return (
      <div>
        <p className="notificationTitle">{notification.title}</p>
        <p className="notificationBody">{notification.body}</p>
      </div>
    );
  };
  if (isLoggedIn) {
    if (notification.title && notification.body) {
      toast.success(<Message />, {
        autoClose: 5000,
      });
      setNotification({ title: "", body: "" });
    }
  }
  useEffect(() => {
    dispatch(allAds());
    dispatch(allProducts());
  }, [dispatch]);
  return (
    <>
      <ToastContainer autoClose={500} />
      <AppRouter />
    </>
  );
}
export default App;
