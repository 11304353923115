import React, { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  allblogCateogory,
  blogInfo,
  blogcategorysingle,
} from "../../../Features/HomeApi/HomeSlice";
import MainBlog from "../../Components/Blog/MainBlog";
import Loader from "../../Components/Loader/Loader";
const DashboardBlogs = () => {
  const dispatch = useDispatch();
  let ad = useSelector(
    (state) =>
      state.ad.allAdPositions["sidebar-blog-detail-dashboard-306-x-250"]
  );
  let blog_Info = useSelector((state) => state.home.blogInfo);
  let isLoading = useSelector((state) => state.home.isLoading);
  let selectedBlogTitle = useSelector(
    (state) => state.home.selectedBlogCategory
  );
  let allblogcat = useSelector((state) => state.home.allblogcategory);
  useEffect(() => {
    dispatch(blogInfo());
    dispatch(allblogCateogory());
  }, [dispatch]);
  const changeCategory = (id) => {
    dispatch(blogcategorysingle({ id }));
  };
  return (
    <>
      {blog_Info && (
        <Row className="db-BlogContainer ">
          <Col md={8}>
            <h2 className="selectedBlog-cat">
              {selectedBlogTitle === " " ? "All Blogs" : selectedBlogTitle}
            </h2>
            <Row style={{ rowGap: 20, flexDirection: "column" }}>
              {!isLoading ? (
                <>
                  {blog_Info.map((item, id) => (
                    <Col key={id}>
                      <MainBlog
                        type="twocolumn"
                        blogFrom="dashboard"
                        blog={item}
                      />
                    </Col>
                  ))}
                </>
              ) : (
                <div className="loading__mode bigLoading">
                  <Loader></Loader>
                </div>
              )}
            </Row>
          </Col>
          <Col md={4} className="rightsidebar">
            <div className="latestblog sticky">
              <h4>All Categories</h4>
              <ul className="allcategory-container">
                <li class={`${selectedBlogTitle === " " && "active"}`}>
                  <div
                    onClick={() => {
                      dispatch(blogInfo());
                    }}
                  >
                    All Blogs
                  </div>
                </li>
                {allblogcat.map((item, id) => (
                  <li
                    key={id}
                    class={`${selectedBlogTitle === item.title && "active"}`}
                  >
                    <div
                      onClick={() => {
                        changeCategory(item.id);
                      }}
                    >
                      {item.title}
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </Col>
        </Row>
      )}
    </>
  );
};

export default DashboardBlogs;
