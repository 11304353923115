
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { BASE_URL } from "../../Constants";

export const fetchSingleVoculabary = createAsyncThunk('singleVoculabary/fetchSingleVoculabary', async (id) => {
  const url = `${BASE_URL}/api/home/all-vocabularies/${id}`;
  const response = await fetch(url);
  if (!response.ok) {
    throw new Error(`Failed to fetch single voculabary. Status: ${response.status}`);
  }
  const data = await response.json();
  return data.data;
});

const singleVoculabaryslice = createSlice({
  name: 'singleVoculabary',
  initialState: { data: {}, loading: 'idle', error: null },
  reducers: {


  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchSingleVoculabary.pending, (state) => {
        state.loading = 'loading';
      })
      .addCase(fetchSingleVoculabary.fulfilled, (state, action) => {
        state.loading = 'succeeded';
        state.data = action.payload;
      })
      .addCase(fetchSingleVoculabary.rejected, (state, action) => {
        state.loading = 'failed';
        state.error = action.error.message;
      });
  },
});

export default singleVoculabaryslice.reducer;
