import React, { useEffect } from "react";
import axios from "axios";
import { Container } from "react-bootstrap";
import HTMLParser from "../Components/HTMLParser";
import { useState } from "react";
import PageHeadings from "../Components/PageHeadings";
const Page = ({ url }) => {
  const [result, setResult] = useState("");
  const fetchUrl = async () => {
    try {
      await axios.get(url).then((res) => setResult(res.data.data));
    } catch (err) {
      throw err;
    }
  };
  useEffect(() => {
    fetchUrl();
  }, [url]);
  // console.log(result);
  return (
    <>
      {result && (
        <main>
          <PageHeadings item={result}>
          </PageHeadings>
          <Container fluid="sm">
            <div className="page-container">
              <div className="page-content">
                {/* <img src={single_blog.image} alt={single_blog.title} /> */}
                <HTMLParser>{result.description}</HTMLParser>
              </div>
            </div>
          </Container>
        </main>
      )}
    </>
  );
};

export default Page;
