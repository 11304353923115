import React, { useEffect, useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import ubtTest from "../../../../Assets/img/test-img.png";
import { Form } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import esewa from "../../../../Assets/img/Esewa.png";
import khalti from "../../../../Assets/img/Khalti.png";
import rewardpoint from "../../../../Assets/img/rewardpoint.png";
import Free from "../../../../Assets/img/Free.png";
import {
  eachSets,
  redirectToKhalti,
  prelogFn,
  buysetsWithRewardPoint,
} from "../../../../Features/SetsAPI/SetsSlice";
import HTMLParser from "../../../Components/HTMLParser";
import { claimSet } from "../../../../Features/SetsAPI/SetsSlice";
import { toast } from "react-toastify";
import Loader from "../../../Components/Loader/Loader";
import { host, mode, paymentUrl } from "../../../../Constants";
const UBTTestBuy = () => {
  let { id } = useParams();
  let navigate = useNavigate();
  const dispatch = useDispatch();
  let currentUserId = useSelector((state) => state.profile.profileInfo.id);
  let token = useSelector((state) => state.auth.currentUser.token);
  let selectedList = useSelector((state) => state.set.selectedSet);
  let isLoading = useSelector((state) => state.set.isLoading);
  const [checked, setChecked] = useState();
  const [show, setShow] = useState(false);
  const handleShow = () => {
    setShow(true);
  };
  const handleClose = () => {
    setShow(false);
  };
  useEffect(() => {
    dispatch(eachSets({ token, id }));
  }, [dispatch]);
  const randomIdGenerator = () => {
    return `s-${selectedList.id}-u-${currentUserId}-${Math.floor(
      Date.now() * Math.random() * 100
    )}`;
  };
  const esewaPayment = async () => {
    let form;
    let esewaURL = `${
      mode === "test" || mode === "dev"
        ? "https://uat.esewa.com.np/epay/main"
        : "https://esewa.com.np/epay/main"
    }`;
    const data = {
      amt: selectedList && selectedList.price,
      psc: 0,
      pdc: 0,
      txAmt: 0,
      tAmt: selectedList && selectedList.price,
      pid: randomIdGenerator(),
      scd: `${
        mode === "test" || mode === "dev" ? "EPAYTEST" : "NP-ES-LEARNKOREAN"
      }`,
      su: `${paymentUrl}/page/esewa_payment_success`,
      fu: `${paymentUrl}/esewa_payment_failed`,
      // su: "https://new.lkwb.com.np/page/esewa_payment_success",
      // su: "https://lkwb.com.np/page/esewa_payment_success",
      // fu: "https://new.lkwb.com.np/page/esewa_payment_failed",
      // fu: "https://lkwb.com.np/page/esewa_payment_failed",
    };
    const post = () => {
      form = document.createElement("form");
      form.setAttribute("method", "POST");
      form.setAttribute("action", esewaURL);
      for (var key in data) {
        var hiddenField = document.createElement("input");
        hiddenField.setAttribute("type", "hidden");
        hiddenField.setAttribute("name", key);
        hiddenField.setAttribute("value", data[key]);
        form.appendChild(hiddenField);
      }
      document.body.appendChild(form);
      form.submit();
    };
    dispatch(
      prelogFn({ token, gateway: "esewa", response: JSON.stringify(data) })
    ).then(() => {
      post();
    });
  };
  const buyKhaltiPayment = async () => {
    const data = {
      // return_url: "http://localhost:3000/page/khalti_payment/",
      // return_url: "https://lkwb.com.np/page/khalti_payment/",
      return_url: `${paymentUrl}/page/khalti_payment_success`,
      //website_url: "http://localhost:3000/",
      // website_url: "https://lkwb.com.np/",
      website_url: `${host}`,
      // amount: 11,
      // amount: 110,
      amount: selectedList && selectedList.price * 100,
      purchase_order_id: randomIdGenerator(),
      purchase_order_name: `${
        selectedList && selectedList.title + "-" + Date.now()
      }`,
    };
    dispatch(
      prelogFn({ token, gateway: "khalti", response: JSON.stringify(data) })
    ).then(() => {
      dispatch(redirectToKhalti({ data, token })).then((res) => {
        if (JSON.parse(res.payload.data).detail) {
          handleClose();
        }
      });
    });
  };
  const buySetHandle = (price) => {
    if (checked === "Esewa") {
      handleShow();
      esewaPayment();
    }
    if (checked === "Khalti") {
      buyKhaltiPayment();
      handleShow();
    }
    if (!checked) {
      toast.error("Please select at least one payment method.");
    }
    if (checked === "RewardPoint") {
      handleShow();
      dispatch(
        buysetsWithRewardPoint({
          token,
          id,
        })
      ).then((res) => {
        if (res.payload.status === 200) {
          toast.success(`${price} Reward points has been deducted.`);
          navigate(`/dashboard/ubt-test/ubt-test-invoice`, {
            replace: true,
          });
        }
      });
    }
  };
  const claimSetBtn = () => {
    dispatch(claimSet({ token, id })).then((res) => {
      if (res.payload.status === 200) {
        toast.success(`You have successfully Claimed ${selectedList.title}`);
        setTimeout(() => {
          navigate("/dashboard/ubt-test/purchasedset", { replace: true });
        }, 2000);
      }
    });
  };
  const goBack = () => {
    navigate("/dashboard/ubt-test", { replace: true });
  };
  const changeRadio = (e) => {
    setChecked(e.target.name);
  };
  const navigateback = () => {
    navigate("/dashboard/ubt-test", {
      replace: true,
    });
  };
  const imgText = (img, text) => {
    return (
      <div className="labelImg">
        <img src={img} />
        <p>{text}</p>
      </div>
    );
  };

  return (
    <>
      {!isLoading ? (
        <>
          {" "}
          <Modal show={show} backdrop="static" onHide={handleClose} centered>
            <Modal.Body>
              <div className="pleasewait">
                <Loader label="Initiating Your Payment" />
                <p>Please wait.</p>
              </div>
            </Modal.Body>
          </Modal>
          {selectedList && (
            <Row>
              <Col md={9}>
                <div className="dashboard-contents dashboard-buytest">
                  {/* <div className="discount-percent">
                <p>{selectedList.discount}%</p>
              </div> */}
                  <button className="btn btn-small" onClick={navigateback}>
                    Go Back
                  </button>
                  <div className="dashboard-contentshead">
                    <img src={ubtTest} alt="" />
                    <div className="packageName">
                      <h3 className="eachdashboard-title">Package Name</h3>
                      <p>
                        <HTMLParser>{selectedList.title}</HTMLParser>
                      </p>
                    </div>
                  </div>
                  <div className="eachdashboard-detail">
                    <h3 className="eachdashboard-title">Package Detail</h3>
                    <p>
                      <HTMLParser>
                        {selectedList.description
                          ? selectedList.description
                          : null}
                      </HTMLParser>
                    </p>
                  </div>
                  <div className="eachdashboard-detail">
                    <h3 className="eachdashboard-title">Package Price</h3>
                    {selectedList.price ? (
                      <>
                        <p>
                          Price: Rs. <span>{selectedList.price}</span>
                        </p>
                        <p>
                          Reward Point: <span>{selectedList.price}</span>
                        </p>
                        <p className="note-container">
                          <i class="bi bi-exclamation-circle"></i>
                          If You buy this set from reward point a total of{" "}
                          {selectedList.price} reward points will be deducted
                          from your reward points.
                        </p>
                      </>
                    ) : (
                      <p>Free</p>
                    )}
                  </div>
                </div>
                <div className="dashboard-contents">
                  <div className="choosedigital-payment">
                    <p>
                      *You can use the following Digital Payment Service to buy
                      this package.
                    </p>
                    <div className="choosepayment">
                      <Form>
                        {["radio"].map((type, id) => (
                          <div
                            className="mb-3 d-flex align-items-center paymentoptions"
                            key={id}
                          >
                            <Form.Check
                              label={imgText(esewa, "Esewa")}
                              name="Esewa"
                              disabled={
                                (!selectedList.price && true) ||
                                selectedList.canAccessSet
                              }
                              type={type}
                              checked={checked === "Esewa"}
                              onChange={changeRadio}
                            />
                            <Form.Check
                              label={imgText(khalti, "khalti")}
                              name="Khalti"
                              disabled={
                                (!selectedList.price && true) ||
                                selectedList.canAccessSet
                              }
                              checked={checked === "Khalti"}
                              onChange={changeRadio}
                              type={type}
                            />
                            <Form.Check
                              label={imgText(rewardpoint, "Reward Point")}
                              name="RewardPoint"
                              disabled={
                                (!selectedList.price && true) ||
                                selectedList.canAccessSet
                              }
                              checked={checked === "RewardPoint"}
                              onChange={changeRadio}
                              type={type}
                            />
                            <Form.Check
                              label={imgText(Free, "Free")}
                              name="Free"
                              disabled={
                                selectedList.price &&
                                true &&
                                !selectedList.canAccessSet
                              }
                              onChange={changeRadio}
                              checked={
                                checked === "Free" ||
                                (!selectedList.price && true) ||
                                (selectedList.canAccessSet && true)
                              }
                              type={type}
                            />
                          </div>
                        ))}
                      </Form>
                    </div>
                    <div className="btn-action-container">
                      <button className="btn btn-secondary" onClick={goBack}>
                        Cancel
                      </button>
                      {selectedList.price && !selectedList.canAccessSet ? (
                        <button
                          className="btn btn-green"
                          onClick={() => buySetHandle(selectedList.price)}
                        >
                          Buy Now 
                        </button>
                      ) : (
                        <button className="btn btn-green" onClick={claimSetBtn}>
                          Claim Now
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </Col>
              <Col md={3} className="rightsidebar"></Col>
            </Row>
          )}
        </>
      ) : (
        <div>
          <Loader></Loader>
        </div>
      )}
    </>
  );
};

export default UBTTestBuy;
