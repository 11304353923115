import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { singleService } from "../../../Features/HomeApi/HomeSlice";
import { Container } from "react-bootstrap";
import { ReactComponent as PageBannerSVG } from "../../../Assets/img/lkwb.svg";
import Loader from "../Loader/Loader";
import Discussions from "../../DashboardView/Container/DiscussionPage/Discussions";
import PageHeadings from "../PageHeadings";
const ModuleDiscussion = () => {
  const dispatch = useDispatch();
  let dictionaryItem = useSelector((state) => state.home.allDictionary);
  let selectedService = useSelector(
    (state) => state.home.selectedSingleService
  );
  let isLoading = useSelector((state) => state.home.homeModuleLoading);
  useEffect(() => {
    dispatch(singleService({ id: 5 }));
  }, [dispatch]);
  return (
    <>
      {dictionaryItem && (
        <>
          <PageHeadings item={selectedService} hasIcon="true" />
          <Container>
            {!isLoading ? (
              <div className="homediscussion-container home__dictionary">
                <Discussions from="home" />
              </div>
            ) : (
              <div className="loading__mode ">
                <Loader />
              </div>
            )}
          </Container>
        </>
      )}
    </>
  );
};

export default ModuleDiscussion;
