import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { buyPackage, buySets } from "../../../Features/SetsAPI/SetsSlice";
import Loader from "../../Components/Loader/Loader";
const EsewaSuccess = () => {
  let dispatch = useDispatch();
  let navigate = useNavigate();
  let token = useSelector((state) => state.auth.currentUser.token);
  let errors = useSelector((state) => state.set.buySetErrorhandle);
  let [searchParams] = useSearchParams();
  const [isValid, setIsValid] = useState("");
  useEffect(() => {
    const formdata = new FormData();
    for (const entry of searchParams.entries()) {
      const [param, value] = entry;
      formdata.append(param, value);
    }
    let paymentFor = formdata.get("oid").split("-")[0];
    let paymentForId = formdata.get("oid").split("-")[1];
    if (paymentFor === "s") {
      dispatch(
        buySets({
          token,
          formdata,
          id: paymentForId,
          for: paymentFor,
          provider: "esewa",
        })
      ).then((res) => {
        if (res.payload.status === 200) {
          setIsValid("valid");
        } else {
          if (res.payload.response.data.success === false) {
            setIsValid("notvalid");
          }
        }
      });
    } else {
      dispatch(
        buyPackage({
          token,
          formdata,
          id: paymentForId,
          for: paymentFor,
          provider: "esewa",
        })
      ).then((res) => {
        if (res.payload.status === 200) {
          setIsValid("valid");
        } else {
          if (res.payload.response.data.success === false) {
            setIsValid("notvalid");
          }
        }
      });
    }
  }, [dispatch]);

  useEffect(() => {
    if (isValid === "valid") {
      navigate(`/dashboard/ubt-test/ubt-test-invoice`, {
        replace: true,
      });
    }
    if (isValid === "notvalid") {
      navigate(`/page/esewa_payment_failed`, {
        replace: true,
        state: {
          errors: errors && errors,
        },
      });
    }
  }, [isValid]);
  return (
    <div className="esewaSuccess">
      <div className="text-center">
        <div className="bigLoading loading__mode">
          <Loader label="Verifying your payment"></Loader>
        </div>
      </div>
      {/* <input value={query} onChangeText={setQuery} /> */}
    </div>
  );
};
export default EsewaSuccess;
