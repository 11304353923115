import React from "react";
import ContactLinks from "../../Components/Footer/ContactLinks";
const ContactSideBar = () => {
  const contactLinks = {
    header: "Contact",
    address: "Lalitpur, Bagmati Province, Nepal",
    phoneNum: "+977 9849950096",
    mail: "bibek@learnkoreanwithbibek.com",
  };
  return (
    <div className="contactsidebar">
      <ContactLinks links={contactLinks} extraClass={"black"} />
    </div>
  );
};

export default ContactSideBar;
