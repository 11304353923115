import { Outlet } from "react-router-dom";
import DashboardHeader from "./views/DashboardView/Components/Header";
import { Row, Col, Container } from "react-bootstrap";
import DashboardNav from "./views/DashboardView/Components/DashboardMenu";
const DashboardRouter = () => {
  return (
    <div className="dashboard__body">
      <Container fluid>
        <Row>
          <Col lg="2" className="dashboard-nav-wrap" >
            <DashboardNav />
          </Col>
          <Col lg="10" className="dashboards-allcontentwrapper">
            <DashboardHeader />
            <div className="dashboards-allcontent">
              <Outlet />
            </div>
          </Col>
        </Row>
      </Container>
      <p className="copyright">&copy; Learn Korean with Bibek. All Rights Reserved</p>
    </div>
  );
};

export default DashboardRouter;
