import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams, useNavigate } from "react-router-dom";
import { buyPackage, buySets } from "../../../Features/SetsAPI/SetsSlice";
import Loader from "../../Components/Loader/Loader";
const KhaltiRedirect = () => {
  let dispatch = useDispatch();
  let navigate = useNavigate();
  let token = useSelector((state) => state.auth.currentUser.token);
  let errors = useSelector((state) => state.set.buySetErrorhandle);
  let [searchParams] = useSearchParams();
  const [isValid, setIsValid] = useState("");
  useEffect(() => {
    const newFormData = new FormData();
    for (const entry of searchParams.entries()) {
      const [param, value] = entry;
      newFormData.append(param, value);
    }
    const formdata = new FormData();
    formdata.append("pidx", newFormData.get("pidx"));
    formdata.append("poid", newFormData.get("purchase_order_id"));
    formdata.append("pon", newFormData.get("purchase_order_name"));
    formdata.append("amt", parseInt(newFormData.get("amount")) / 100);
    formdata.append("mobile ", newFormData.get("mobile"));
    formdata.append("txn_id ", newFormData.get("transaction_id"));
    let paymentFor = formdata.get("poid").split("-")[0];
    let paymentForId = formdata.get("poid").split("-")[1];
    if (paymentFor === "s") {
      dispatch(
        buySets({
          token,
          formdata,
          id: paymentForId,
          provider: "khalti",
          for: paymentFor,
        })
      ).then((res) => {
        if (res.payload.status === 200) {
          setIsValid("valid");
        } else {
          if (res.payload.response.data.success === false) {
            setIsValid("notvalid");
          }
        }
      });
    } else {
      dispatch(
        buyPackage({
          token,
          formdata,
          id: paymentForId,
          provider: "khalti",
          for: paymentFor,
        })
      ).then((res) => {
        if (res.payload.status === 200) {
          setIsValid("valid");
        } else {
          if (res.payload.response.data.success === false) {
            setIsValid("notvalid");
          }
        }
      });
    }
  }, [dispatch]);
  useEffect(() => {
    if (isValid === "valid") {
      navigate(`/dashboard/ubt-test/ubt-test-invoice`, {
        replace: true,
      });
    }
    if (isValid === "notvalid") {
      navigate(`/page/esewa_payment_failed`, {
        replace: true,
        state: {
          errors: errors && errors,
        },
      });
    }
  }, [isValid]);
  return (
    <div className="esewaSuccess">
      <div className="text-center">
        <div className="bigLoading loading__mode">
          <Loader label="Verifying your payment"></Loader>
        </div>
      </div>
    </div>
  );
};

export default KhaltiRedirect;
