import React from "react";

const BlogDate = (props) => {
  return (
    <div className="BlogDate">
      <p>
        <i className="bi bi-stopwatch"></i>
        {props.blogdate}
      </p>
      {/* <p>{props.category}</p> */}
    </div>
  );
};

export default BlogDate;
