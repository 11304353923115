import React from "react";
import { FacebookIcon, FacebookShareButton, WhatsappIcon, WhatsappShareButton, TwitterIcon, TwitterShareButton } from "react-share";
const ShareItem = () => {
  const shareUrl = window.location.href;

  return (
    <>
      <FacebookShareButton quote="Make your korean dream come true" hashtag="#learnkoreanwithbibek" url={shareUrl}>
        <FacebookIcon size={30} round={true} />
      </FacebookShareButton>
      <WhatsappShareButton url={shareUrl}>
        <WhatsappIcon size={30} round={true} />
      </WhatsappShareButton>
      <TwitterShareButton url={shareUrl}>
        <TwitterIcon size={30} round={true} />
      </TwitterShareButton>
    </>
  );
};

export default ShareItem;
