import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import {
  get_profile,
  update_profile,
  result_History,
  word_of_theday,
  delete_user,
} from "./ProfileApi";
const initialState = {
  isLoading: false,
  resultData: {},
  profileInfo: "",
  wordOfTheDay: {},
  profileError: [],
};

export const deleteUser = createAsyncThunk(
  "profile/deleteUser",
  async (data, { rejectWithValue }) => {
    try {
      return await delete_user(data);
    } catch (error) {
      if (!error.response) throw error;
      return rejectWithValue(error);
    }
  }
);
export const wordOfTheDay = createAsyncThunk(
  "profile/wordOfTheDay",
  async (token) => {
    try {
      return await word_of_theday(token);
    } catch (error) {
      return error;
    }
  }
);
export const resultHistory = createAsyncThunk(
  "profile/resultHistory",
  async (token) => {
    try {
      return await result_History(token);
    } catch (error) {
      return error;
    }
  }
);
export const getProfile = createAsyncThunk(
  "profile/getProfile",
  async (token) => {
    try {
      return await get_profile(token);
    } catch (error) {
      return error;
    }
  }
);
export const editProfile = createAsyncThunk(
  "profile/editProfile",
  async ({ token, formData }) => {
    try {
      return await update_profile({ token, formData });
    } catch (error) {
      return error;
    }
  }
);
const ProfileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    emptyProfile: (state) => {
      state.profileInfo = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(wordOfTheDay.pending, (state) => {
        state.wordOfTheDay = {};
      })
      .addCase(wordOfTheDay.fulfilled, (state, action) => {
        state.wordOfTheDay = action.payload.data.data;
      })
      .addCase(wordOfTheDay.rejected, (state) => {
        state.wordOfTheDay = {};
      })
      .addCase(resultHistory.fulfilled, (state, action) => {
        state.resultData = action.payload.data.data;
      })
      .addCase(getProfile.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getProfile.fulfilled, (state, action) => {
        state.isLoading = false;
        state.profileInfo =
          action.payload.data.data && action.payload.data.data;
      })
      .addCase(getProfile.rejected, (state, action) => {
        state.isLoading = false;
        state.profileInfo = "";
      })
      .addCase(editProfile.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(editProfile.fulfilled, (state, action) => {
        state.isLoading = false;
        state.profileInfo = action.payload.data.data;
      })
      .addCase(editProfile.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(deleteUser.rejected, (state, action) => {
        if (typeof action.payload.response.data.message === "object") {
          for (const [key, value] of Object.entries(
            action.payload.response.data.message
          )) {
            state.profileError.push({ key, value });
          }
          state.profileError.forEach((item) => {
            toast.error(item.value);
          });
        }
        if (typeof action.payload.response.data.message === "string") {
          toast.error(action.payload.response.data.message);
        }
      });
  },
});
export const { emptyProfile } = ProfileSlice.actions;
export default ProfileSlice.reducer;
