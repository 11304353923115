import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import fallbackimg from "../../../Assets/img/fallbackimg.png";
import { useNavigate } from "react-router-dom";
const DiscussionItem = ({ discussion }) => {
  let isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  let navigate = useNavigate();
  const navigateTo = (e, id) => {
    e.preventDefault();
    if (!isLoggedIn) {
      navigate(`/discussions/discussionsingle/${id}`, {
        replace: true,
      });
    } else {
      navigate(`/dashboard/discussions/discussionsingle/${discussion.id}`, {
        replace: true,
      });
    }
  };
  return (
    <div id={discussion.id}>
      <div className="d-flex flex-wrap">
        <div className="person-image">
          <img
            src={discussion.user_image ? discussion.user_image : fallbackimg}
            alt="test"
          />
          <div>
            <p>Updated {discussion.display_date}</p>
            <strong> by {discussion.user}</strong>
          </div>
        </div>

        <div className="userCommentDetail">
          <h3>
            <Link onClick={(e) => navigateTo(e, discussion.id)}>
              {discussion.title}
            </Link>
          </h3>
          <p>
            {discussion.description &&
              `${discussion.description.slice(0, 300)}...`}
          </p>
        </div>
      </div>
      <div className="userProfile">
        <p>Updated {discussion.display_date}</p>
        <strong> by {discussion.user}</strong>
        <span>
          <i className="bi bi-chat-fill"></i>{" "}
          {discussion.total_comments > 1 ? (
            <span> {discussion.total_comments} Comments </span>
          ) : (
            <span>{discussion.total_comments} Comment </span>
          )}
        </span>
      </div>
    </div>
  );
};

export default DiscussionItem;
