import React from "react";
import { Link } from "react-router-dom";
import ubtTest from "../../../Assets/img/test-img.png";

const SetsItem = ({ details, type, handleClick }) => {
  const navigateTo = (id) => {
    if (details) {
      if (details.canAccessSet) {
        if (details.status) {
          return `/dashboard/ubt-test/ubt-course/${id}`;
        } else {
          return `/dashboard/ubt-test/ubt-test-buy/${id}`;
        }
      } else {
        if (details.status) {
          return `/dashboard/ubt-test/ubt-course/${id}`;
        } else {
          return `/dashboard/ubt-test/ubt-test-buy/${id}`;
        }
      }
    }
  };
  const checkIfPremium = () => {
    if (details.canAccessSet || !details.price) {
      return "Claim Now";
    } else {
      return "Buy Now";
    }
  };
  const premiumPrice = (price) => {
    if (details.canAccessSet) {
      return <p>Free For Premium User</p>;
    } else {
      if (price) {
        return (
          <p>
            Price: Rs.
            <span>{details.price}</span>
            {details.discount != 0 && <del>{details.base_price}</del>}
          </p>
        );
      } else {
        return <p className="freetext">Free</p>;
      }
    }
  };
  return (
    <div
      className={`${
        details.status ? "item-bought" : "item-notbought"
      } setsitem-container ${details.isNew ? "newTest" : ""}`}
    >
      <div className="new__test">New</div>
      <div className="setsitem-header">
        <div className="pdficon">
          <img src={ubtTest} alt="" />
          <div className="sets-content">
            <div>
              {type === "home" ? (
                <h3 onClick={handleClick}>{details.title}</h3>
              ) : (
                <h3>
                  <Link to={`${navigateTo(details.id)}`}>{details.title}</Link>
                </h3>
              )}
              {/* {details.status && (
              <p className="ubt-buy">Sets {details.setNumber}</p>
            )} */}

              {premiumPrice(details.price)}
            </div>
            <div className="text-end">
              {type !== "home" ? (
                details.status ? (
                  <Link
                    to={`${navigateTo(details.id)}`}
                    className="btn btn-small"
                  >
                    Test Now
                  </Link>
                ) : (
                  <Link
                    to={`${navigateTo(details.id)}`}
                    className="btn btn-small"
                  >
                    {checkIfPremium()}
                  </Link>
                )
              ) : (
                <button onClick={handleClick} className="btn btn-small">
                  {checkIfPremium()}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SetsItem;
