import React from "react";
import { useDispatch } from "react-redux";
import { resetOTPToken } from "../../../Features/AuthSlice";
import { openLoginForm } from "../../../Features/ViewSlice";
const PasswordSuccess = () => {
  let dispatch = useDispatch();
  return (
    <div>
      <p>Password Reset Succesfully</p>
      <button
        onClick={() => {
          dispatch(resetOTPToken());
          dispatch(openLoginForm());
        }}
      >
        Proceed to login
      </button>
    </div>
  );
};

export default PasswordSuccess;
