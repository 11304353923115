import { BASE_URL } from "../../Constants";
import axios from "axios";

export const site_info = async () => {
  let url = `${BASE_URL}/api/home/site_info`;
  const res = await axios.get(url);
  return res;
};

export const banner_info = async () => {
  let url = `${BASE_URL}/api/home/banner`;
  const res = await axios.get(url);
  return res;
};

export const aboutus_info = async () => {
  let url = `${BASE_URL}/api/home/about`;
  const res = await axios.get(url);
  return res;
};

export const services_info = async () => {
  let url = `${BASE_URL}/api/home/services`;
  const res = await axios.get(url);
  return res;
};

export const single_service = async (data) => {
  let url = `${BASE_URL}/api/service/${data.id}`;
  const res = await axios.get(url);
  return res;
};

export const faq_info = async () => {
  let url = `${BASE_URL}/api/home/faqs`;
  const res = await axios.get(url);
  return res;
};

export const blog_info = async () => {
  let url = `${BASE_URL}/api/home/blogs`;
  const res = await axios.get(url);
  return res;
};

export const blog_single = async (data) => {
  let url = `${BASE_URL}/api/home/blogs/${data.id}`;
  let header = {
    Authorization: `Bearer ${data.token}`,
  };
  const res = await axios.get(url, {
    headers: header,
  });
  return res;
};

export const menu_info = async () => {
  let url = `${BASE_URL}/api/menus`;
  const res = await axios.get(url);
  return res;
};

export const home_UBT = async () => {
  let url = `${BASE_URL}/api/home/all-sets`;
  const res = await axios.get(url);
  return res;
};

export const home_Grammmar = async () => {
  let url = `${BASE_URL}/api/home/all-grammar`;
  const res = await axios.get(url);
  return res;
};

export const home_grammer_cat = async () => {
  let url = `${BASE_URL}/api/home/grammar-category`;
  const res = await axios.get(url);
  return res;
};

export const single_home_Grammmar = async (data) => {
  let url = `${BASE_URL}/api/home/grammar-word/${data.id}`;
  const res = await axios.get(url);
  return res;
};

export const allDictionary = async () => {
  let url = `${BASE_URL}/api/home/all-dictionaries`;
  const res = await axios.get(url);
  return res;
};
export const single_home_Dictionary = async (data) => {
  let url = `${BASE_URL}/api/home/dictionaries/${data.id}`;
  const res = await axios.get(url);
  return res;
};

export const home_Dictionary = async (data) => {
  let url = `${BASE_URL}/api/home/dictionaries?page=${data.page}`;
  const res = await axios.get(url);
  return res;
};

export const comment_on_blog = async (data) => {
  let url = `${BASE_URL}/api/blog/${data.id}/comment`;
  let header = {
    Authorization: `Bearer ${data.token}`,
  };
  const res = await axios.post(url, data.formData, {
    headers: header,
  });
  return res;
};

export const blog_category = async () => {
  let url = `${BASE_URL}/api/home/blogcategories`;
  const res = await axios.get(url);
  return res;
};
export const blog_category_single = async (data) => {
  let url = `${BASE_URL}/api/home/blogcategories/${data.id}`;
  const res = await axios.get(url);
  return res;
};

export const all_product = async () => {
  let url = `${BASE_URL}/api/products`;
  const res = await axios.get(url);
  return res;
};

// export const all_booknotes = async () => {
//   let url = `${BASE_URL}/api/products/book-notes`;
//   const res = await axios.get(url);
//   return res;
// };

export const single_booknotes = async (data) => {
  let url = `${BASE_URL}/api/products/book-notes/${data.id}`;
  const res = await axios.get(url);
  return res;
};
