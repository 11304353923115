import React, { useEffect } from "react";
import { Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { faqInfo } from "../../Features/HomeApi/HomeSlice";
import Accordion from "../Components/Accordion/AccordionItems";
const FAQPage = () => {
  const dispatch = useDispatch();
  let faq_List = useSelector((state) => state.home.faqInfo);
  useEffect(() => {
    dispatch(faqInfo());
  }, [dispatch]);

  // console.log(faq_List && faq_List.faq_info && faq_List);

  return (
    <>
      {faq_List && faq_List.faqs && faq_List.faq_info && (
        <section className="faq-section section-t mb-5">
          <Container>
            <Row className="section-head text-center">
              <h2>{faq_List.faq_info.header_text}</h2>
            </Row>
            <Row className="accordion-container">
              <Accordion mockFAQ={faq_List.faqs} />
            </Row>
          </Container>
        </section>
      )}
    </>
  );
};

export default FAQPage;
