import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import {
  singleDiscussion,
  clearSelectedDiscussions,
  deleteDiscussion,
  updateDiscussion,
} from "../../../../Features/DiscussionsAPI/DiscussionsSlice";
import { useNavigate } from "react-router";
import { commentOnDiscussion } from "../../../../Features/DiscussionsAPI/DiscussionsSlice";
import DiscussionCommentAdd from "../../../Components/DiscussionComponents/DiscussionCommentAdd";
import DiscussionSingleCommentItem from "../../../Components/DiscussionComponents/DiscussionSingleCommentItem";
import ShareModal from "../../../Components/Share/ShareModal";
import DiscussionAdd from "../../../Components/DiscussionComponents/DiscussionAdd";
import { Modal } from "react-bootstrap";
const DiscussionSingle = () => {
  let navigate = useNavigate();
  const { id } = useParams();
  let token = useSelector((state) => state.auth.currentUser.token);
  let selectedDiscussion = useSelector(
    (state) => state.discussion.selectedDiscussion
  );
  const dispatch = useDispatch();
  const [value, setValue] = useState("");
  useEffect(() => {
    dispatch(singleDiscussion({ token, id }));
  }, [dispatch]);
  const [editValue, setEditValue] = useState({
    title: "",
    description: "",
  });
  const [show, setShow] = useState(false);
  const clearTexts = () => {
    setEditValue({
      title: "",
      description: " ",
    });
  };
  const handleClose = () => {
    setShow(false);
    clearTexts();
  };
  const handleShow = () => setShow(true);
  const [shareShare, setShowShare] = useState(false);
  const handleShowShare = () => setShowShare(true);
  const handleCloseShare = () => setShowShare(false);
  const handleChange = (e) => {
    setEditValue({ ...editValue, [e.target.name]: e.target.value });
  };

  const handleComment = (e) => {
    setValue(e.target.value);
  };
  const hanldeEdit = () => {
    handleShow();
    setEditValue({
      title: selectedDiscussion.title,
      description: selectedDiscussion.description,
    });
  };
  const submitEdit = () => {
    const formdata = new FormData();
    formdata.append("title", editValue.title);
    formdata.append("description", editValue.description);
    formdata.append("_method", "PUT");
    dispatch(updateDiscussion({ id, token, formdata }));
    handleClose();
  };

  const addCommentOnDiscussion = (discussionId) => {
    const formData = new FormData();
    formData.append("body", value);
    dispatch(
      commentOnDiscussion({ token, discussionid: discussionId, formData })
    );
    setValue("");
  };
  const navigateBack = () => {
    navigate("/dashboard/discussions", {
      replace: true,
    });
  };
  const deleteDiscussionFn = (id) => {
    const formdata = new FormData();
    formdata.append("_method", "DELETE");
    dispatch(deleteDiscussion({ id, formdata, token })).then((res) => {
      if (res.payload.status === 200) {
        navigateBack();
      }
    });
  };
  const getCreatedAt = (date) => {
    let newDate = new Date(date);
    return `${newDate.toLocaleDateString()} at ${newDate.getUTCHours()}:${newDate.getUTCMinutes()}:${newDate.getUTCSeconds()}`;
  };
  return (
    <>
      <button
        onClick={() => {
          dispatch(clearSelectedDiscussions());
          navigateBack();
        }}
      >
        Go Back
      </button>
      {Object.keys(selectedDiscussion).length > 0 && (
        <div>
          <Row>
            <Col md={9}>
              <div className="single__comment">
                <div>
                  <div className="d-flex flex-wrap">
                    <div className="person-image">
                      <img src={selectedDiscussion.user_image} alt="test" />
                    </div>

                    <div className="userCommentAll">
                      <>
                        <h3>{selectedDiscussion.title}</h3>
                        <div className="userProfile">
                          <strong>Posted By {selectedDiscussion.user}</strong>
                          <p>
                            on {getCreatedAt(selectedDiscussion.created_at)}
                          </p>
                        </div>
                        <div className="userMenu">
                          <span
                            className="bi bi-share-fill"
                            onClick={handleShowShare}
                          >
                            <span>Share</span>
                          </span>

                          <ShareModal
                            show={shareShare}
                            handleClose={handleCloseShare}
                          />
                          {selectedDiscussion.isDeletable && (
                            <>
                              <span
                                className="bi bi-pen-fill"
                                onClick={hanldeEdit}
                              >
                                <span>Edit</span>
                              </span>
                              <span
                                className="bi bi-trash-fill"
                                onClick={() =>
                                  deleteDiscussionFn(selectedDiscussion.id)
                                }
                              >
                                <span>Delete</span>
                              </span>
                            </>
                          )}
                        </div>
                      </>

                      <div className="contentArea">
                        <p>{selectedDiscussion.description}</p>
                      </div>
                      <div className="addComment">
                        <DiscussionCommentAdd
                          discussionId={selectedDiscussion.id}
                          addComment={() =>
                            addCommentOnDiscussion(selectedDiscussion.id)
                          }
                          value={value}
                          handleComment={(e) => handleComment(e)}
                        />
                        {selectedDiscussion.comments &&
                          selectedDiscussion.comments.map((item, id) => (
                            <DiscussionSingleCommentItem
                              comment={item}
                              key={id}
                            />
                          ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col md={3}></Col>
          </Row>
        </div>
      )}
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Edit Discussion</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="">
            <div className="postDiscussion">
              <DiscussionAdd
                type="edit"
                clearTextFields={handleClose}
                descriptionValue={editValue.description}
                titleValue={editValue.title}
                handleChange={handleChange}
                createDiscussion={submitEdit}
              />
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default DiscussionSingle;
