import React from "react";
import ReactDOM from "react-dom/client";
import "react-toastify/dist/ReactToastify.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import 'react-circular-progressbar/dist/styles.css';
import "./Assets/styles/styles.css";
import "./Assets/styles/responsive.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import store from "./store";
import version from "../package.json";
import CacheBuster from "react-cache-buster";

let persistor = persistStore(store);
const root = ReactDOM.createRoot(document.getElementById("root"));
const isProduction = process.env.NODE_ENV === "production";
root.render(
  <CacheBuster
    currentVersion={version.version}
    isEnabled={isProduction} //If false, the library is disabled.
    isVerboseMode={false} //If true, the library writes verbose logs to console.
    //loadingComponent={<BigLoader />} //If not pass, nothing appears at the time of new version check.
    metaFileDirectory={"."} //If public assets are hosted somewhere other than root on your server.
    onCacheClear={(refreshCacheAndReload) => {
      localStorage.clear();
      refreshCacheAndReload();
    }}
  >
    <BrowserRouter>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <App />
        </PersistGate>
      </Provider>
    </BrowserRouter>
  </CacheBuster>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
