import React from "react";
import { Form, Button } from "react-bootstrap";
const DiscussionAdd = ({
  handleChange,
  createDiscussion,
  titleValue,
  clearTextFields,
  descriptionValue,
  type,
}) => {
  const limit = 200;
  
  return (
    <>
      <Form.Group className="mb-3 discussionFields">
        <Form.Label>
          <input
            type="text"
            name="title"
            placeholder='Discussion Title'
            onChange={handleChange}
            value={titleValue}
            maxLength={limit}
          ></input>
          <p>
            <span>{titleValue ? titleValue.length : 0}</span>/{limit}
          </p>
        </Form.Label>
        {titleValue && titleValue.length === limit && (
          <p>You have Reached Maximum length</p>
        )}
        <textarea
          onChange={handleChange}
          value={descriptionValue}
          name="description"
          rows="100"
          cols="5"
          placeholder="What are your thoughts?"
        ></textarea>
      </Form.Group>
      <Form.Group className="text-end cta__btns mb-4">
        <Button
          type="submit"
          className="btn btn-secondary btn-small"
          onClick={clearTextFields}
        >
          Cancel
        </Button>
        <Button
          type="submit"
          className="btn btn-small"
          onClick={createDiscussion}
        >
          {type === "edit" ? "Edit" : "Add"} Discussion
        </Button>
      </Form.Group>
    </>
  );
};

export default DiscussionAdd;
