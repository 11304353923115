import React, { useRef } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Apple from "../../Assets/img/apple.png";
import splashScreen from "../../Assets/img/splash_lkwb-two.png";
import Google from "../../Assets/img/Google-app.png";
import { Link } from "react-router-dom";

const DownloadApp = () => {
  const downloadApp_Section = useRef(null);

  // if (downloadApp_Section != null) {
  //   let observer = new IntersectionObserver((el) => {
  //     if (el[0].isIntersecting) {
  //       downloadApp_Section.current.classlist.add("active");
  //     }
  //   });
  //   observer.observe(downloadApp_Section.current)
  // }

  return (
    // <section ref={downloadApp_Section} className="download__app section">
    //   <Container>
    //     <div className="download-wrapper">
    //       <Row className="align-items-center">
    //         <Col md={6}>
    //           <div className="download--icon">
    //             <img src={splashScreen} alt="" />
    //           </div>
    //         </Col>
    //         <Col md={6}>
    //           <div className="section-head">
    //             <span>Download App</span>
    //             <h2>Download our app to get started today!</h2>
    //           </div>
    //           <div className="download-content">
    //             <p>End-to-end payments and financial management in a single solution.</p>
    //             <div className="downloadBtn">
    //               <span>Coming Soon</span>
    //               <div>
    //                 <a href="/#" target="_blank" rel="noopener noreferrer">
    //                   <img src={Apple} alt="" />
    //                 </a>
    //                 <a href="/#" target="_blank" rel="noopener noreferrer">
    //                   <img src={Google} alt="" />
    //                 </a>
    //               </div>
    //             </div>
    //           </div>
    //         </Col>
    //       </Row>
    //     </div>
    //   </Container>
    // </section>
    <section ref={downloadApp_Section} className="download__app section">
      <div className="custom-shape-divider-top-1673324135">
        <svg
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1200 120"
          preserveAspectRatio="none"
        >
          <path
            d="M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z"
            className="shape-fill"
          ></path>
        </svg>
      </div>
      <div className="downloadGradient"></div>
      <div className="downloadWrapper">
        <div className="downloadIcon">
          <img src={splashScreen} alt="" width="601" />
        </div>
      </div>
      <div className="downloadApp">
        <Container>
          <Row>
            <Col md={6}>
              <div className="section-head">
                <span>Download App</span>
                <h2>Download our app to get started today!</h2>
              </div>
              <div className="download-content">
                <p>Make your 'Korea dream' come true.</p>
                <div className="downloadBtn">
                  {/* <span>Coming Soon</span> */}
                  <div>
                    <a
                      aria-label="apple icon"
                      href="https://apps.apple.com/us/app/learn-korean-with-bibek/id6447615461"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src={Apple} alt="" />
                    </a>
                    <a
                      href="https://play.google.com/store/apps/details?id=com.genesiswtech.lkwb"
                      target="_blank"
                      aria-label="google icon"
                      rel="noopener noreferrer"
                    >
                      <img src={Google} alt="" />
                    </a>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </section>
  );
};

export default DownloadApp;
