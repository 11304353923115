// REACT_APP_GOOGLEKEY = 221508455534-plhsvn96h7kh0q33ke90mle8e43agh9h.apps.googleusercontent.com
// REACT_APP_FACEBOOKKEY = 1341775266585111
// REACT_APP_FIREBASEKEY = AIzaSyBHMb5umRK_g_E19xQIEfz1ORGcLHWcWCY
// REACT_APP_AUTHDOMAIN = lkwb-76712.firebaseapp.com
// REACT_APP_PROJECTID = lkwb-76712
// REACT_APP_STORAGEBUCKET = lkwb-76712.appspot.com
// REACT_APP_MESSAGINSENDERID = 784898974527
// REACT_APP_APPID = 1:784898974527:web:1899f400b77fabb8e2698a
// REACT_APP_MEASUREMENTID = G-LSGZTE3Z6S
// REACT_APP_PUBLICKEY= BJfZfUaaRFj2zduDQByCtJgbXJM2SAtYXkh3cHXW1FWVUYujKT8yzaxdJoKHtgKdAgg6QJJHwTf6ty3V1W_2S4U
// REACT_APP_LOCALAPI = http://192.168.100.68:8080
// REACT_APP_BASEAPI = https://admin.lkwb.com.np
// REACT_APP_DEVAPI = https://dev.lkwb.com.np
// REACT_APP_LOCALMODE = http://localhost:3000/page/
// REACT_APP_DEVMODE = https://new.lkwb.com.np/page/
// REACT_APP_LIVEMODE = https://lkwb.com.np/page/
// REACT_APP_ENVIRONMENT = live
// import FbPage from "./Assets/img/fb-page.png";
// import FbGroup from "./Assets/img/fb-group.png";

// export const BASE_URL = "http://13.214.226.138";
// console.log(process.env.REACT_APP_LOCALAPI);
// export const BASE_URL =
//   process.env.NODE_ENV === "production"
//     ? process.env.REACT_APP_BASEAPI
//     : process.env.REACT_APP_LOCALAPI;
export const mode = process.env.REACT_APP_ENVIRONMENT;
const getHost = () => {
  if (mode === "test") {
    return process.env.REACT_APP_LOCALMODE;
  }
  if (mode === "dev") {
    return process.env.REACT_APP_DEVMODE;
    // return process.env.REACT_APP_LOCALMODE;
  }
  if (mode === "live") {
    return process.env.REACT_APP_LIVEMODE;
  }
};
const getAPI = () => {
  if (mode  ==="test") {
    return process.env.REACT_APP_LOCALAPI;
  }
  if (mode === "dev") {
    return process.env.REACT_APP_DEVAPI;
  }
  if (mode === "live") {
    return process.env.REACT_APP_BASEAPI;
  }
};
const getPaymentURL = () => {
  if (mode === "live") {
    return process.env.REACT_APP_BASEAPI;
  }
  if (mode === "dev") {
    return process.env.REACT_APP_DEVAPI;
  }
};
export const host = getHost();
export const paymentUrl = getPaymentURL();
export const BASE_URL = getAPI();
// export const BASE_URL = process.env.REACT_APP_BASEAPI;
export const shuffleArray = (array) => {
  const newArr = [...array];
  for (var i = newArr.length - 1; i > 0; i--) {
    var j = Math.floor(Math.random() * (i + 1));
    var temp = newArr[i];
    newArr[i] = newArr[j];
    newArr[j] = temp;
  }
  return newArr;
};

export const textToSpeech = (textValue) => {
  const msg = new SpeechSynthesisUtterance();
  msg.text = textValue;
  msg.voice = window.speechSynthesis
    .getVoices()
    .filter((item) => item.lang === "ko-KR")[0];
  msg.rate = 1;
  msg.lang = "ko-KR";
  msg.pitch = 1;
  window.speechSynthesis.speak(msg);
};
export const socialOBJKEY = {
  facebook_page: {
    icon: <i className="bi bi-facebook"></i>,
  },
  // facebook_group: {
  //   icon: <img src={FbGroup} alt="" />,
  // },
  youtube: {
    icon: <i className="bi bi-youtube"></i>,
  },
  whatsapp: {
    icon: <i className="bi bi-whatsapp"></i>,
  },
  tiktok: {
    icon: <i className="bi bi-tiktok"></i>,
  },
  instagram: {
    icon: <i className="bi bi-instagram"></i>,
  },

  viber: {
    icon: <viberIcon />,
  },
};

export const CopyTextToClipBoard = (e, text) => {
  let newText = text.replace("/dashboard", "");
  e.preventDefault();
  navigator.clipboard.writeText(newText);
};

export const CreateExcert = (text) => {
  let endingText = "...";
  let limit = 160;
  if (text != null) {
    if (text.length > limit) {
      return text.substring(0, limit) + endingText;
    } else {
      return text;
    }
  }
};
