import React from "react";
import { Link } from "react-router-dom";
const SocialLinks = ({ links }) => {
  return (
    <>
      <h2 className="footerhead">{links.header}</h2>
      <ul className="socialMedia">
        {links.links.map((item, id) => {
          if (item.icon) {
            return (
              <li key={id}>
                <a href={item.link} aria-label={item.link} target="_blank">
                  {item.icon.icon}
                </a>
              </li>
            );
          }
        })}
      </ul>
    </>
  );
};

export default SocialLinks;
