

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { url } from './BookApi';
export const fetchBooks = createAsyncThunk('books/fetchBooks', async () => {
 
  const response = await fetch(url);
  if (!response.ok) {
    throw new Error(`Failed to fetch books. Status: ${response.status}`);
  }
  const data = await response.json();
  return data.data;
});

const Bookslice = createSlice({
  name: 'books',
  initialState: { books: [], loading: 'idle', error: null },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchBooks.pending, (state) => {
        state.loading = 'loading';
      })
      .addCase(fetchBooks.fulfilled, (state, action) => {
        state.loading = 'succeeded';
        state.books = action.payload;
      })
      .addCase(fetchBooks.rejected, (state, action) => {
        state.loading = 'failed';
        state.error = action.error.message;
      });
  },
});

export default Bookslice.reducer;

