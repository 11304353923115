import React from "react";
import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { homeDictionary } from "../../../Features/HomeApi/HomeSlice";
import { Container } from "react-bootstrap";
import { ReactComponent as PageBannerSVG } from "../../../Assets/img/lkwb.svg";
import { singleService } from "../../../Features/HomeApi/HomeSlice";
import DictionaryPage from "../../DashboardView/Container/DictionaryPage/DictionaryPage";
import { useNavigate } from "react-router";
import { openModal, openLoginForm } from "../../../Features/ViewSlice";
import PageHeadings from "../PageHeadings";

const ModuleDictionary = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let token = useSelector((state) => state.auth?.currentUser?.token);
  let dictionaryItem = useSelector((state) => state.home.allDictionary);
  let selectedService = useSelector(
    (state) => state.home.selectedSingleService
  );
  let errorHandle = useSelector((state) => state.home.errorHandle);
  const [page, setPage] = useState(1);
  useEffect(() => {
    dispatch(singleService({ id: 3 }));
  }, [dispatch]);
  useEffect(() => {
    if (errorHandle) {
      return;
    }
    dispatch(homeDictionary({ page: page }));
  }, [dispatch, page]);
  const openLogin = (e) => {
    e.preventDefault();
    dispatch(openModal("loginView"));
    dispatch(openLoginForm());
  };
  const goToDashboard = () => {
    navigate("/dashboard");
  };
  return (
    <>
      {dictionaryItem && (
        <>
          <PageHeadings item={selectedService} hasIcon="true" />
          <Container>
            {/* <div className="viewall">
              {token ? <p>Go To Dashboard</p> : <p>You must be logged</p>}
              <p>In order to view all dictionary.</p>
              {token ? (
                <button className="btn btn-small" onClick={goToDashboard}>
                  Go To Dashboard
                </button>
              ) : (
                <button className="btn btn-small" onClick={openLogin}>
                  Login
                </button>
              )}
            </div> */}
            <div className="grammar-container home__dictionary">
              <DictionaryPage
                setPage={setPage}
                datas={dictionaryItem}
                type="home"
              />
            </div>
          </Container>
        </>
      )}
    </>
  );
};

export default ModuleDictionary;
