import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { allPackage } from "../../../../Features/SetsAPI/SetsSlice";
import { Row, Col } from "react-bootstrap";
const UBTPackages = ({ type }) => {
  const dispatch = useDispatch();
  let allPackageCol = useSelector((state) => state.set.packageCollection);
  let testState = useSelector((state) => state.localset.questionState);
  let token = useSelector((state) => state.auth.currentUser.token);
  useEffect(() => {
    if (!testState.start) {
      dispatch(allPackage(token));
    }
  }, [dispatch]);
  return (
    <Row
      className={`packages-container ${type === "columns" ? "columns" : "row"}`}
    >
      {allPackageCol && (
        <>
          {allPackageCol.map((item, id) => (
            <Col key={id} md={type === "columns" ? 4 : 12}>
              <div
                className={`buyPackages ${item.category} ${
                  item.status === true && "packagebought"
                }`}
              >
                <div>
                  <div className="text-start">
                    <p>{item.category} Package</p>
                    <h6>{item.title}</h6>
                  </div>
                  <div className="text-end">
                    <p className="packageCat">{item.sets.length} Sets</p>
                  </div>
                </div>
                <div className="pricepkg">
                  Rs.
                  <span>{item.price} </span>
                  {item.discount != 0 && <del>{item.base_price}</del>}
                </div>
                {!item.status ? (
                  <Link
                    to={`/dashboard/ubt-package-buy/${item.id}`}
                    className="buyBtn"
                  >
                    Buy package
                  </Link>
                ) : (
                  <Link
                    to={`/dashboard/ubt-package/${item.id}`}
                    className="buyBtn"
                  >
                    View package
                  </Link>
                )}
              </div>
            </Col>
          ))}
        </>
      )}
    </Row>
  );
};

export default UBTPackages;
