import React from "react";
import { useRef } from "react";
import { Row, Col } from "react-bootstrap";
// import applelogin from "../../../Assets/img/apple-logo.png";
import facebooklogin from "../../../Assets/img/facebook-logo.png";
import googlelogin from "../../../Assets/img/google.png";
import {
  openViewForgotPassword,
  openModal,
  openRegisterForm,
} from "../../../Features/ViewSlice";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { LoginSocialGoogle, LoginSocialFacebook } from "reactjs-social-login";
import Loader from "../Loader/Loader";
import { closeModal } from "../../../Features/ViewSlice";
import { useNavigate } from "react-router";
import { loginWithSocial, loadingStart } from "../../../Features/AuthSlice";
const LoginForm = ({ handleSubmit, handleChange, handleCheckbox }) => {
  // const REDIRECT_URI = "https://localhost:3000";
  const REDIRECT_URI = "https://lkwb.com.np/dashboard";
  let inputPassword = useRef(null);
  let navigate = useNavigate();
  let dispatch = useDispatch();
  let isLoading = useSelector((state) => state.auth.isLoading);
  const togglePasswordShow = () => {
    inputPassword.current.getAttribute("type") === "password"
      ? inputPassword.current.setAttribute("type", "text")
      : inputPassword.current.setAttribute("type", "password");
  };
  return (
    <>
      {!isLoading ? (
        <form className="loginForm">
          <h2>Sign In</h2>
          <p>We help you to learn korean language with ease.</p>
          <label htmlFor="email">
            <input
              name="email"
              type="text"
              placeholder="Enter your email or username"
              onChange={handleChange}
            ></input>
          </label>
          <label htmlFor="password" className="password-container">
            <input
              ref={inputPassword}
              name="password"
              placeholder="Enter your password"
              type="password"
              onChange={handleChange}
            ></input>
            <i className="bi bi-eye-slash" onClick={togglePasswordShow}></i>
          </label>
          <div className="formoptions ">
            <p
              className="text-right"
              onClick={() => dispatch(openViewForgotPassword())}
            >
              Forgot Password?
            </p>
          </div>
          <button
            onClick={(e) => {
              handleSubmit(e);
            }}
          >
            Sign In
          </button>
          <div className="orloginwith">
            <p>or sign in with</p>
          </div>
          <div className="socialmedia-login">
            <LoginSocialGoogle
              client_id={process.env.REACT_APP_GOOGLEKEY}
              redirect_uri={REDIRECT_URI}
              scope="openid profile email"
              onResolve={({ provider, data }) => {
                dispatch(loadingStart());
                const formData = new FormData();
                formData.append("name", data.name);
                formData.append("photo", data.picture);
                formData.append("email", data.email);
                formData.append("provider_id", data.sub);
                // formData.append("provider_id", data.access_token);
                dispatch(loginWithSocial({ formData, provider })).then(
                  (res) => {
                    if (res.payload.status === 200) {
                      dispatch(closeModal());
                      navigate("/dashboard");
                    } else {
                      toast.error("Please Try Again!");
                    }
                  }
                );
              }}
              onReject={(err) => {
                toast.error("Please Try Again!", err);
              }}
            >
              <p className="eachlogin-btn google-login">
                <img src={googlelogin} alt="" />
                <span>Continue with Google</span>
              </p>
            </LoginSocialGoogle>
            <LoginSocialFacebook
              appId={process.env.REACT_APP_FACEBOOKKEY}
              redirect_uri={REDIRECT_URI}
              onResolve={({ provider, data }) => {
                const formData = new FormData();
                formData.append("name", data.name);
                formData.append("photo", data.picture.data.url);
                formData.append("email", data.email);
                formData.append("provider_id", data.id);
                dispatch(loginWithSocial({ formData, provider })).then(
                  (res) => {
                    if (res.payload.status === 200) {
                      dispatch(closeModal());
                      navigate("/dashboard");
                    } else {
                      toast.error("Please Try Again!");
                    }
                  }
                );
              }}
              onReject={(err) => {
                toast.error("Please Try Again!", err);
              }}
            >
              <p className="eachlogin-btn facebook-login">
                <img src={facebooklogin} alt="" />
                <span>Continue with Facebook</span>
              </p>
            </LoginSocialFacebook>

            {/* <a href="/#" className="eachlogin-btn apple-login">
                  <img src={applelogin} alt="" />
                  <span>Continue with Apple</span>
                </a> */}
          </div>
          <p className="registernow">
            Don't have an account?{" "}
            <span
              onClick={() => {
                dispatch(openModal("registerView"));
                dispatch(openRegisterForm());
              }}
            >
              Register Now
            </span>
          </p>
        </form>
      ) : (
        <div className="loading__mode bigloading">
          <Loader label="Redirecting you to dashboard! Please wait" />
        </div>
      )}
    </>
  );
};

export default LoginForm;
