import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { openViewNewPassword } from "../../../Features/ViewSlice";
import { emailVerify, otpResend } from "../../../Features/AuthSlice";
const OTPVerification = () => {
  let dispatch = useDispatch();
  const [OTP, setOTP] = useState("");
  const handleChange = (e) => {
    setOTP(e.target.value);
  };
  let email = localStorage.getItem("email");
  const verifyOTP = () => {
    dispatch(emailVerify({ otp: OTP, email })).then((res) => {
      if (res.payload.status === 200) {
        dispatch(openViewNewPassword());
      }
    });
  };
  const handleResendOTP = () => {
    dispatch(otpResend({ email }));
  };
  return (
    <div className="forgotpassword-container">
      <h2>OTP Verification</h2>
      <p>Enter the verification code we just sent on your email address.</p>
      <label htmlFor="">
        <input
          type="number"
          name="otp number"
          placeholder="Enter Your 6 Digit OTP Number"
          onChange={handleChange}
        ></input>
      </label>
      <button onClick={verifyOTP}>Verify OTP</button>
      <p className="registernow">
        Didn't Receive Code? <span onClick={handleResendOTP}>Resend</span>
      </p>
    </div>
  );
};

export default OTPVerification;
