import React, { useEffect } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { useSelector } from "react-redux";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler
);

export function ChartContainer() {
  let dataApi = useSelector(
    (state) => state.profile.profileInfo.result_history
  );

  let labels = [];
  let datasets = [];
  if (dataApi) {
    Object.keys(dataApi).forEach((item) => {
      let newTextAtrr = item.split("-");
      let newText = `${newTextAtrr[0]} at ${newTextAtrr[1]} - ${newTextAtrr[2]}`;
      labels.unshift(newText);
    });
    let datasetobj = {};
    datasetobj.label = "Score";
    datasetobj.backgroundColor = "#EAE8FE";
    datasetobj.borderColor = "#00437b";
    datasetobj.tension = 0.4;
    datasetobj.fill = true;
    datasetobj.pointBorderWidth = 5;
    datasetobj.data = [];
    Object.values(dataApi).forEach((item) => {
      datasetobj.data.unshift(parseFloat(item));
    });
    datasets.push(datasetobj);
  }
  const getLabel = (index) => {
    let text = labels[index];
    return `${text.split(" ")[0]} ${text.split(" ")[1]}`;
  };
  const options = {
    plugins: {
      title: {
        display: true,
        text: "Last 5 test scores",
      },
    },
    responsive: true,
    scales: {
      x: {
        stacked: true,
        grid: {
          display: false,
        },
        ticks: {
          callback: function (value, index, ticks) {
            return getLabel(value);
          },
        },
      },
      y: {
        stacked: true,
        suggestedMin: 0,
      },
    },
  };

  const data = {
    labels,
    datasets,
  };
  return <>{dataApi && <Line options={options} data={data} />}</>;
}
