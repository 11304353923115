import React, { useState, useEffect, useRef } from "react";
import { Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Form } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import DictionarySidebar from "../../Components/DictionarySidebar";
import HTMLParser from "../../../Components/HTMLParser";
import { useSelector, useDispatch } from "react-redux";
import Loader from "../../../Components/Loader/Loader";
import WordDay from "../../Components/WordDay";
import { getSearch } from "../../../../Features/SearchApi/SearchSlice";
import CustomAd from "../../../Components/CustomAd/CustomAd";
import DictionaryDetails from "./DictionaryDetails";
import {
  deselectDictionary,
  selectDictionary,
  singleDictionary,
} from "../../../../Features/DictionaryApi/DictionarySlice";
const DictionaryPage = ({ datas, setPage, type }) => {
  const dispatch = useDispatch();
  let isLoading = useSelector((state) => state.dictionary.isLoading);
  let errorHandle = useSelector((state) => state.dictionary.errorHandle);
  let searchText = useSelector((state) => state.search.getSearchValue);
  let searchLoading = useSelector((state) => state.search.isLoading);
  let token = useSelector((state) => state.auth?.currentUser?.token);
  let isLoadingSingle = useSelector(
    (state) => state.dictionary.isLoadingSingle
  );
  let dictionarySingleView = useSelector(
    (state) => state.dictionary.isSingleSelected
  );
  let homead = useSelector(
    (state) =>
      state.ad.allAdPositions["sidebar-dictionary-list-homepage-306-x-250"]
  );
  let dashboardad = useSelector(
    (state) =>
      state.ad.allAdPositions["sidebar-dictionary-list-dashboard-306-x-250"]
  );
  const [currentConvert, setConvert] = useState("k-n");
  const [lastElement, setLastElement] = useState(null);
  const [haveFocus, setIsFocused] = useState(false);

  const [value, setValue] = useState("");
  const observer = useRef(
    new IntersectionObserver((entries) => {
      const first = entries[0];
      if (first.isIntersecting) {
        setPage((prev) => prev + 1);
      }
    })
  );
  useEffect(() => {
    const currentElement = lastElement;
    const currentObserver = observer.current;
    if (errorHandle) {
      currentObserver.unobserve(currentElement);
      return;
    }
    if (currentElement) {
      currentObserver.observe(currentElement);
    }

    return () => {
      if (currentElement) {
        currentObserver.unobserve(currentElement);
      }
    };
  }, [lastElement]);

  const handleChange = (e) => {
    setValue(e.target.value);
  };
  const searchValue = (e) => {
    setTimeout(() => {
      if (value.length != 0) {
        dispatch(deselectDictionary());
        dispatch(getSearch({ param: value, model: "Dictionary" }));
      }
    }, 250);
  };

  const leaveFocus = () => {
    setIsFocused(false);
    if (value.length === 0) {
      setValue("");
    }
  };
  const handleSelectDictionary = (id) => {
    dispatch(selectDictionary());
    if (token) {
      dispatch(singleDictionary({ token, id }));
    } else {
      dispatch(singleDictionary({ id }));
    }
  };
  return (
    <div className="grammar-container">
      {/* <h2 className="dashboard-page-title">LKWB Dictionary</h2> */}
      <Row>
        <Col md={8}>
          <div className="grammar__section">
            <div className="grammar-search">
              {dictionarySingleView && (
                <div className="grammar-container">
                  {!isLoadingSingle ? (
                    <DictionaryDetails> </DictionaryDetails>
                  ) : (
                    <div className="bigLoading loading__mode">
                      <Loader />
                    </div>
                  )}
                </div>
              )}
              <Form>
                <Form.Text className="text-muted">
                  Search word and find it’s meaning and examples.
                </Form.Text>
                <Form.Group controlId="grammar--search">
                  <Form.Control
                    onChange={handleChange}
                    type="search"
                    value={value}
                    placeholder="Search"
                    onFocus={haveFocus}
                    onBlur={leaveFocus}
                    onKeyUp={searchValue}
                  />
                </Form.Group>
                <Button variant="primary" type="submit">
                  <i className="bi bi-search"></i>
                </Button>
              </Form>
            </div>
            {value.length === 0 ? (
              <>
                <div
                  className={
                    dictionarySingleView
                      ? " hidesingle_g_d"
                      : "grammar--details"
                  }
                >
                  {datas.map((item, id) => {
                    return (
                      <div className="grammar-listeach" key={id} ref={setLastElement}>
                        <h3
                          key={id}
                          onClick={() => handleSelectDictionary(item.id)}
                        >
                          {item.word}
                        </h3>
                        {currentConvert === "k-e" ? (
                          <HTMLParser>{item.meaning.en_meaning}</HTMLParser>
                        ) : (
                          <HTMLParser>{item.meaning.np_meaning}</HTMLParser>
                        )}
                      </div>
                    );
                  })}
                  {isLoading && (
                    <div className="loading__mode ">
                      <Loader />
                    </div>
                  )}
                  {errorHandle && <p>No More Data to Load</p>}
                </div>
              </>
            ) : (
              <div
                className={
                  dictionarySingleView ? " hidesingle_g_d" : "grammar--details"
                }
              >
                {!searchLoading ? (
                  <>
                    {searchText.length > 0 ? (
                      <>
                        {searchText.map((item, id) => (
                          <div key={id}>
                            <h3
                              key={id}
                              onClick={() => handleSelectDictionary(item.id)}
                            >
                              {item.word}
                            </h3>
                            {currentConvert === "k-e" ? (
                              <HTMLParser>{item.meaning.en_meaning}</HTMLParser>
                            ) : (
                              <HTMLParser>{item.meaning.np_meaning}</HTMLParser>
                            )}
                          </div>
                        ))}
                      </>
                    ) : (
                      <>
                        {value.length > 0 && (
                          <div className="text-center font-weight-bold nosearchresult">
                            No Search Result Found
                          </div>
                        )}
                      </>
                    )}
                    <>
                      {value.length === 0 && (
                        <div className="search">Search...</div>
                      )}
                    </>
                  </>
                ) : (
                  <div className="loading__mode bigLoading">
                    <Loader label="Searching" />
                  </div>
                )}
              </div>
            )}
          </div>
        </Col>
        <Col md={4}>
          <div className="sticky">
            <WordDay
              handleSelection={handleSelectDictionary}
              type="dictionary"
              from={type}
            ></WordDay>
            {!dictionarySingleView && (
              <DictionarySidebar
                currentConvert={currentConvert}
                setConvert={(data) => setConvert(data)}
              />
            )}

            {type === "home" ? (
              <>
                {homead.map((item, id) => (
                  <CustomAd item={item} key={id} />
                ))}
              </>
            ) : (
              <>
                {dashboardad.map((item, id) => (
                  <CustomAd item={item} key={id} />
                ))}
              </>
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default DictionaryPage;
