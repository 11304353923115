import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { ReactComponent as PageBannerSVG } from "../../../Assets/img/big-lkwb.svg";
import { useSelector, useDispatch } from "react-redux";
import HTMLParser from "../../Components/HTMLParser";
import { allProducts } from "../../../Features/HomeApi/HomeSlice";
import { Link } from "react-router-dom";
const BooksNotes = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(allProducts());
  }, [dispatch]);
  let booksnotes = useSelector((state) => state.home?.allProduct?.books_notes);
  return (
    <>
      <div className="pageBanner">
        <PageBannerSVG />
        <Container>
          <div className="page-wrap">
            <h3>Books/Notes</h3>
          </div>
          <div class="custom-shape-divider-bottom-1678688493">
            <svg
              data-name="Layer 1"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 1200 120"
              preserveAspectRatio="none"
            >
              <path
                d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z"
                class="shape-fill"
              ></path>
            </svg>
          </div>
        </Container>
      </div>
      <section className="section">
        {booksnotes && (
          <>
            {booksnotes.length > 0 ? (
              <Container>
                <Row className="g-4">
                  {booksnotes.map((item, id) => (
                    <Col key={id} className="eachbooknote" lg={4} sm={2}>
                      <Link to={`/books-notes/booknotessingle/${item.id}`}>
                        <img src={item.image}></img>
                      </Link>
                      <h3>
                        <Link to={`/books-notes/booknotessingle/${item.id}`}>
                          {item.title}
                        </Link>
                      </h3>
                      {item.excerpt && (
                        <p>
                          <HTMLParser>{item.excerpt}</HTMLParser>
                        </p>
                      )}
                    </Col>
                  ))}
                </Row>
              </Container>
            ) : (
              <Container>
                <div className="dashboard-contents">
                  <p>Books and Packages are not available at the moment.</p>
                </div>
              </Container>
            )}
          </>
        )}
      </section>
    </>
  );
};

export default BooksNotes;
