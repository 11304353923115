import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { save_results } from "./SetsAPI";
const initialQuestion = {
  start: false,
  end: false,
};
const initialState = {
  isLoading: false,
  solvedQuestion: [],
  selectedLocalQuestion: {},
  saveResult: {},
  errorMessage: "",
  testLatestURL: "",
  questionState: { ...initialQuestion },
};

export const saveResult = createAsyncThunk(
  "localset/saveResult",
  async (data, { rejectWithValue }) => {
    try {
      return await save_results(data);
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err);
    }
  }
);
let findingIndex = (array, questionId) => {
  return array.findIndex(({ id }) => id === questionId);
};
const LocalSetSLice = createSlice({
  name: "localsets",
  initialState,
  reducers: {
    testLatestUrl: (state, action) => {
      state.testLatestURL = action.payload;
    },
    saveSolvedQuestions: (state, action) => {
      state.solvedQuestion = [...state.solvedQuestion, action.payload];
    },
    selectLocalQuestion: (state, action) => {
      state.selectedLocalQuestion = action.payload;
    },
    updateSolvedQuestions: (state, action) => {
      let questionId = action.payload.id;
      let value = action.payload.value;
      let index = findingIndex(state.solvedQuestion, questionId);
      if (index !== undefined) {
        state.solvedQuestion[index].answers.options.forEach((item) => {
          if (item.hasOwnProperty("userSelected")) {
            delete item.userSelected;
          }
        });
        let answersIndex = state.solvedQuestion[
          index
        ].answers.options.findIndex(({ id }) => id === parseInt(value));
        state.solvedQuestion[index].answers.options[
          answersIndex
        ].userSelected = true;
      }
      // state.solvedQuestion[action.payload.id].answers.options =
      //   action.payload.data;
    },
    audioPlayed: (state, action) => {
      let questionId = action.payload.idOfQuestion;
      let optionId = action.payload.idOfOption;
      let index = findingIndex(state.solvedQuestion, questionId);
      if (index !== undefined) {
        let answersIndex = state.solvedQuestion[
          index
        ].answers.options.findIndex(({ id }) => id === parseInt(optionId));
        state.solvedQuestion[index].answers.options[
          answersIndex
        ].userPlayed = true;
      }
    },
    questionAudioPlayed: (state, action) => {
      let questionId = action.payload.idOfQuestion;
      let index = findingIndex(state.solvedQuestion, questionId);
      if (index !== undefined) {
        state.solvedQuestion[index].userPlayedQuestion = true;
      }
    },
    clearAllValue: (state) => {
      state.solvedQuestion = [];
      state.selectedLocalQuestion = {};
      state.questionState = { ...initialQuestion };
    },
    startTest: (state) => {
      state.questionState = { ...initialQuestion };
      state.questionState.start = true;
    },
    endTest: (state) => {
      state.questionState = { ...initialQuestion };
      state.questionState.end = true;
    },
    resetTest: (state) => {
      state.questionState = { ...initialQuestion };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(saveResult.pending, (state) => {
        state.isLoading = true;
        state.saveResult = "";
        state.errorMessage = "";
      })
      .addCase(saveResult.fulfilled, (state, action) => {
        state.isLoading = false;
        state.saveResult = action.payload.data.data;
      })
      .addCase(saveResult.rejected, (state, action) => {
        state.isLoading = false;
        state.errorMessage = action.payload.response.data.message;
        state.saveResult = "";
      });
  },
});

export const {
  saveSolvedQuestions,
  updateSolvedQuestions,
  selectLocalQuestion,
  clearAllValue,
  startTest,
  questionAudioPlayed,
  audioPlayed,
  endTest,
  resetTest,
} = LocalSetSLice.actions;
export default LocalSetSLice.reducer;
