

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { url } from './VoculabaryApi';
export const fetchVoculabary = createAsyncThunk('voculabary/fetchVoculabary', async () => {
 
  const response = await fetch(url);
  if (!response.ok) {
    throw new Error(`Failed to fetch voculabary. Status: ${response.status}`);
  }
  const data = await response.json();
  return data.data;
});

const Voculabaryslice = createSlice({
  name: 'voculabary',
  initialState: { voculabary: [], loading: 'idle', error: null },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchVoculabary.pending, (state) => {
        state.loading = 'loading';
      })
      .addCase(fetchVoculabary.fulfilled, (state, action) => {
        state.loading = 'succeeded';
        state.voculabary = action.payload;
      })
      .addCase(fetchVoculabary.rejected, (state, action) => {
        state.loading = 'failed';
        state.error = action.error.message;
      });
  },
});

export default Voculabaryslice.reducer;

