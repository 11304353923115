import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  allDictionary,
} from "../../../../Features/DictionaryApi/DictionarySlice";
import DictionaryPage from "./DictionaryPage";
const Dictionary = () => {
  const dispatch = useDispatch();
  let token = useSelector((state) => state.auth.currentUser.token);
  let dictionaryData = useSelector((state) => state.dictionary.allDictionary);
  let errorHandle = useSelector((state) => state.dictionary.errorHandle);
  const [page, setPage] = useState(1);
  useEffect(() => {
    if (errorHandle) {
      return;
    }
    dispatch(allDictionary({ token, page: page }));
  }, [dispatch, page]);

  return (
    <>
      {dictionaryData && (
        <DictionaryPage
          datas={dictionaryData}
          setPage={setPage}
          type="dashboard"
        />
      )}
    </>
  );
};

export default Dictionary;
