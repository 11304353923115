import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";
import {
  all_sets,
  each_Sets,
  buy_Sets,
  buy_Package,
  questions,
  each_Question,
  claim_sets,
  redirectKhalti,
  buy_setsRewardPoint,
  buy_packageRewardPoint,
  all_package,
  prelog,
  reclaim,
  most_bought_set,
  single_package,
} from "./SetsAPI";
import { toast } from "react-toastify";

const initialState = {
  isLoading: false,
  allSets: [],
  selectedSet: {},
  selectedPackage: {},
  questions: [],
  selectedQ: {},
  invoiceDetails: {},
  errorHandle: [],
  mostBoughtSets: [],
  buySetErrorhandle: {},
  packageCollection: [],
};

export const singlePackage = createAsyncThunk(
  "sets/singlePackage",
  async (data, { rejectWithValue }) => {
    try {
      return await single_package(data);
    } catch (error) {
      if (!error.response) throw error;
      return rejectWithValue(error);
    }
  }
);
export const mostBoughtSets = createAsyncThunk(
  "sets/mostBoughtSets",
  async () => {
    try {
      return await most_bought_set();
    } catch (error) {
      return error;
    }
  }
);
export const reClaimFn = createAsyncThunk(
  "sets/reclaim",
  async (data, { rejectWithValue }) => {
    try {
      return await reclaim(data);
    } catch (error) {
      if (!error.response) throw error;
      return rejectWithValue(error);
    }
  }
);

export const prelogFn = createAsyncThunk(
  "sets/prelog",
  async (data, { rejectWithValue }) => {
    try {
      return await prelog(data);
    } catch (error) {
      if (!error.response) throw error;
      return rejectWithValue(error);
    }
  }
);

export const buysetsWithRewardPoint = createAsyncThunk(
  "sets/buysetsWithRewardPoint",
  async (data, { rejectWithValue }) => {
    try {
      return await buy_setsRewardPoint(data);
    } catch (error) {
      if (!error.response) throw error;
      return rejectWithValue(error);
    }
  }
);

export const buypackageWithRewardPoint = createAsyncThunk(
  "sets/buypackageWithRewardPoint",
  async (data, { rejectWithValue }) => {
    try {
      return await buy_packageRewardPoint(data);
    } catch (error) {
      if (!error.response) throw error;
      return rejectWithValue(error);
    }
  }
);
export const allPackage = createAsyncThunk("sets/allPackage", async (token) => {
  try {
    return await all_package(token);
  } catch (error) {
    return error;
  }
});
export const allSets = createAsyncThunk("sets/getAllSets", async (token) => {
  try {
    return await all_sets(token);
  } catch (error) {
    return error;
  }
});

export const eachSets = createAsyncThunk("sets/eachSets", async (data) => {
  try {
    return await each_Sets(data);
  } catch (error) {
    return error;
  }
});

export const buySets = createAsyncThunk(
  "sets/buySets",
  async (data, { rejectWithValue }) => {
    try {
      return await buy_Sets(data);
    } catch (error) {
      if (!error.response) throw error;
      return rejectWithValue(error);
    }
  }
);

export const buyPackage = createAsyncThunk(
  "sets/buyPackage",
  async (data, { rejectWithValue }) => {
    try {
      return await buy_Package(data);
    } catch (error) {
      if (!error.response) throw error;
      return rejectWithValue(error);
    }
  }
);

export const getQuestions = createAsyncThunk(
  "sets/getQuestions",
  async (data, { rejectWithValue }) => {
    try {
      return await questions(data);
    } catch (error) {
      if (!error.response) throw error;
      return rejectWithValue(error);
    }
  }
);
export const eachQuestion = createAsyncThunk(
  "sets/eachQuestion",
  async (data, { rejectWithValue }) => {
    try {
      return await each_Question(data);
    } catch (error) {
      if (!error.response) throw error;
      return rejectWithValue(error);
    }
  }
);
export const redirectToKhalti = createAsyncThunk(
  "sets/redirectKhalti",
  async (data, { rejectWithValue }) => {
    try {
      return await redirectKhalti(data);
    } catch (error) {
      error.response
        ? error.response.data.amount.forEach((item) => {
            toast.error(item);
          })
        : toast.error(error.message);
      return rejectWithValue(error);
    }
  }
);
// export const saveResult = createAsyncThunk("sets/saveResult", async (data) => {
//   try {
//     return await save_results(data);
//   } catch (error) {
//     return error;
//   }
// });
export const claimSet = createAsyncThunk("sets/claimSet", async (data) => {
  try {
    return await claim_sets(data);
  } catch (error) {
    return error;
  }
});
const SetsSlice = createSlice({
  name: "sets",
  initialState,
  reducers: {
    clearSession: (state) => {
      state.allSets = [];
      state.selectedSet = {};
      state.questions = [];
      state.selectedQ = {};
      state.selectedPackage = {};
    },
    selectPackage: (state, action) => {
      state.selectedPackage = action.payload;
    },
    selectQuestion: (state, action) => {
      state.selectedQ = state.questions.find(
        (item) => item.id === parseInt(action.payload)
      );
    },
    loadstart: (state) => {
      state.isLoading = true;
    },
    loadend: (state) => {
      state.isLoading = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(allSets.pending, (state, action) => {
        state.isLoading = true;
        state.allSets = [];
      })
      .addCase(allSets.fulfilled, (state, action) => {
        state.allSets = action.payload.data.data;
        state.isLoading = false;
      })
      .addCase(allSets.rejected, (state, action) => {
        state.isLoading = false;
        state.allSets = [];
        state.allSets = "";
        if (typeof action.payload.response.data.message === "object") {
          for (const [key, value] of Object.entries(
            action.payload.response.data.message
          )) {
            state.errorHandle.push({ key, value });
          }
          state.errorHandle.forEach((item) => {
            toast.error(item.value[0]);
          });
        }
        if (typeof action.payload.response.data.message === "string") {
          state.errorHandle.push(action.payload.response.data.message);
          state.errorHandle.forEach((item) => {
            toast.error(item);
          });
        }
      })
      .addCase(eachSets.pending, (state, action) => {
        state.isLoading = true;
        state.selectedSet = "";
      })
      .addCase(eachSets.fulfilled, (state, action) => {
        state.isLoading = false;
        state.selectedSet = action.payload.data.data;
      })
      .addCase(eachSets.rejected, (state, action) => {
        state.isLoading = false;
        state.selectedSet = "";
      })
      .addCase(getQuestions.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getQuestions.fulfilled, (state, action) => {
        state.questions = action.payload.data.data;
        state.isLoading = false;
      })
      .addCase(getQuestions.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(buySets.pending, (state, action) => {
        state.isLoading = true;
        state.invoiceDetails = "";
      })
      .addCase(buySets.fulfilled, (state, action) => {
        state.isLoading = false;
        state.invoiceDetails = action.payload.data.data;
      })
      .addCase(buySets.rejected, (state, action) => {
        state.invoiceDetails = "";
        state.isLoading = false;
        state.buySetErrorhandle = action.payload.response.data.message;
      })
      .addCase(buyPackage.pending, (state, action) => {
        state.isLoading = true;
        state.invoiceDetails = "";
      })
      .addCase(buyPackage.fulfilled, (state, action) => {
        state.isLoading = false;
        state.invoiceDetails = action.payload.data.data;
      })
      .addCase(buyPackage.rejected, (state, action) => {
        state.invoiceDetails = "";
        state.isLoading = false;
        state.buySetErrorhandle = action.payload.response.data.message;
      })
      .addCase(claimSet.pending, (state, action) => {
        state.invoiceDetails = "";
      })
      .addCase(claimSet.fulfilled, (state, action) => {
        state.invoiceDetails = action.payload.data.data;
      })
      .addCase(claimSet.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(redirectToKhalti.pending, (state) => {
        state.isLoading = true;
        state.errorHandle = [];
      })
      .addCase(redirectToKhalti.fulfilled, (state, action) => {
        state.isLoading = false;
        if (JSON.parse(action.payload.data).payment_url) {
          window.location.replace(JSON.parse(action.payload.data).payment_url);
        }
        if (JSON.parse(action.payload.data).detail) {
          toast.error(JSON.parse(action.payload.data).detail);
        }
      })
      .addCase(redirectToKhalti.rejected, (state, action) => {
        state.isLoading = false;
        if (typeof action.payload.response.data.message === "object") {
          for (const [key, value] of Object.entries(
            action.payload.response.data.message
          )) {
            state.errorHandle.push({ key, value });
          }
          state.errorHandle.forEach((item) => {
            toast.error(item.value[0]);
          });
        }
        if (typeof action.payload.response.data.message === "string") {
          state.errorHandle.push(action.payload.response.data.message);
          state.errorHandle.forEach((item) => {
            toast.error(item);
          });
        }
      })
      .addCase(allPackage.pending, (state) => {
        state.packageCollection = [];
      })
      .addCase(allPackage.fulfilled, (state, action) => {
        state.selectedPackage = {};
        state.packageCollection = action.payload.data.data;
      })
      .addCase(allPackage.rejected, (state, action) => {
        state.packageCollection = [];
      })
      .addCase(singlePackage.pending, (state) => {
        state.isLoading = true;
        state.selectedPackage = {};
      })
      .addCase(singlePackage.fulfilled, (state, action) => {
        state.isLoading = false;
        state.selectedPackage = action.payload.data.data;
      })
      .addCase(singlePackage.rejected, (state, action) => {
        state.isLoading = false;
        state.selectedPackage = {};
      })
      .addCase(buysetsWithRewardPoint.pending, (state, action) => {
        state.invoiceDetails = [];
        state.isLoading = true;
        state.errorHandle = [];
      })
      .addCase(buysetsWithRewardPoint.fulfilled, (state, action) => {
        state.isLoading = false;
        state.invoiceDetails = action.payload.data.data;
      })
      .addCase(buysetsWithRewardPoint.rejected, (state, action) => {
        state.isLoading = false;
        if (typeof action.payload.response.data.message === "object") {
          for (const [key, value] of Object.entries(
            action.payload.response.data.message
          )) {
            state.errorHandle.push({ key, value });
          }
          state.errorHandle.forEach((item) => {
            toast.error(item.value[0]);
          });
        }
        if (typeof action.payload.response.data.message === "string") {
          state.errorHandle.push(action.payload.response.data.message);
          state.errorHandle.forEach((item) => {
            toast.error(item);
          });
        }
      })
      .addCase(buypackageWithRewardPoint.pending, (state) => {
        state.isLoading = true;
        state.invoiceDetails = [];
        state.errorHandle = [];
      })
      .addCase(buypackageWithRewardPoint.fulfilled, (state, action) => {
        state.isLoading = false;
        state.invoiceDetails = action.payload.data.data;
      })
      .addCase(buypackageWithRewardPoint.rejected, (state, action) => {
        state.isLoading = false;
        if (typeof action.payload.response.data.message === "object") {
          for (const [key, value] of Object.entries(
            action.payload.response.data.message
          )) {
            state.errorHandle.push({ key, value });
          }
          state.errorHandle.forEach((item) => {
            toast.error(item.value[0]);
          });
        }
        if (typeof action.payload.response.data.message === "string") {
          state.errorHandle.push(action.payload.response.data.message);
          state.errorHandle.forEach((item) => {
            toast.error(item);
          });
        }
      })
      .addCase(reClaimFn.fulfilled, () => {
        toast.info("The Test has been reclaimed. Thank you for your purchase.");
      })
      .addCase(reClaimFn.rejected, (state, action) => {
        toast.error(action.payload.response.data.message);
      })
      .addCase(mostBoughtSets.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(mostBoughtSets.fulfilled, (state, action) => {
        state.isLoading = false;
        state.mostBoughtSets = action.payload.data.data;
      })
      .addCase(mostBoughtSets.rejected, (state, action) => {
        state.isLoading = false;
      });
  },
});

export const {
  clearSession,
  loadstart,
  loadend,
  selectPackage,
  selectQuestion,
} = SetsSlice.actions;
export default SetsSlice.reducer;
