import React from "react";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";

const Page404 = () => {
  return (
    <div>
      <div className="error404">
        <Container>
          <h1>404</h1>
          <p>
            <i className="bi bi-exclamation-diamond"></i>
            Oops! Page not found!
          </p>
          <Link to="/" className="btn">
            Return To Home
          </Link>
        </Container>
      </div>
    </div>
  );
};

export default Page404;
