import React, { useState } from "react";
import { Accordion, Col, Row } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import DashboardSummary from "./DashboardSummary";
import { useLocation } from "react-router";
import { useNavigate } from "react-router";
import { Modal } from "react-bootstrap";
import DashboardResultQuestions from "./DashboardResultQuestions";
import { clearAllValue } from "../../../Features/SetsAPI/LocalSetSlice";
import { clearSession } from "../../../Features/SetsAPI/SetsSlice";
import ResultsQuestionItem from "./ResultsQuestionItem";
const DashboardViewAllResults = () => {
  let navigate = useNavigate();
  let dispatch = useDispatch();
  let testState = useSelector((state) => state.localset.questionState);
  let solvedQuestions = useSelector((state) => state.localset.solvedQuestion);
  let allQuestions = useSelector((state) => state.set.questions);
  let audioQuestions = [];
  let readingQuestions = [];
  if (allQuestions) {
    allQuestions.forEach((item) => {
      if (item.type === "audio") {
        audioQuestions.push(item);
      }
      if (item.type === "image" || item.type === "text") {
        readingQuestions.push(item);
      }
    });
  }
  const [cancel, setCancel] = useState(false);
  const handleCloseCancel = () => {
    setCancel(false);
  };
  const handleShowCancel = () => setCancel(true);
  const takeAnotherTest = () => {
    handleCloseCancel();
    dispatch(clearAllValue());
    dispatch(clearSession());
    navigate("/dashboard/ubt-test");
  };
  const goToSection = (id) => {
    document
      .getElementById(id)
      .scrollIntoView({ behavior: "smooth", block: "center" });
  };
  window.onpopstate = () => {
    if (testState.end === true) {
      navigate("/dashboard/dashboard-viewall-results", {
        replace: true,
      });
      handleShowCancel();
    }
  };
  return (
    <>
      <button className="btn mb-3 fw showonmobile" onClick={takeAnotherTest}>
        TAKE ANOTHER TEST
      </button>
      {solvedQuestions && allQuestions && (
        <Row className="dashboard-results__wrapper">
          <Col md={9}>
            {allQuestions.map((item, id) => {
              // if (solvedQuestions[id] != undefined) {
              //   return (
              return (
                <DashboardResultQuestions
                  key={id}
                  index={id}
                  selectedQuestion={item}
                />
              );
              //   );
              // } else {
              //   return (
              //     <DashboardResultQuestions
              //       index={id}
              //       key={id}
              //       selectedQuestion={item}
              //     />
              //   );
              // }
            })}
          </Col>
          <Col md={3} className="rightsidebar">
            <div className="sticky fixedheight">
              <button className="btn mb-3 fw" onClick={takeAnotherTest}>
                TAKE ANOTHER TEST
              </button>
              <DashboardSummary view="resultView" />
              <Accordion defaultActiveKey={["0"]} alwaysOpen>
                {readingQuestions.length > 1 && (
                  <ResultsQuestionItem
                    eventId="0"
                    label="Reading Questions"
                    questions={readingQuestions}
                    goToSection={(id) => goToSection(id)}
                    solvedQuestion={solvedQuestions}
                  />
                )}
                {audioQuestions.length > 1 && (
                  <ResultsQuestionItem
                    eventId="1"
                    goToSection={(id) => goToSection(id)}
                    label="Listening Questions"
                    solvedQuestion={solvedQuestions}
                    questions={audioQuestions}
                    startFrom={
                      readingQuestions.length > 1 ? readingQuestions.length : 0
                    }
                  />
                )}
              </Accordion>
              <Modal show={cancel} onHide={handleCloseCancel}>
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                  <p>
                    Do you really want to close the result? You can’t undo it.
                  </p>
                  <button className="btn btn-small" onClick={takeAnotherTest}>
                    Yes
                  </button>
                  <button
                    className="btn btn-small btn-secondary"
                    onClick={handleCloseCancel}
                  >
                    No
                  </button>
                </Modal.Body>
              </Modal>
            </div>
          </Col>
          <span className="close hideonmobile" onClick={handleShowCancel}>
            <i className="bi bi-x-lg"></i>
          </span>
        </Row>
      )}
    </>
  );
};

export default DashboardViewAllResults;
