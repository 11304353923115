import React, { useEffect, useState } from "react";
import { Row, Col, Modal, Form } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import fallBackImg from "../../../../Assets/img/fallbackimg.png";
import {
  emptyProfile,
  getProfile,
} from "../../../../Features/Profile_management/profileSlice";
import { deleteUser } from "../../../../Features/Profile_management/profileSlice";
import { clearAllValue } from "../../../../Features/SetsAPI/LocalSetSlice";
import { endTest } from "../../../../Features/SetsAPI/LocalSetSlice";
import { clearSession } from "../../../../Features/SetsAPI/SetsSlice";
import { logoutFunc, otpResend } from "../../../../Features/AuthSlice";
const Profile = () => {
  let dispatch = useDispatch();
  let authToken = useSelector((state) => state.auth.currentUser.token);
  let profileInfo = useSelector((state) => state.profile.profileInfo);
  useEffect(() => {
    dispatch(getProfile(authToken));
  }, [dispatch, authToken]);
  const [show, setShow] = useState(false);
  const [password, setPassword] = useState("");
  const handleChange = (e) => {
    setPassword(e.target.value);
  };
  const logout = () => {
    localStorage.clear();
    dispatch(clearAllValue());
    dispatch(emptyProfile());
    dispatch(endTest());
    dispatch(clearSession());
    dispatch(logoutFunc());
  };
  const sendOTP = () => {
    dispatch(otpResend({ email: profileInfo.email }));
  };
  const handleShow = () => {
    sendOTP();
    setShow(true);
  };
  const handleClose = () => setShow(false);
  const deleteUserFn = (e) => {
    e.preventDefault();
    dispatch(deleteUser({ token: authToken, otp: password })).then((res) => {
      if (res.payload.status === 200) {
        logout();
      }
    });
  };

  return (
    <>
      {profileInfo && (
        <Row className="justify-content-center">
          <Col md={6}>
            <div className="currentprofile-view">
              <div className="insertimage">
                <div className="image-container">
                  <img
                    src={profileInfo.image ? profileInfo.image : fallBackImg}
                    alt="profileimage"
                  />
                </div>
                <div className="profile-text">
                  <h4>{profileInfo.name}</h4>
                  <span>{profileInfo.refCode}</span>
                  <p>{profileInfo.email}</p>
                  <Link className="btn" to="/dashboard/editprofile">
                    Edit Your Profile
                  </Link>
                </div>
                <button className="btn btn-secondary mt-2" onClick={handleShow}>
                  Delete your Account
                </button>
                <Modal show={show} onHide={handleClose} centered>
                  <Modal.Body>
                    <p>
                      Enter your otp sent to your email address.{" "}
                      <strong>{profileInfo.email}</strong>
                    </p>
                    <Form>
                      <Form.Control
                        type="text"
                        placeholder="Enter your OTP"
                        onChange={handleChange}
                      />
                      <p>
                        Haven't received an OTP?{" "}
                        <span onClick={sendOTP}>Resend OTP</span>
                      </p>
                      <div className="d-flex  justify-content-center mt-2">
                        <button
                          onClick={(e) => deleteUserFn(e)}
                          className="btn btn-secondary"
                        >
                          Delete
                        </button>
                        <button
                          className="btn btn-primary"
                          onClick={handleClose}
                        >
                          No
                        </button>
                      </div>
                    </Form>
                  </Modal.Body>
                </Modal>
              </div>
            </div>
          </Col>
        </Row>
      )}
    </>
  );
};

export default Profile;
