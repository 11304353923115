import { BASE_URL } from "../../Constants";
import axios from "axios";

export const get_Discussions = async (data) => {
  let url = `${BASE_URL}/api/home/discussions${
    data.sortby ? "?sort_order="+data.sortby : ""
  }`;
  let res;
  if (data.token) {
    let header = {
      Authorization: `Bearer ${data.token}`,
    };
    res = await axios.get(url, {
      headers: header,
    });
  } else {
    res = await axios.get(url);
  }
  return res;
};

export const single_Discussion = async (data) => {
  let url = `${BASE_URL}/api/home/discussions/${data.id}`;
  let res;
  if (data.token) {
    let header = {
      Authorization: `Bearer ${data.token}`,
    };
    res = await axios.get(url, {
      headers: header,
    });
  } else {
    res = await axios.get(url);
  }
  return res;
};

export const create_Discussion = async (data) => {
  let url = `${BASE_URL}/api/discussions/create`;
  let header = {
    authorization: `Bearer ${data.token}`,
  };
  const res = await axios.post(url, data.formdata, { headers: header });
  return res;
};
export const delete_discussion = async (data) => {
  let url = `${BASE_URL}/api/discussions/${data.id}/delete`;
  let header = {
    authorization: `Bearer ${data.token}`,
  };
  const res = await axios.post(url, data.formdata, { headers: header });
  return res;
};
export const update_discussion = async (data) => {
  let url = `${BASE_URL}/api/discussions/${data.id}/update`;
  let header = {
    authorization: `Bearer ${data.token}`,
  };
  const res = await axios.post(url, data.formdata, { headers: header });
  return res;
};
export const comment_Discussion = async (data) => {
  let url = `${BASE_URL}/api/discussions/${data.discussionid}/comment`;
  let header = {
    authorization: `Bearer ${data.token}`,
  };
  const res = await axios.post(url, data.formData, { headers: header });
  return res;
};

export const update_comment = async (data) => {
  let url = `${BASE_URL}/api/comment/${data.id}/update`;
  let header = {
    authorization: `Bearer ${data.token}`,
  };
  const res = await axios.post(url, data.formData, { headers: header });
  return res;
};

export const delete_comment = async (data) => {
  let url = `${BASE_URL}/api/comments/${data.id}/delete`;
  let header = {
    authorization: `Bearer ${data.token}`,
  };
  const res = await axios.post(url, data.formData, { headers: header });
  return res;
};

export const reply_comment = async (data) => {
  let url = `${BASE_URL}/api/comment/${data.id}/reply`;
  let header = {
    authorization: `Bearer ${data.token}`,
  };
  const res = await axios.post(url, data.formData, { headers: header });
  return res;
};

export const update_reply = async (data) => {
  let url = `${BASE_URL}/api/reply/${data.id}/update`;
  let header = {
    authorization: `Bearer ${data.token}`,
  };
  const res = await axios.post(url, data.formData, { headers: header });
  return res;
};

export const delete_reply = async (data) => {
  let url = `${BASE_URL}/api/reply/${data.id}/delete`;
  let header = {
    authorization: `Bearer ${data.token}`,
  };
  const res = await axios.post(url, data.formData, { headers: header });
  return res;
};
