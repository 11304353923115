import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  register,
  saveTempData,
  loginWithSocial,
} from "../../../Features/AuthSlice";
import Modal from "../Modal/Modal";
import { useNavigate } from "react-router";
import RegisterForm from "./RegisterForm";
import { Container } from "react-bootstrap";
import {
  closeModal,
  openRegisterImgRefForm,
} from "../../../Features/ViewSlice";
import Compressor from "compressorjs";
import RegisterImgRefForm from "./RegisterImgRefForm";
import { toast } from "react-toastify";
const Register = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [imageHandle, setImageHandle] = useState();
  const [imageUrl, setImageUrl] = useState("");
  const [show, setshow] = useState(false);
  const handleShow = () => {
    setshow(true);
  };
  const hanldeLogoChange = (e) => {
    setImageUrl(URL.createObjectURL(e.target.files[0]));
  };
  const [refCode, setRefCode] = useState();
  const handleRefCode = (e) => {
    setRefCode(e.target.value);
  };
  let registerTempData = useSelector((state) => state.auth.registerTempData);
  let registerModal = useSelector(
    (state) => state.view.modalState.registerView
  );
  let registerViews = useSelector((state) => state.view.registerInStates);
  const [registerDetails, setRegisterDetails] = useState({
    username: "",
    email: "",
    password: "",
    password_confirmation: "",
  });
  const handleChange = (e) => {
    setRegisterDetails({
      ...registerDetails,
      [e.target.name]: e.target.value,
    });
  };
  const handleImage = (e) => {
    let files = e.target.files[0];
    new Compressor(files, {
      quality: 0.8,
      success: (compressedResult) => {
        setImageHandle(compressedResult);
      },
    });
  };
  const handleFirstStep = (e) => {
    e.preventDefault();
    if (
      registerDetails.username.length === 0 ||
      registerDetails.email.length === 0 ||
      registerDetails.password.length === 0 ||
      registerDetails.password_confirmation.length === 0
    ) {
      toast.error("Please Insert all details before continuing.");
    } else {
      if (registerDetails.password != registerDetails.password_confirmation) {
        toast.error("Password Confirmation does not match");
      } else {
        let registerData = {
          name: registerDetails.username,
          email: registerDetails.email,
          password: registerDetails.password,
          password_confirmation: registerDetails.password_confirmation,
        };
        dispatch(saveTempData(registerData));
        dispatch(openRegisterImgRefForm());
      }
    }
  };
  const handleSubmit = () => {
    handleShow();
    if (registerTempData.hasOwnProperty("provider")) {
      let formData = new FormData();
      formData.append("name", registerTempData.name);
      formData.append("photo", registerTempData.photo);
      formData.append("email", registerTempData.email);
      formData.append("provider_id", registerTempData.provider_id);
      formData.append("refCode", refCode);
      dispatch(
        loginWithSocial({ formData, provider: registerTempData.provider })
      ).then((res) => {
        if (res.payload.status === 200) {
          dispatch(closeModal());
          navigate("/dashboard");
        } else {
          toast.error("Please Try Again!");
        }
      });
    } else {
      let formData = new FormData();
      formData.append("name", registerTempData.name);
      formData.append("email", registerTempData.email);
      formData.append("password", registerTempData.password);
      formData.append(
        "password_confirmation",
        registerTempData.password_confirmation
      );
      formData.append("refCode", refCode);
      if (imageHandle) {
        formData.append("image", imageHandle);
      }
      dispatch(register(formData)).then((res) => {
        if (res.payload.status === 200) {
          dispatch(closeModal());
          navigate("/dashboard");
        }
      });
    }
  };
  return (
    <>
      {registerModal && (
        <div className="registerFormContainer defaultInputs">
          <Container>
            <Modal size="lg">
              {registerViews.viewRegisterForm && (
                <RegisterForm
                  handleChange={handleChange}
                  handleFirstStep={handleFirstStep}
                />
              )}
              {registerViews.viewRegisterImgRefForm && (
                <RegisterImgRefForm
                  imageUrl={imageUrl}
                  handleRefCode={handleRefCode}
                  hanldeLogoChange={hanldeLogoChange}
                  handleImage={handleImage}
                  show={show}
                  handleSubmit={handleSubmit}
                />
              )}
            </Modal>
          </Container>
        </div>
      )}
    </>
  );
};
export default Register;
