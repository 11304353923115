import react, { useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { AllRoutes } from "./AllRoutes";
import { useSelector } from "react-redux";
import NotVerified from "./views/DashboardView/Components/NotVerified";
import HomeRouter from "./HomeRouter";
import DashboardRouter from "./DashboardRouter";
import EsewaFailure from "./views/pages/EsewaPages/EsewaFailure";
import EsewaSuccess from "./views/pages/EsewaPages/EsewaSuccess";
import KhaltiRedirect from "./views/pages/KhaltiPages/KhaltiRedirect";
import { useLocation } from "react-router-dom";
function AppRouter() {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  let isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  let isEmailVerified = useSelector((state) => state.auth.isEmailVerified);
  return (
    <Routes>
      <Route path="/" element={<HomeRouter />}>
        {AllRoutes.homeRoute.map((item, id) => (
          <Route key={id}>
            <Route path={item.link} element={item.component} />
            {item.submenu &&
              item.submenu.map((subitem, subid) => (
                <Route
                  key={subid}
                  path={subitem.link}
                  element={subitem.component}
                />
              ))}
          </Route>
        ))}
      </Route>
      <Route element={<DashboardRouter />}>
        <Route path="page/esewa_payment_success" element={<EsewaSuccess />} />
        <Route path="page/esewa_payment_failed" element={<EsewaFailure />} />
        <Route path="page/khalti_payment" element={<KhaltiRedirect />} />
        {AllRoutes.dashboardRoute.map((item, id) => (
          <Route
            key={id}
            path={item.link}
            element={
              <ProtectedRoute user={isLoggedIn}>
                {isEmailVerified ? item.component : <NotVerified />}
              </ProtectedRoute>
            }
          ></Route>
        ))}
      </Route>
    </Routes>
  );
}
const ProtectedRoute = ({ user, children }) => {
  if (!user) {
    window.location.pathname = "/";
    return <Navigate to="/" replace />;
  }
  return children;
};
export default AppRouter;
