import { BASE_URL } from "../Constants";
import axios from "axios";

export const sign_in = async (email, password) => {
  let url = `${BASE_URL}/api/login`;
  let loginDetails = {
    email,
    password,
  };
  return await axios.post(url, loginDetails);
};

export const sign_up = async (data) => {
  let url = `${BASE_URL}/api/register`;
  return await axios.post(url, data);
};
export const sign_out = async (data) => {
  let url = `${BASE_URL}/api/logout`;
  const formData = new FormData();
  formData.append("device_id", data.deviceToken);
  formData.append("device_type", "web");
  return await axios.post(url, formData, {
    headers: {
      Authorization: `Bearer ${data.token}`,
    },
  });
};

export const forgot_password = async (email) => {
  let url = `${BASE_URL}/api/forgot-password`;
  let details = {
    email,
  };
  return await axios.post(url, details);
};

export const verify_otp = async (data) => {
  let url;
  if (data.email) {
    url = `${BASE_URL}/api/verify-otp/${data.email}`;
    const otpForm = new FormData();
    otpForm.append("otp", data.otp);
    return await axios.post(url, otpForm);
  } else {
    url = `${BASE_URL}/api/verify-otp`;
    const otpForm = new FormData();
    otpForm.append("otp", data.otp);
    return await axios.post(url, otpForm, {
      headers: {
        Authorization: `Bearer ${data.token}`,
      },
    });
  }
};
export const resend_otp = async (data) => {
  let url = `${BASE_URL}/api/resend-otp`;
  const email = new FormData();
  email.append("email", data.email);
  return await axios.post(url, email);
};

export const create_new_password = async (details) => {
  let url = `${BASE_URL}/api/create-new-password`;
  return await axios.post(url, details.passwords, {
    headers: {
      Authorization: `Bearer ${details.otpToken}`,
    },
  });
};

export const login_withSocial = async (data) => {
  let url = `${BASE_URL}/api/login/${data.provider}/callback`;
  return await axios.post(url, data.formData);
};

export const firebase_Push = async (data) => {
  let url = `${BASE_URL}/api/store-device-token/web`;
  const formData = new FormData();
  formData.append("device_id", data.device_id);
  formData.append("device_type", data.device_type);
  return await axios.post(url, formData, {
    headers: {
      Authorization: `Bearer ${data.token}`,
    },
  });
};