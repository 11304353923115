import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Container } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { logoutFunc, signOut } from "../../../Features/AuthSlice";
import {
  emptyProfile,
  getProfile,
} from "../../../Features/Profile_management/profileSlice";
import { clearAllValue } from "../../../Features/SetsAPI/LocalSetSlice";
import { clearSession } from "../../../Features/SetsAPI/SetsSlice";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router-dom";
import fallBackImg from "../../../Assets/img/fallbackimg.png";
import NotificationComponent from "./Notification/NotificationComponent";
export default function DashboardHeader() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let isEmailVerified = useSelector((state) => state.auth.isEmailVerified);
  let site_Info = useSelector((state) => state.home.siteInfo);
  let token = useSelector((state) => state.auth.currentUser.token);
  let deviceToken = useSelector(
    (state) => state.auth?.deviceToken
  );
  let profileInfo = useSelector((state) => state.profile.profileInfo);
  useEffect(() => {
    isEmailVerified && dispatch(getProfile(token));
  }, [dispatch, token, isEmailVerified]);
  const [show, setShow] = useState(false);
  const [urlToHeader, setUrlToHeader] = useState("");
  useEffect(() => {
    let header = window.location.pathname.split("/");
    if (header.length >= 3) {
      let newhead = header[2]
        .split("-")
        .join(" ")
        .replace("ubt", "UBT")
        .replace("buy", " ")
        .split("-")
        .join(" ");
      setUrlToHeader(newhead);
    } else {
      let newhead = header[1];
      setUrlToHeader(newhead);
    }
  }, [window.location.href]);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <header className="dashboard__header">
        <Container fluid>
          <div className="d-flex justify-content-between align-items-center flex-wrap">
            <div>
              {/* <div className="logo">
                <a href="/#">
                  <img src={Logo} alt="Learn Korean With Bibek" />
                </a>
              </div> */}
              <div className="dashboard__title">
                <h3>LKWB {urlToHeader}</h3>
              </div>
            </div>
            {isEmailVerified && (
              <>
                <div>
                  <div className="dashboard__right">
                    {/* <a href="/#" className="dashboard__settings">
                      <i className="bi bi-gear"></i>
                    </a> */}
                    <NotificationComponent />
                    {profileInfo && (
                      <div className="dashboard__user hovermenu-container">
                        <Link to="/dashboard/profile">
                          <img
                            src={
                              profileInfo.image
                                ? profileInfo.image
                                : fallBackImg
                            }
                            alt={profileInfo.name}
                          />
                          <div className="profile-text">
                            <p>{profileInfo.email}</p>
                            <p>{profileInfo.name}</p>
                          </div>
                        </Link>
                        <ul className="dashboard-settings hovermenu">
                          <li>
                            <Link to="/dashboard/profile">View Profile</Link>
                          </li>
                          <li>
                            <Link to="/dashboard/editprofile">
                              Edit Profile
                            </Link>
                          </li>

                          <li>
                            <Link to="/dashboard/favorite">Favorite</Link>
                          </li>
                          <li>
                            <Link onClick={handleShow}>About LKWB</Link>
                          </li>
                          <li>
                            <Link
                              onClick={(e) => {
                                e.preventDefault();
                                dispatch(signOut({ token, deviceToken }));
                                dispatch(emptyProfile());
                                navigate("/", { replace: true });
                                dispatch(logoutFunc());
                                dispatch(clearAllValue());
                                dispatch(clearSession());
                              }}
                            >
                              Logout
                            </Link>
                          </li>
                        </ul>
                      </div>
                    )}
                  </div>
                </div>

                <Modal show={show} onHide={handleClose}>
                  <Modal.Header closeButton>
                    <Modal.Title>Learn Korean With Bibek</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <div className="aboutModal">
                      <p>App Version: 1.0</p>
                      <h5>Contact Details:</h5>
                      <ul class="undefined modalContact footer-links">
                        <li>
                          <i class="bi bi-geo-alt-fill"></i>
                          <span>{site_Info.address}</span>
                        </li>
                        <li>
                          <i class="bi bi-telephone-fill"></i>
                          <a href={`tel:${site_Info.phone}`}>
                            {site_Info.phone}
                          </a>
                        </li>
                        <li>
                          <i class="bi bi-envelope-fill"></i>
                          <a
                            aria-label={`${site_Info.email}`}
                            href={`mailto:${site_Info.email}`}
                          >
                            {`${site_Info.email}`}
                          </a>
                        </li>
                      </ul>
                    </div>
                  </Modal.Body>
                </Modal>
              </>
            )}
          </div>
        </Container>
      </header>
    </>
  );
}
