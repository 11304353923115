import React from "react";

const QuestionOption = ({
  label,
  optionId,
  selected,
  value,
  handleClick,
  ifCorrect,
  type,
  correctValue,
  wasSelected,
}) => {
  const makeActive = (selected, wasSelected, correctValue) => {
    if (type === "questionItem") {
      if (parseInt(value) === parseInt(selected)) {
        return "selected";
      } else {
        return " ";
      }
    }
    if (type === "resultItem") {
      if (wasSelected && correctValue === 0) {
        return "notcorrect";
      }
      if (wasSelected === undefined && correctValue === 1) {
        return "correct";
      }
      if (correctValue === 1) {
        return "correct";
      }
      // if (ifCorrect) {
      //   if (wasSelected) {
      //     return "notcorrect";
      //   }
      //   return "correct";
      // } else {
      //   if (wasSelected && !ifCorrect) {
      //     return "notcorrect";
      //   }
      //   return " ";
      // }
    }
  };
  return (
    <div
      className={`questionOption ${makeActive(
        selected,
        wasSelected,
        correctValue
      )} ${type === "resultItem" ? "resultItem" : ""}`}
    >
      <span onClick={handleClick} id={value}>
        {++optionId}
      </span>
      <p>{label}</p>
    </div>
  );
};

export default QuestionOption;
