import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { homeUBT, singleService } from "../../../Features/HomeApi/HomeSlice";
import Loader from "../Loader/Loader";
import { Col, Container, Row } from "react-bootstrap";
import SetsItem from "../../DashboardView/Components/SetsItem";
import { openModal, openLoginForm } from "../../../Features/ViewSlice";
import { ReactComponent as PageBannerSVG } from "../../../Assets/img/lkwb.svg";
import { useNavigate } from "react-router";
import { allSets } from "../../../Features/SetsAPI/SetsSlice";
import PageHeadings from "../PageHeadings";
const ModuleUBT = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let ubtItems = useSelector((state) => state.home.homeModule);
  let token = useSelector((state) => state.auth?.currentUser?.token);
  let allSetslist = useSelector((state) => state.set.allSets);
  let selectedService = useSelector((state) => state.home.selectedSingleService);
  const loginModel = () => {
    if (!token) {
      dispatch(openModal("loginView"));
      dispatch(openLoginForm());
    } else {
      navigate("/dashboard/ubt-test", { replace: true });
    }
  };
  let isLoading = useSelector((state) => state.home.homeModuleLoading);
  useEffect(() => {
    dispatch(singleService({ id: 1 }));
    if (!token) {
      dispatch(homeUBT());
    } else {
      dispatch(allSets(token));
    }
  }, [dispatch, token]);
  return (
    <>
      {!isLoading ? (
        <>
          {ubtItems && (
            <>
              <PageHeadings item={selectedService} hasIcon="true" />
              <Container>
                <div className="services-lists">
                  <h4>Here are the list of UBT Test that are available in our premium version. You must be logged in in order to access the test.</h4>
                  <Row className="setstitem-container align-items-center">
                    {!token &&
                      ubtItems.slice(0, 6).map((item, id) => (
                        <Col md={6} xs={12}>
                          <SetsItem key={id} details={item} type="home" handleClick={loginModel} />
                        </Col>
                      ))}
                    {token &&
                      allSetslist.slice(0, 6).map((item, id) => (
                        <Col md={6} xs={12}>
                          <SetsItem key={id} details={item} />
                        </Col>
                      ))}
                  </Row>
                  <div className="text-center" onClick={loginModel}>
                    <button className="btn">Explore More UBT Tests</button>
                  </div>
                </div>
              </Container>
            </>
          )}
        </>
      ) : (
        <div className="loading__mode">
          <Loader />
        </div>
      )}
    </>
  );
};

export default ModuleUBT;
