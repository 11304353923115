import {
  ServicesPage,
  Page404,
  BlogSingle,
  Page,
  FAQPage,
} from "./views/pages/routespages";
import {
  DashboardBlog,
  Dictionary,
  DictionaryDetails,
  Discussions,
  DiscussionSingle,
  Grammer,
  GrammarDetails,
  UBT,
  UBTPurchasedSet,
  UBTTestBuy,
  Profile,
  EditProfile,
  UBTCourse,
  EachDBBlog,
  DashboardResults,
  DashboardViewAllResults,
  Favorite,
  NotificationPage,
  UBTTestInvoice,
  UBTTestQuestions,
} from "./views/DashboardView/Container/routesDashboard";
import BooksNotes from "./views/Containers/ProductTab/BooksNotes";
import Books from "./views/Containers/ProductTab/Books";
import Voculabary from "./views/Containers/ProductTab/Voculabary";
import BooksNotesSingle from "./views/Containers/ProductTab/BooksNotesSingle";
import BooksSingle from "./views/Containers/ProductTab/BooksSingle";
import Chapterscontent from "./views/Containers/ProductTab/Chapterscontent";
import Dashboard from "./views/DashboardView/Dashboard";
import MainContainer from "./views/Containers/MainContainer";
import Homeimg from "./Assets/img/lkwb-dash-home.png";
import UbtTestImg from "./Assets/img/lkwb-dash-test.png";
import GrammarImg from "./Assets/img/lkwb-dash-grammar.png";
import DictionaryImg from "./Assets/img/lkwb-dash-dictionary.png";
import BlogImg from "./Assets/img/lkwb-dash-blogs.png";
import DiscussionImg from "./Assets/img/lkwb-dash-discussion.png";
import ModuleUBT from "./views/Components/HomeModules/ModuleUBT";
import ModuleDictionary from "./views/Components/HomeModules/ModuleDictionary";
import ModuleGrammar from "./views/Components/HomeModules/ModuleGrammar";
// import ModuleBlog from "./views/Components/HomeModules/ModuleBlog";
import ModuleDiscussion from "./views/Components/HomeModules/Modulediscussion";
import ModuleSingleGrammar from "./views/Components/HomeModules/ModuleSingleGrammer";
import ModuleSingleDictionary from "./views/Components/HomeModules/ModuleSingleDictionary";
import UBTPackageBuy from "./views/DashboardView/Container/UBTPages/UBTPackageBuy";
import UBTPackage from "./views/DashboardView/Container/UBTPages/UBTPackage";
import ModuleSingleDiscussion from "./views/Components/HomeModules/ModuleSingleDiscussion";
import GrammarMobile from "./views/Components/MobileView/GrammarMobile";
import DictionaryMobile from "./views/Components/MobileView/DictionaryMobile";
import VoculabarySingle from "./views/Containers/ProductTab/VoculabarySingle";
import VoculabaryChapterscontent from "./views/Containers/ProductTab/VoculabaryChapterscontent";

export const AllRoutes = {
  homeRoute: [
    {
      link: "/",
      label: "Home",
      component: <MainContainer />,
      inNavBar: true,
    },
    {
      link: "/grammar-mobileview",
      label: "Home",
      component: <GrammarMobile />,
      inNavBar: false,
    },
    {
      link: "/dictionary-mobileview",
      label: "Home",
      component: <DictionaryMobile />,
      inNavBar: false,
    },
    {
      link: "/aboutus",
      label: "About Us",
      component: (
        <Page url="https://secureadmin.lkwb.com.np/api/about-us/about-us" />
      ),
      inNavBar: true,
      submenu: [
        {
          link: "/mission-and-vision",
          label: "Mission & Vision",
          component: (
            <Page url="https://secureadmin.lkwb.com.np/api/about-us/mission-vision" />
          ),
        },
        {
          link: "/message-from-ceo",
          label: "Message from CEO",
          component: (
            <Page url="https://secureadmin.lkwb.com.np/api/about-us/message-from-ceo" />
          ),
        },
        {
          link: "/why-lkwb",
          label: "Why LKWB?",
          component: (
            <Page url="https://secureadmin.lkwb.com.np/api/about-us/why-lkwb" />
          ),
          inNavBar: true,
        },
      ],
    },
    {
      link: "/services",
      label: "Services",
      component: <ServicesPage />,
      inNavBar: true,
      submenu: [
        {
          link: "/ubt-test",
          label: "LKWB UBT Test",
          component: <ModuleUBT />,
          inNavBar: true,
        },
        {
          link: "/dictionary",
          label: "LKWB Dictionary",
          component: <ModuleDictionary />,
          inNavBar: true,
        },
        {
          link: "/grammar",
          label: "LKWB Grammar",
          component: <ModuleGrammar />,
          inNavBar: true,
        },
        {
          link: "/discussion",
          label: "LKWB Discussion",
          component: <ModuleDiscussion />,
          inNavBar: false,
        },

        // {
        //   link: "/blogs",
        //   label: "LKWB Blog",
        //   component: <ModuleBlog />,
        //   inNavBar: true,
        // },
      ],
    },
    {
      label: "Products",
      inNavBar: true,
      submenu: [
        {
          link: "/books-notes",
          label: "Books/Notes",
          component: <BooksNotes />,
        },

        {
          type: "model",
          link: localStorage.getItem("recordings"),
          label: "Recordings",
        },
        {
          type: "model",
          link: localStorage.getItem("onlineRedirect"),
          label: "Online Class",
        },
        {
          link: "/books",
          label: "Books",
          component: <Books />,
        },
        {
          link: "/voculabary",
          label: "Voculabary",
          component: <Voculabary />,
        },
      ],
    },
    {
      link: "/books-notes/booknotessingle/:id",
      label: "Books/Notes/Single",
      component: <BooksNotesSingle />,
      inNavBar: false,
    },

    {
      link: "/books/chapters/:id",
      label: "Books/single",
      component: <BooksSingle />,
      inNavBar: false,
    },

    {
      link: "/voculabary/chapters/:id",
      label: "Voculabary/single",
      component: <VoculabarySingle />,
      inNavBar: false,
    },

    {
      link: "/discussions/discussionsingle/:id",
      label: "LKWB Single Discussion",
      component: <ModuleSingleDiscussion />,
      inNavBar: false,
    },
    {
      link: "/chapters/:contentid",
      label: "Chapters",
      component: <Chapterscontent />,
      inNavBar: false,
    },
    {
      link: "/voculabarychapters/:contentid",
      label: "Voculabary Chapters",
      component: <VoculabaryChapterscontent />,
      inNavBar: false,
    },

    {
      link: "/grammar/grammardetail/:id",
      label: "GrammarDetails",
      component: <ModuleSingleGrammar />,
      inSideBar: false,
    },
    {
      link: "/dictionary/dictionarydetail/:id",
      label: "DictionaryDetails",
      component: <ModuleSingleDictionary />,
      inSideBar: false,
    },
    {
      link: "/contactus",
      label: "Contact",
      component: (
        <Page url="https://secureadmin.lkwb.com.np/api/pages/contact-us" />
      ),
      inNavBar: true,
    },

    {
      link: "/faq",
      label: "FAQ",
      component: <FAQPage />,
      inNavBar: false,
    },
    {
      link: "/terms-and-condition",
      label: "Terms and Condition",
      component: (
        <Page url="https://secureadmin.lkwb.com.np/api/pages/terms-and-condition" />
      ),
      inNavBar: false,
    },
    {
      link: "/privacy-policy",
      label: "Privacy Policy",
      component: (
        <Page url="https://secureadmin.lkwb.com.np/api/pages/privacy-policy" />
      ),
      inNavBar: false,
    },
    // {
    //   link: "/blog",
    //   label: "Blogs",
    //   component: <ModuleBlog />,
    //   inNavBar: true,
    // },
    {
      link: "/blog/:id",
      label: "Home",
      component: <BlogSingle />,
      inNavBar: false,
    },
    {
      link: "/*",
      label: "Page404",
      component: <Page404 />,
      inNavBar: false,
    },
  ],
  dashboardRoute: [
    {
      link: "/dashboard",
      label: "Home",
      icon: Homeimg,
      component: <Dashboard />,
      inSideBar: true,
    },
    {
      link: "/dashboard/notification",
      label: "Notification",
      component: <NotificationPage />,
      inSideBar: false,
    },
    {
      link: "/dashboard/ubt-test",
      label: "LKWB UBT Test",
      icon: UbtTestImg,
      component: <UBT />,
      inSideBar: true,
    },
    {
      link: "/dashboard/ubt-package-buy/:id",
      label: "LKWB UBT package",
      component: <UBTPackageBuy />,
    },
    {
      link: "/dashboard/ubt-package/:id",
      label: "LKWB UBT package",
      component: <UBTPackage />,
    },
    {
      link: "/dashboard/ubt-test/purchasedset",
      label: "UBT Purchased Set",
      component: <UBTPurchasedSet />,
      inSideBar: false,
    },
    {
      link: `/dashboard/ubt-test/ubt-test-buy/:id`,
      label: "UBT TestBuy",
      component: <UBTTestBuy />,
      inSideBar: false,
    },
    {
      link: `/dashboard/ubt-test/ubt-test-invoice`,
      label: "UBT TestBuy",
      component: <UBTTestInvoice />,
      inSideBar: false,
    },
    {
      link: `/dashboard/ubt-test/ubt-course/:id`,
      label: "UBT Course",
      component: <UBTCourse />,
      inSideBar: false,
    },
    {
      link: `/dashboard/ubt-test/ubt-test-questions/:setsid`,
      label: "UBT Test Questions",
      component: <UBTTestQuestions />,
      inSideBar: false,
    },
    {
      link: "/dashboard/grammar",
      label: "LKWB Grammar",
      icon: DictionaryImg,
      component: <Grammer />,
      inSideBar: true,
    },
    {
      link: "/dashboard/grammar/grammardetail/:id",
      label: "GrammarDetails",
      component: <GrammarDetails />,
      inSideBar: false,
    },
    {
      link: "/dashboard/dictionary",
      label: "LKWB Dictionary",
      icon: GrammarImg,
      component: <Dictionary />,
      inSideBar: true,
    },
    // {
    //   link: "/dashboard/dictionary/dictionaryconvert",
    //   label: "Dictionary Convert",
    //   component: <DictionaryConvert />,
    //   inSideBar: false,
    // },
    {
      link: "/dashboard/dictionary/dictionarydetail/:id",
      label: "Dictionary Details",
      component: <DictionaryDetails />,
      inSideBar: false,
    },
    {
      link: "/dashboard/blogs",
      label: "LKWB Blogs",
      icon: BlogImg,
      component: <DashboardBlog />,
      inSideBar: true,
    },
    {
      link: "/dashboard/blog/:id",
      label: "Blogs",
      component: <EachDBBlog />,
      inSideBar: false,
    },
    {
      link: "/dashboard/discussions",
      label: "LKWB Discussions",
      icon: DiscussionImg,
      component: <Discussions />,
      inSideBar: true,
    },
    {
      link: "/dashboard/discussions/discussionsingle/:id",
      label: "LKWB Discussions",
      component: <DiscussionSingle />,
      inSideBar: false,
    },
    {
      link: "/dashboard/profile",
      label: "Profile",
      component: <Profile />,
      inSideBar: false,
    },
    {
      link: "/dashboard/editprofile",
      label: "Profile",
      component: <EditProfile />,
      inSideBar: false,
    },
    {
      link: "/dashboard/dashboard-results",
      label: "Dashboard Results",
      component: <DashboardResults />,
      inSideBar: false,
    },
    {
      link: "/dashboard/dashboard-viewall-results",
      label: "Dashboard View Results",
      component: <DashboardViewAllResults />,
      inSideBar: false,
    },

    {
      link: "/dashboard/favorite",
      label: "Favorites",
      component: <Favorite />,
      inSideBar: false,
    },
  ],
};
