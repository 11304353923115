import { Outlet } from "react-router-dom";
import Navbar from "./views/Components/navbar/navbar";
import Footer from "./views/Containers/Footer";
const HomeRouter = () => (
  <>
    <Navbar />
    <Outlet />
    <Footer />
  </>
);

export default HomeRouter;
