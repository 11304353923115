import React, { useState, useRef } from "react";
import fallbackimg from "../../../Assets/img/fallbackimg.png";
import { useDispatch, useSelector } from "react-redux";
import {
  updateReply,
  deleteReply,
  deleteRepliesWithID,
} from "../../../Features/DiscussionsAPI/DiscussionsSlice";
const DiscussionReplies = ({ comment, commentID }) => {
  const dispatch = useDispatch();
  const [isEditable, setEditable] = useState(false);
  const [commentValue, setCommentValue] = useState("");
  let token = useSelector((state) => state.auth.currentUser.token);
  let currentUser = useSelector((state) => state.profile.profileInfo.name);
  const handleEditable = () => {
    setEditable(true);
    setCommentValue(comment.body);
  };
  const closeEditable = () => {
    setEditable(false);
    setCommentValue(" ");
  };
  const handleEditComment = (e) => {
    setCommentValue(e.target.value);
  };
  const updateReplyAction = (id) => {
    const formData = new FormData();
    formData.append("_method", "PUT");
    formData.append("body", commentValue);
    dispatch(updateReply({ token, formData, id }));
    closeCommentMenu();
    handleEditable();
  };
  const showCommentMenu = () => {
    setShowUserMenu(true);
  };
  const closeCommentMenu = () => {
    setShowUserMenu(false);
  };
  const deleteReplyAction = (data) => {
    const formData = new FormData();
    formData.append("_method", "DELETE");
    dispatch(deleteReply({ token, formData, id: data.replyId }));
    dispatch(
      deleteRepliesWithID({
        comment_id: commentID,
        reply_id: data.replyId,
      })
    );
    closeCommentMenu();
  };
  const [showUserMenu, setShowUserMenu] = useState(false);
  let userMenu = useRef(null);
  const detectClickOutside = () => {
    window.addEventListener("mousedown", (e) => {
      if (userMenu.current && !userMenu.current.contains(e.target)) {
        setShowUserMenu(false);
      }
    });
  };
  detectClickOutside();
  return (
    <div className="comment-details">
      <div className="d-flex justify-content-between">
        <div className="d-flex flex-wrap">
          <div className="person-image">
            <img
              src={comment.user_image ? comment.user_image : fallbackimg}
              alt="test"
            />
            <div className="">
              <h5>{comment.user_name} </h5>
              <p>{comment.created_at}</p>
            </div>
          </div>
          <div className="commentDetails">
            <h5>
              {comment.user_name} <span>{comment.display_date}</span>
            </h5>
            {isEditable ? (
              <>
                <textarea
                  value={commentValue}
                  onChange={handleEditComment}
                ></textarea>
                {/* <input type="text"></input> */}

                <div className="d-flex commentUserMenu">
                  <div
                    onClick={() => {
                      closeEditable();
                    }}
                  >
                    <button className="btn btn-small btn-secondary">
                      Cancel
                    </button>
                  </div>
                  <div
                    onClick={() => {
                      closeEditable();
                      updateReplyAction(comment.id);
                    }}
                  >
                    <button className="btn btn-small">Save</button>
                  </div>
                </div>
              </>
            ) : (
              <p>{comment.body}</p>
            )}
          </div>
        </div>
        {comment.user_name === currentUser && (
          <div className="user-menu">
            <i
              className="bi bi-three-dots-vertical"
              onClick={showCommentMenu}
            ></i>
            {showUserMenu && (
              <ul className="userMenuOption" ref={userMenu}>
                {!isEditable ? (
                  <li
                    onClick={() => {
                      handleEditable();
                      closeCommentMenu();
                    }}
                  >
                    Edit Comment
                  </li>
                ) : (
                  <li className="userMenuOption-list">
                    <div
                      onClick={() => {
                        closeEditable();
                        updateReplyAction(comment.id);
                      }}
                    >
                      <i className="bi bi-check2"></i>
                    </div>
                    <div
                      onClick={() => {
                        closeEditable();
                      }}
                    >
                      <i className="bi bi-x-lg"></i>
                    </div>
                  </li>
                )}
                <li
                  onClick={() =>
                    deleteReplyAction({
                      replyId: comment.id,
                    })
                  }
                >
                  Delete Comment
                </li>
              </ul>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default DiscussionReplies;
