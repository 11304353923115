import { BASE_URL } from "../../Constants";
import axios from "axios";

export const get_profile = async (token) => {
  let url = `${BASE_URL}/api/profile`;
  let headers = {
    authorization: `Bearer ${token}`,
  };
  const res = await axios.get(url, {
    headers: headers,
  });
  return res;
};

export const update_profile = async ({ authToken, formData }) => {
  let url = `${BASE_URL}/api/update-profile`;
  let headers = {
    authorization: `Bearer ${authToken}`,
  };
  const res = await axios.post(url, formData, { headers });
  return res;
};

export const result_History = async (token) => {
  let url = `${BASE_URL}/api/result-history`;
  let headers = {
    authorization: `Bearer ${token}`,
  };
  const res = await axios.get(url, {
    headers: headers,
  });
  return res;
};

export const word_of_theday = async (token) => {
  let url = `${BASE_URL}/api/words-of-today`;
  const res = await axios.get(url);
  return res;
};

export const delete_user = async (data) => {
  let url = `${BASE_URL}/api/delete-account`;
  let headers = {
    authorization: `Bearer ${data.token}`,
  };
  const formData = new FormData();
  formData.append("_method", "DELETE");
  formData.append("otp", data.otp);
  const res = await axios.post(url, formData, {
    headers: headers,
  });
  return res;
};
