import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  site_info,
  banner_info,
  aboutus_info,
  services_info,
  faq_info,
  blog_info,
  menu_info,
  home_UBT,
  single_service,
  home_grammer_cat,
  home_Grammmar,
  home_Dictionary,
  single_home_Dictionary,
  single_home_Grammmar,
  allDictionary,
  all_product,
  blog_category,
  blog_category_single,
  single_booknotes,
} from "./HomeApi";
const initialState = {
  appVersion: 0,
  isLoading: false,
  siteInfo: {},
  bannerInfo: {},
  aboutusInfo: {},
  servicesInfo: {},
  faqInfo: [],
  blogInfo: [],
  singleBlogContent: {},
  socialLinks: [],
  menuLinks: [],
  bottomLinks: [],
  aboutLinks: [],
  resourcesLinks: [],
  homeModuleLoading: false,
  selectedSingleService: {},
  homeModule: [],
  homeGrammerCat: [],
  singleHomeModule: {},
  allDictionary: [],
  errorHandle: "",
  allProduct: {},
  allblogcategory: [],
  selectedBlogCategory: "",
  singlebooknotes: {},
};
export const singleBookNote = createAsyncThunk(
  "home/singlebooknote",
  async (data) => {
    try {
      return await single_booknotes(data);
    } catch (err) {
      return err;
    }
  }
);
export const allblogCateogory = createAsyncThunk(
  "home/allblogcategory",
  async () => {
    try {
      return await blog_category();
    } catch (err) {
      return err;
    }
  }
);
export const blogcategorysingle = createAsyncThunk(
  "home/blogcategorysingle",
  async (data) => {
    try {
      return await blog_category_single(data);
    } catch (err) {
      return err;
    }
  }
);
export const allProducts = createAsyncThunk("home/allProduct", async () => {
  try {
    return await all_product();
  } catch (error) {
    return error;
  }
});
export const collectDictionary = createAsyncThunk(
  "home/collectDictionary",
  async () => {
    try {
      return await allDictionary();
    } catch (error) {
      return error;
    }
  }
);
export const siteInfo = createAsyncThunk("home/siteInfo", async (data) => {
  try {
    return await site_info(data);
  } catch (error) {
    return error;
  }
});

export const bannerInfo = createAsyncThunk("home/bannerInfo", async (data) => {
  try {
    return await banner_info(data);
  } catch (error) {
    return error;
  }
});

export const aboutusInfo = createAsyncThunk(
  "home/aboutusInfo",
  async (data) => {
    try {
      return await aboutus_info(data);
    } catch (error) {
      return error;
    }
  }
);

export const servicesInfo = createAsyncThunk(
  "home/servicesInfo",
  async (data) => {
    try {
      return await services_info(data);
    } catch (error) {
      return error;
    }
  }
);

export const faqInfo = createAsyncThunk("home/faqInfo", async (data) => {
  try {
    return await faq_info(data);
  } catch (error) {
    return error;
  }
});

export const blogInfo = createAsyncThunk("home/blogInfo", async (data) => {
  try {
    return await blog_info(data);
  } catch (error) {
    return error;
  }
});

export const menuInfo = createAsyncThunk("home/menuInfo", async (data) => {
  try {
    return await menu_info(data);
  } catch (error) {
    return error;
  }
});
export const singleService = createAsyncThunk(
  "home/singleService",
  async (data) => {
    try {
      return await single_service(data);
    } catch (error) {
      return error;
    }
  }
);
export const homeUBT = createAsyncThunk("home/homeUBT", async () => {
  try {
    return await home_UBT();
  } catch (error) {
    return error;
  }
});
export const homeDictionary = createAsyncThunk(
  "home/homeDictionary",
  async (data) => {
    try {
      return await home_Dictionary(data);
    } catch (error) {
      return error;
    }
  }
);
export const homeGrammar = createAsyncThunk("home/homeGrammar", async () => {
  try {
    return await home_Grammmar();
  } catch (error) {
    return error;
  }
});
export const homeGrammarCat = createAsyncThunk(
  "home/homeGrammarCat",
  async () => {
    try {
      return await home_grammer_cat();
    } catch (error) {
      return error;
    }
  }
);
export const singleHomeGrammar = createAsyncThunk(
  "home/singleGrammar",
  async (data) => {
    try {
      return await single_home_Grammmar(data);
    } catch (error) {
      return error;
    }
  }
);
export const singleHomeDictionary = createAsyncThunk(
  "home/singleDictionary",
  async (data) => {
    try {
      return await single_home_Dictionary(data);
    } catch (error) {
      return error;
    }
  }
);
const HomeSlice = createSlice({
  name: "Home",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(singleBookNote.pending, (state, action) => {
        state.isLoading = true;
        state.singlebooknotes = {};
      })
      .addCase(singleBookNote.fulfilled, (state, action) => {
        state.isLoading = false;
        state.singlebooknotes = action.payload.data.data;
      })
      .addCase(singleBookNote.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(allblogCateogory.fulfilled, (state, action) => {
        state.allblogcategory = action.payload.data.data;
      })
      .addCase(blogcategorysingle.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(blogcategorysingle.fulfilled, (state, action) => {
        state.blogInfo = action.payload.data.data.blogs;
        state.selectedBlogCategory = action.payload.data.data.title;
        state.isLoading = false;
      })
      .addCase(blogcategorysingle.rejected, (state, action) => {
        state.blogInfo = [];
        state.isLoading = false;
      })
      .addCase(siteInfo.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(siteInfo.fulfilled, (state, action) => {
        state.isLoading = false;
        state.siteInfo = action.payload.data.data;
      })
      .addCase(siteInfo.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(bannerInfo.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(bannerInfo.fulfilled, (state, action) => {
        state.isLoading = false;
        state.bannerInfo = action.payload.data.data;
      })
      .addCase(bannerInfo.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(aboutusInfo.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(aboutusInfo.fulfilled, (state, action) => {
        state.isLoading = false;
        state.aboutusInfo = action.payload.data.data;
      })
      .addCase(aboutusInfo.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(servicesInfo.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(servicesInfo.fulfilled, (state, action) => {
        state.isLoading = false;
        state.servicesInfo = action.payload.data.data;
      })
      .addCase(servicesInfo.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(faqInfo.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(faqInfo.fulfilled, (state, action) => {
        state.isLoading = false;
        state.faqInfo = action.payload.data.data;
      })
      .addCase(faqInfo.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(blogInfo.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(blogInfo.fulfilled, (state, action) => {
        state.isLoading = false;
        state.selectedBlogCategory = " ";
        state.blogInfo = action.payload.data.data;
      })
      .addCase(blogInfo.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(menuInfo.fulfilled, (state, action) => {
        state.isLoading = false;
        state.bottomLinks = action.payload.data.data.find(
          (item) => item.id === 1 && item.slug === "bottom-footer-left-menu"
        ).menu_items;
        state.menuLinks = action.payload.data.data.find(
          (item) => item.id === 2 && item.slug === "top-navigation-menu"
        ).menu_items;
        state.aboutLinks = action.payload.data.data.find(
          (item) => item.id === 3 && item.slug === "footer-company-menu"
        ).menu_items;
        state.resourcesLinks = action.payload.data.data.find(
          (item) => item.id === 4 && item.slug === "footer-resources-menu"
        ).menu_items;
      })
      .addCase(homeUBT.pending, (state, action) => {
        state.homeModule = [];
        state.homeModuleLoading = true;
      })
      .addCase(homeUBT.fulfilled, (state, action) => {
        state.homeModule = action.payload.data.data;
        state.homeModuleLoading = false;
      })
      .addCase(homeDictionary.pending, (state, action) => {
        state.homeModuleLoading = true;
      })
      .addCase(homeDictionary.fulfilled, (state, action) => {
        state.homeModuleLoading = false;
        if (action.payload.data.data.length > 0) {
          let allData = new Set([
            ...state.allDictionary,
            ...action.payload.data.data,
          ]);
          state.allDictionary = [...allData];
        } else {
          state.errorHandle = "No More Data To Load";
        }
      })
      .addCase(homeGrammar.pending, (state, action) => {
        state.homeModuleLoading = false;
        state.homeModuleLoading = true;
        state.homeModule = [];
      })
      .addCase(homeGrammar.fulfilled, (state, action) => {
        state.homeModuleLoading = false;
        state.homeModule = action.payload.data.data;
      })
      .addCase(singleHomeGrammar.pending, (state, action) => {
        state.homeModuleLoading = false;
        state.homeModuleLoading = true;
        state.homeModule = {};
      })
      .addCase(singleHomeGrammar.fulfilled, (state, action) => {
        state.homeModuleLoading = false;
        state.singleHomeModule = action.payload.data.data;
      })
      .addCase(singleHomeDictionary.pending, (state, action) => {
        state.homeModuleLoading = true;
        state.homeModuleLoading = false;
        state.homeModule = {};
      })
      .addCase(singleHomeDictionary.fulfilled, (state, action) => {
        state.homeModuleLoading = false;
        state.singleHomeModule = action.payload.data.data;
      })
      .addCase(collectDictionary.fulfilled, (state, action) => {
        localStorage.setItem(
          "allDictionary",
          JSON.stringify(action.payload.data.data)
        );
      })
      .addCase(singleService.pending, (state, action) => {
        state.isLoading = true;
        state.selectedSingleService = "";
      })
      .addCase(singleService.fulfilled, (state, action) => {
        state.isLoading = false;
        state.selectedSingleService = action.payload.data.data;
      })
      .addCase(homeGrammarCat.fulfilled, (state, action) => {
        state.homeGrammerCat = action.payload.data.data;
      })
      .addCase(allProducts.fulfilled, (state, action) => {
        state.allProduct = action.payload.data.data;
        localStorage.setItem(
          "onlineRedirect",
          action.payload.data.data.online_classes.url
        );
        localStorage.setItem(
          "recordings",
          action.payload.data.data.recordings.url
        );
      });
  },
});
export default HomeSlice.reducer;
