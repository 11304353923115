import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  sign_in,
  sign_up,
  forgot_password,
  verify_otp,
  create_new_password,
  login_withSocial,
  resend_otp,
  firebase_Push,
  sign_out,
} from "./AuthApi";
import { toast } from "react-toastify";
const initialState = {
  isLoggedIn: false,
  currentUser: {},
  isEmailVerified: false,
  isLoading: false,
  loginErroMessage: [],
  otpToken: null,
  registerTempData: {},
  newUserToast: false,
  deviceToken: "",
};
export const signOut = createAsyncThunk(
  "auth/signOut",
  async (data, { rejectWithValue }) => {
    try {
      return await sign_out(data);
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err);
    }
  }
);
export const firebasePush = createAsyncThunk(
  "auth/firebasepush",
  async (data, { rejectWithValue }) => {
    try {
      return await firebase_Push(data);
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err);
    }
  }
);
export const loginWithSocial = createAsyncThunk("auth/google", async (data) => {
  try {
    return await login_withSocial(data);
  } catch (err) {
    return err;
  }
});
export const login = createAsyncThunk(
  "auth/login",
  async ({ email, password }, { rejectWithValue }) => {
    try {
      return await sign_in(email, password);
    } catch (error) {
      if (!error.response) throw error;
      return rejectWithValue(error);
    }
  }
);

export const register = createAsyncThunk(
  "auth/register",
  async (data, { rejectWithValue }) => {
    try {
      return await sign_up(data);
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err);
    }
  }
);

export const emailVerify = createAsyncThunk(
  "auth/emailVerify",
  async (data, { rejectWithValue }) => {
    try {
      return await verify_otp(data);
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err);
    }
  }
);

export const otpResend = createAsyncThunk(
  "auth/otpResend",
  async (data, { rejectWithValue }) => {
    try {
      return await resend_otp(data);
    } catch (error) {
      if (!error.response) throw error;
      return rejectWithValue(error);
    }
  }
);
export const forgotPassword = createAsyncThunk(
  "auth/forgotPassword",
  async (data, { rejectWithValue }) => {
    try {
      return await forgot_password(data);
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err);
    }
  }
);

export const createNewPassword = createAsyncThunk(
  "auth/createNewPassword",
  async (password, { rejectWithValue }) => {
    try {
      return await create_new_password(password);
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err);
    }
  }
);
const AuthSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    saveDeviceToken: (state, action) => {
      state.deviceToken = action.payload;
    },
    hasDisplayedToast: (state) => {
      state.newUserToast = true;
    },
    loadingStart: (state) => {
      state.isLoading = true;
    },
    loadingEnd: (state) => {
      state.isLoading = false;
    },
    resetOTPToken: (state) => {
      state.otpToken = null;
    },
    logoutFunc: (state) => {
      state.isLoading = false;
      state.isEmailVerified = false;
      state.otpToken = null;
      state.isLoggedIn = false;
      state.currentUser = "";
      state.deviceToken = "";
      state.registerTempData = {};
      state.newUserToast = false;
      state.loginErroMessage = [];
      localStorage.clear();
    },
    saveTempData: (state, action) => {
      state.registerTempData = action.payload;
    },
    clearTempData: (state) => {
      state.registerTempData = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state, action) => {
        state.isLoading = true;
        state.loginErroMessage = [];
      })
      .addCase(login.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isLoggedIn = true;
        state.loginErroMessage = [];
        state.currentUser = action.payload.data.data;
        state.isEmailVerified =
          action.payload.data.data.email_verification === "Verified"
            ? true
            : false;
        toast.success("LoggedIn successfully");
      })
      .addCase(login.rejected, (state, action) => {
        state.isLoading = false;
        state.isLoggedIn = false;
        state.currentUser = null;
        // state.loginErroMessage = [
        //   ...state.loginErroMessage,
        //   action.payload.response.data.message,
        // ];
        if (typeof action.payload.response.data.message === "object") {
          for (const [key, value] of Object.entries(
            action.payload.response.data.message
          )) {
            state.loginErroMessage.push({ key, value });
          }
          state.loginErroMessage.forEach((item) => {
            toast.error(item.value[0]);
          });
        }
        if (typeof action.payload.response.data.message === "string") {
          state.loginErroMessage.push(action.payload.response.data.message);
          state.loginErroMessage.forEach((item) => {
            toast.error(item);
          });
        }
        state.isEmailVerified = false;
      })
      .addCase(register.pending, (state) => {
        state.isLoading = true;
        state.loginErroMessage = [];
      })
      .addCase(register.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isLoggedIn = true;
        state.isEmailVerified =
          action.payload.data.data.email_verification === "Verified"
            ? true
            : false;
        state.currentUser = action.payload.data.data;
        state.loginErroMessage = [];
        toast.success("Registered successfully");
      })
      .addCase(register.rejected, (state, action) => {
        state.isLoading = false;
        state.isEmailVerified = false;
        if (typeof action.payload.response.data.message === "object") {
          for (const [key, value] of Object.entries(
            action.payload.response.data.message
          )) {
            state.loginErroMessage.push({ key, value });
          }

          state.loginErroMessage.forEach((item) => {
            toast.error(item.value[0]);
          });
        }
        if (typeof action.payload.response.data.message === "string") {
          state.loginErroMessage.push(action.payload.response.data.message);
          state.loginErroMessage.forEach((item) => {
            toast.error(item);
          });
        }
      })
      .addCase(emailVerify.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(emailVerify.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isEmailVerified = true;
        state.otpToken = action.payload.data.data.token;
        toast.success("Email Verified successfully");
      })
      .addCase(emailVerify.rejected, (state, action) => {
        state.isLoading = false;
        state.isEmailVerified = false;
        if (typeof action.payload.response.data.message === "object") {
          for (const [key, value] of Object.entries(
            action.payload.response.data.message
          )) {
            state.loginErroMessage.push({ key, value });
          }
          state.loginErroMessage.forEach((item) => {
            toast.error(item.value[0]);
          });
        }
        if (typeof action.payload.response.data.message === "string") {
          toast.error(action.payload.response.data.message);
        }
      })
      .addCase(forgotPassword.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(forgotPassword.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isEmailVerified = true;
      })
      .addCase(forgotPassword.rejected, (state, action) => {
        state.isLoading = false;
        state.isEmailVerified = false;
        if (typeof action.payload.response.data.message === "object") {
          for (const [key, value] of Object.entries(
            action.payload.response.data.message
          )) {
            state.loginErroMessage.push({ key, value });
          }
          state.loginErroMessage.forEach((item) => {
            toast.error(item.value[0]);
          });
        }
        if (typeof action.payload.response.data.message === "string") {
          toast.error(action.payload.response.data.message);
        }
      })
      .addCase(createNewPassword.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createNewPassword.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isEmailVerified = true;
        toast.success("Password Changed successfully");
      })
      .addCase(createNewPassword.rejected, (state, action) => {
        state.isLoading = false;
        state.isEmailVerified = false;
      })
      .addCase(loginWithSocial.pending, (state) => {
        state.isLoading = true;
        state.currentUser = null;
      })
      .addCase(loginWithSocial.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isLoggedIn = true;
        state.currentUser = action.payload.data.data;
        state.isEmailVerified =
          action.payload.data.data.email_verification === "Verified"
            ? true
            : false;
        toast.success("LoggedIn successfully");
      })
      .addCase(loginWithSocial.rejected, (state, action) => {
        state.isLoading = false;
        state.isLoggedIn = false;
        state.currentUser = null;
        // state.loginErroMessage = [
        //   ...state.loginErroMessage,
        //   action.payload.response.data.message,
        // ];
        if (typeof action.payload.response.data.message === "object") {
          for (const [key, value] of Object.entries(
            action.payload.response.data.message
          )) {
            state.loginErroMessage.push({ key, value });
          }
          state.loginErroMessage.forEach((item) => {
            toast.error(item.value[0]);
          });
        }
        if (typeof action.payload.response.data.message === "string") {
          state.loginErroMessage.push(action.payload.response.data.message);
          state.loginErroMessage.forEach((item) => {
            toast.error(item);
          });
        }
        state.isEmailVerified = false;
      })
      .addCase(otpResend.fulfilled, () => {
        toast.success("OTP has been sent to your email address.");
      })
      .addCase(otpResend.rejected, () => {
        toast.error(
          "There was an error while trying to send OTP. Please try again"
        );
      })
      .addCase(signOut.fulfilled, () => {
        toast.info("Logged Out Successfully");
      });
  },
});
export const {
  saveDeviceToken,
  hasDisplayedToast,
  logoutFunc,
  loadingEnd,
  loadingStart,
  saveTempData,
  resetOTPToken,
  clearTempData
} = AuthSlice.actions;
export default AuthSlice.reducer;
