import React from "react";
import { Modal } from "react-bootstrap";

const TestOver = ({ cancel, handleCloseCancel, saveAnswer }) => {
  return (
    <Modal show={cancel} onHide={handleCloseCancel}>
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <p>Do you really want to close the result? You can’t undo it.</p>
        <button
          className="btn btn-small btn-secondary"
          onClick={handleCloseCancel}
        >
          No
        </button>
        <button className="btn btn-small" onClick={saveAnswer}>
          Yes
        </button>
      </Modal.Body>
    </Modal>
  );
};

export default TestOver;
