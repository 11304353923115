import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectPackage,
  singlePackage,
} from "../../../../Features/SetsAPI/SetsSlice";
import { useParams } from "react-router";
import { useNavigate } from "react-router";
import Loader from "../../../Components/Loader/Loader";
const UBTPackage = () => {
  let { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let token = useSelector((state) => state.auth.currentUser.token);
  let isLoading = useSelector((state) => state.set.isLoading);
  let selectedPackage = useSelector((state) => state.set.selectedPackage);
  useEffect(() => {
    dispatch(singlePackage({ token, id }));
  }, [id]);
  const goBack = () => {
    navigate("/dashboard/ubt-test", {
      replace: true,
    });
  };
  const navigateTo = (item) => {
    navigate(item, {
      replace: true,
    });
  };
  return (
    <>
      {!isLoading ? (
        <>
          {selectedPackage && (
            <>
              <div className="mainPackageDetails">
                <button className="btn btn-small mb-2" onClick={goBack}>
                  Go Back
                </button>
                <h4>
                  {selectedPackage.category} {selectedPackage.title}
                </h4>
                <span>Rs. {selectedPackage.price} </span>
                <p>
                  {selectedPackage.sets && selectedPackage.sets.length} sets
                </p>
              </div>
              {selectedPackage.sets && selectedPackage.sets.length > 0 ? (
                <div className="subPackages boughtpackages">
                  <p>This package includes following sets:</p>
                  <ol>
                    {selectedPackage.sets
                      .filter((item) => item.status === true)
                      .map((subitem, subid) => (
                        <li
                          key={subid}
                          className={`packageset d-flex justify-content-between align-items-center`}
                        >
                          <h6
                            onClick={() =>
                              navigateTo(
                                `/dashboard/ubt-test/ubt-course/${subitem.id}`
                              )
                            }
                          >
                            {++subid}. {subitem.title}
                          </h6>
                          <button
                            onClick={() =>
                              navigateTo(
                                `/dashboard/ubt-test/ubt-course/${subitem.id}`
                              )
                            }
                            className="btn btn-small btn-green"
                          >
                            Test Now
                          </button>
                        </li>
                      ))}
                  </ol>
                </div>
              ) : (
                <div>There are no sets in the package</div>
              )}
            </>
          )}
        </>
      ) : (
        <div className="bigLoading">
          <Loader></Loader>
        </div>
      )}
    </>
  );
};

export default UBTPackage;
