import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteDiscussionWithId,
  getDiscussions,
  createDiscussion,
  updateDiscussion,
  deleteDiscussion,
} from "../../../../Features/DiscussionsAPI/DiscussionsSlice";
import DiscussionItem from "../../../Components/DiscussionComponents/DiscussionItem";
import Loader from "../../../Components/Loader/Loader";
import DiscussionAdd from "../../../Components/DiscussionComponents/DiscussionAdd";
import { getSearch } from "../../../../Features/SearchApi/SearchSlice";
import { openModal } from "../../../../Features/ViewSlice";
import { openLoginForm } from "../../../../Features/ViewSlice";
const Discussions = () => {
  const dispatch = useDispatch();
  let getDiscussionAll = useSelector(
    (state) => state.discussion.getDiscussions
  );
  let isLoading = useSelector((state) => state.discussion.isLoading);
  let token = useSelector((state) => state.auth?.currentUser?.token);
  let currentUser = useSelector((state) => state.profile.profileInfo.name);
  let searchText = useSelector((state) => state.search.getSearchValue);
  let searchLoading = useSelector((state) => state.search.isLoading);
  let isLoggedIn = useSelector((state) => state.auth.isLoggedIn);

  const openLogin = (e) => {
    if (!isLoggedIn) {
      e.preventDefault();
      dispatch(openModal("loginView"));
      dispatch(openLoginForm());
    }
  };
  useEffect(() => {
    dispatch(getDiscussions({ token }));
  }, [dispatch]);
  const [value, setValue] = useState({
    title: "",
    description: "",
  });
  const handleChange = (e) => {
    setValue({ ...value, [e.target.name]: e.target.value });
  };

  const createDiscussionAction = () => {
    const formdata = new FormData();
    formdata.append("title", value.title);
    formdata.append("description", value.description);
    dispatch(createDiscussion({ token, formdata }));
    handleClose();
    setValue({
      title: "",
      description: "",
    });
  };

  const updateDiscussionAction = (text, id) => {
    const formdata = new FormData();
    formdata.append("title", text);
    formdata.append("_method", "PUT");
    dispatch(updateDiscussion({ id, token, formdata }));
  };
  const deleteDiscussionAction = (id) => {
    const formdata = new FormData();
    formdata.append("_method", "DELETE");
    dispatch(deleteDiscussion({ id, formdata, token }));
    dispatch(deleteDiscussionWithId(id));
  };

  const clearTexts = () => {
    setValue({
      title: "",
      description: " ",
    });
  };
  const myDiscussion = () => {
    return getDiscussionAll.filter((item) => item.user === currentUser);
  };
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => {
    if (isLoggedIn) {
      setShow(true);
    } else {
      dispatch(openModal("loginView"));
      dispatch(openLoginForm());
    }
  };
  const handleSortChange = (e) => {
    if (e.target.value === "latestFirst") {
      dispatch(getDiscussions({ token, sortby: "latest" }));
    }
    if (e.target.value === "oldestFirst") {
      dispatch(getDiscussions({ token, sortby: "oldest" }));
    }
    if (e.target.value === "highestComment") {
      dispatch(getDiscussions({ token, sortby: "highest_comments" }));
    }
    if (e.target.value === "lowestComment") {
      dispatch(getDiscussions({ token, sortby: "lowest_comments" }));
    }
  };

  const [searchDiscussionValue, setSearchDiscussionValue] = useState("");

  const searchChange = (e) => {
    setSearchDiscussionValue(e.target.value);
  };
  const searchValue = (e) => {
    setTimeout(() => {
      if (searchDiscussionValue.length != 0) {
        dispatch(
          getSearch({ param: searchDiscussionValue, model: "Discussion" })
        );
      }
    }, 250);
  };
  return (
    // <div>Discussion Page is under development. Comming Soon!</div>
    <div className="discussionContainer">
      <div className="discussion--sections">
        <Row>
          <Col md={8}>
            <div className="commentSorting">
              <form action="#">
                <select name="sorting" id="sort" onChange={handleSortChange}>
                  <option value="latestFirst">Latest First</option>
                  <option value="oldestFirst">Oldest First</option>
                  <option value="highestComment">Highest Comment</option>
                  <option value="lowestComment">lowest Comment</option>
                </select>
              </form>
              <div className="discussionSearch">
                <input
                  type="text"
                  onKeyUp={searchValue}
                  onChange={searchChange}
                  placeholder="Search Your Topic"
                />
              </div>
            </div>
            <div className="comment__section">
              {!isLoading ? (
                <>
                  {getDiscussionAll && searchDiscussionValue.length === 0 && (
                    <>
                      {getDiscussionAll.map((item, id) => (
                        <DiscussionItem
                          discussion={item}
                          key={id}
                          updateDiscussionAction={(text, id) =>
                            updateDiscussionAction(text, id)
                          }
                          deleteDiscussion={(id) => deleteDiscussionAction(id)}
                        />
                      ))}
                    </>
                  )}
                  {!searchLoading ? (
                    <>
                      {searchText.length > 0 ? (
                        <>
                          {searchText.map((item, id) => (
                            <DiscussionItem discussion={item} key={id} />
                          ))}
                          <>
                            {searchDiscussionValue.length === 0 && (
                              <div className="search">Search...</div>
                            )}
                          </>
                        </>
                      ) : (
                        <>
                          {searchDiscussionValue.length > 0 && (
                            <p className="text-center font-weight-bold nosearchresult">
                              No Search Results
                            </p>
                          )}
                        </>
                      )}
                    </>
                  ) : (
                    <div className="loading__mode bigLoading">
                      <Loader label="Searching" />
                    </div>
                  )}
                </>
              ) : (
                <div className="loading__mode">
                  <Loader />
                </div>
              )}
            </div>
          </Col>
          <Col md={4}>
            <div className="startDiscussion">
              <p>Create Your Discussion</p>
              <button className="btn btn-small" onClick={handleShow}>
                Start New Discussion
              </button>
            </div>
            <div className="myDiscussion">
              <h3>My Discussions</h3>
              <ul>
                {!isLoading ? (
                  <>
                    {myDiscussion().length > 0 ? (
                      <>
                        {myDiscussion().map((item, id) => (
                          <li key={id}>
                            <Link
                              onClick={openLogin}
                              to={
                                isLoggedIn &&
                                `/dashboard/discussions/discussionsingle/${item.id}`
                              }
                            >
                              {item.title}
                            </Link>
                            <p>
                              {item.description &&
                                `${item.description.slice(0, 100)}...`}
                            </p>
                            <span>{item.display_date}</span>
                          </li>
                        ))}
                      </>
                    ) : (
                      <p>You have not created any discussions</p>
                    )}
                  </>
                ) : (
                  <div className="loading__mode">
                    <Loader />
                  </div>
                )}
              </ul>
              <Modal show={show} onHide={handleClose} centered>
                <Modal.Header closeButton>
                  <Modal.Title>Add Discussion</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className="">
                    <div className="postDiscussion">
                      <DiscussionAdd
                        clearTextFields={clearTexts}
                        descriptionValue={value.description}
                        titleValue={value.title}
                        handleChange={handleChange}
                        createDiscussion={createDiscussionAction}
                      />
                    </div>
                  </div>
                </Modal.Body>
              </Modal>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Discussions;
