import React, { useState, useRef } from "react";
import HTMLParser from "../../../Components/HTMLParser";
import {
  setFavorites,
  removeFavorites,
} from "../../../../Features/FavoriteApi/FavoriteSlice";
import ShareModal from "../../../Components/Share/ShareModal";
import MetaHelmet from "../../../Components/Helmet/MetaHelmet";
import { useSelector, useDispatch } from "react-redux";
import { textToSpeech } from "../../../../Constants";
import { deselectGrammar } from "../../../../Features/GrammarApi/GrammarSlice";
import { openLoginForm, openModal } from "../../../../Features/ViewSlice";
import nepalFlag from "../../../../Assets/img/nepal-flag.png";
import koreaFlag from "../../../../Assets/img/korea-flag.png";
import ukFlag from "../../../../Assets/img/uk-usa-flag.png";

const GrammarDetails = () => {
  const dispatch = useDispatch();
  let singleGrammar = useSelector((state) => state.grammar.singleGrammar);
  let token = useSelector((state) => state.auth?.currentUser?.token);
  const [isFavorite, setFavorite] = useState(singleGrammar.isFavorite);
  const makeFavorite = (e) => {
    e.preventDefault();
    const formdata = new FormData();
    formdata.append("favoritable_id", singleGrammar.id);
    formdata.append("favoritable_type", "App\\Models\\Grammar");
    if (token) {
      dispatch(setFavorites({ formdata, token })).then((res) => {
        if (res.payload.status === 200) {
          setFavorite(true);
        }
      });
    } else {
      dispatch(openModal("loginView"));
      dispatch(openLoginForm());
    }
  };
  const removeFavorite = (e) => {
    e.preventDefault();
    const formdata = new FormData();
    formdata.append("favoritable_id", singleGrammar.id);
    formdata.append("favoritable_type", "App\\Models\\Grammar");
    dispatch(removeFavorites({ formdata, token })).then((res) => {
      if (res.payload.status === 200) {
        setFavorite(false);
      }
    });
  };
  const textForSpeech = useRef(null);
  const textSpeechHandler = (e) => {
    e.preventDefault();
    if (textForSpeech != null) {
      textToSpeech(textForSpeech.current.textContent);
    }
  };

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const goBack = () => {
    dispatch(deselectGrammar());
  };
  return (
    <>
      {singleGrammar && (
        <div className="grammar--details singleselected">
          <MetaHelmet
            data={singleGrammar}
            shareTo={`/grammardetail/${singleGrammar.id}`}
          />
          <div className="header-wrap">
            <div className="d-flex align-item-center justify-content-between">
              <h2 className="dashboard-page-title my-0" ref={textForSpeech}>
                {singleGrammar.word}
                <a aria-label="volume up" href="/#" onClick={textSpeechHandler}>
                  <i className="bi bi-volume-up"></i>
                </a>
              </h2>
              <div className="grammar--share d-flex align-item-center">
                <i className="bi bi-share" onClick={handleShow}></i>
                <ShareModal
                  shareTo={`/grammardetail/${singleGrammar.id}`}
                  show={show}
                  handleClose={handleClose}
                />
                {isFavorite ? (
                  <a aria-label="heartfill" href="/#" onClick={removeFavorite}>
                    <i className="bi bi-heart-fill"></i>
                  </a>
                ) : (
                  <a aria-label="heart shape" href="/#" onClick={makeFavorite}>
                    <i className="bi bi-heart"></i>
                  </a>
                )}
              </div>
            </div>
          </div>
          <div className="grammar__section">
            {singleGrammar.meanings && (
              <div className="grammarDetails">
                <h3>Meaning:</h3>
                <div>
                  {singleGrammar.meanings.np && (
                    <div className="d-flex">
                      <h4>
                        <img src={nepalFlag} alt="" /> <span>네팔어:</span>
                      </h4>
                      <div>
                        <HTMLParser>{singleGrammar.meanings.np}</HTMLParser>
                      </div>
                    </div>
                  )}
                  {singleGrammar.meanings.en && (
                    <div className="d-flex">
                      <h4>
                        <img src={ukFlag} alt="" /> <span>영어:</span>
                      </h4>
                      <div>
                        <HTMLParser>{singleGrammar.meanings.en}</HTMLParser>
                      </div>
                    </div>
                  )}
                  {singleGrammar.meanings.kr && (
                    <div className="d-flex">
                      <h4>
                        <img src={koreaFlag} alt="" /> <span>한국어:</span>
                      </h4>
                      <div>
                        <HTMLParser>{singleGrammar.meanings.kr}</HTMLParser>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
            {singleGrammar.rules && (
              <div className="grammarDetails">
                <h3>Rules:</h3>
                <div>
                  {singleGrammar.rules.np && (
                    <div className="d-flex">
                      <h4>
                        <img src={nepalFlag} alt="" /> <span>네팔어:</span>
                      </h4>
                      <div>
                        <HTMLParser>{singleGrammar.rules.np}</HTMLParser>
                      </div>
                    </div>
                  )}

                  {singleGrammar.rules.en && (
                    <div className="d-flex">
                      <h4>
                        <img src={ukFlag} alt="" /> <span>영어:</span>
                      </h4>
                      <div>
                        <HTMLParser>{singleGrammar.rules.en}</HTMLParser>
                      </div>
                    </div>
                  )}
                  {singleGrammar.rules.kr && (
                    <div className="d-flex">
                      <h4>
                        <img src={koreaFlag} alt="" /> <span>한국어:</span>
                      </h4>
                      <div>
                        <HTMLParser>{singleGrammar.rules.kr}</HTMLParser>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
            {singleGrammar.examples && (
              <div className="grammarDetails">
                <h3>Examples:</h3>
                <div>
                  <div className="d-flex">
                    <HTMLParser>{singleGrammar.examples}</HTMLParser>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="goback btn btn-small btn-green mb-3" onClick={goBack}>
            Go Back
          </div>
        </div>
      )}
    </>
  );
};

export default GrammarDetails;
