import React from "react";
import { Container } from "react-bootstrap";
import { useSelector } from "react-redux";
const Video = () => {
  let site_Info = useSelector((state) => state.home.siteInfo.homepage_youtube_url);
  return (
    <section className="video__section section">
      <Container>
        <div className="section-head text-center  ">
          <span>Videos</span>
          <h2>Watch Our Latest Videos</h2>
        </div>
        <div className="testimonial-container text-center">
          <iframe
            width="100%"
            loading="lazy"
            height="500"
            src={`https://www.youtube.com/embed/${site_Info}`}
            title="YouTube video player"
            frameBorder={0}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
          <a
            href="https://www.youtube.com/channel/UCDrkqqlsKpYttjSY0FA_VyA"
            className="btn mt-3"
            target="_blank"
          >
            View All Our Videos
          </a>
        </div>
      </Container>
    </section>
  );
};

export default Video;
