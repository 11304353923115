import React from "react";
import { Link } from "react-router-dom";
import ubtTest from "../../../Assets/img/test-img.png";

const NormalSetsItem = ({ details }) => {
  return (
    <div className={`normalsetsitem setsitem-container ${details.status ? "item-bought" : "item-notbought"}`}>
      <div className="setsitem-header">
        <div className="pdficon">
          <img src={ubtTest} alt="" />
          <div className="sets-content">
            <div>
              <Link to={`/dashboard/ubt-test/ubt-course/${details.id}`}>
                <h5>{details.title}</h5>
              </Link>
            </div>
            <div className="text-end">
              <Link to={`/dashboard/ubt-test/ubt-course/${details.id}`} className="btn btn-small">
                Test Now
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NormalSetsItem;
